<div class="container">
    <div class="row">
        <div class="col-6 offset-3">
            <div class="login-panel card">
                <div class="card-header">
                    <img class="rounded mx-auto d-block" src="assets/images/logo_tempo_lg.png" style="width: 90%;">
                </div>
                <div class="card-body">
                    <form @submit.prevent="rastrear" role="form">
                        <fieldset>
                            <div class="form-group">
                                <div class="input-group">
                                    <span class="input-group-addon">
                                        <i class="glyphicon glyphicon-user"></i>
                                    </span>
                                    <input autocomplete="off" autofocus class="form-control" placeholder="No. de Guía"
                                        type="text" v-model="guia">
                                </div>
                            </div>
                            <button class="btn btn-info btn-block" type="submit">Buscar</button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2" v-if="correos.length > 0">
        <div class="col-6 offset-3">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <tbody>
                        <tr v-for="correo in correos">
                            <td><a href="" @click.prevent="verGuia(correo)" title="Ver guia">{{ correo.numero_guia
                                    }}</a><br />
                                <strong>OS No.: {{ correo.orden_numero }}</strong><br />{{ correo.orden_fecha }}
                            </td>
                            <td>{{ correo.ciudad_destinatario }} / {{ correo.departamento_destinatario }}</td>
                            <td>{{ estado(correo) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <modal v-model="modalGuia">
        <div slot="header" class="modal-header">
            <h4 class="modal-title">{{ tituloGuia }}</h4>
            <button type="button" class="close" @click="modalGuia = false"><span>&times;</span></button>
        </div>
        <div class="modal-body">
            <div>
                <img v-if="srcGuia" :src="srcGuia" :alt="tituloGuia">
            </div>
            <div v-if="datosGuia">
                <h4>Datos de la guía</h4>
                <dl class="row">
                    <dt class="col-sm-4">Cliente:</dt>
                    <dd class="col-sm-8">{{ datosGuia.cliente }}</dd>
                    <dt class="col-sm-4">Nombre:</dt>
                    <dd class="col-sm-8">{{ datosGuia.nombre_destinatario }}</dd>
                    <dt class="col-sm-4">Dirección:</dt>
                    <dd class="col-sm-8">{{ datosGuia.direccion_destinatario }}</dd>
                    <dt class="col-sm-4">Estado actual:</dt>
                    <dd class="col-sm-8">{{ datosGuia.estado }}</dd>
                </dl>
            </div>
            <div v-if="movimientos">
                <h4>Tiempos y movimientos</h4>
                <dl class="row">
                    <dt class="col-sm-6">Ingreso de la orden:</dt>
                    <dd class="col-sm-6">{{ movimientos.fecha_orden }}</dd>
                    <dt class="col-sm-6">Fecha de cargue del detalle:</dt>
                    <dd class="col-sm-6">{{ movimientos.fecha_cargue }}</dd>
                    <dt class="col-sm-6">Fecha de llegada del producto:</dt>
                    <dd class="col-sm-6">{{ movimientos.fecha_llegada_producto }}</dd>
                    <template v-if="movimientos.fecha_verificacion">
                        <dt class="col-sm-6">Fecha de verificación:</dt>
                        <dd class="col-sm-6">{{ movimientos.fecha_verificacion }}</dd>
                    </template>
                    <template v-if="movimientos.fecha_transito">
                        <dt class="col-sm-6" v-if="movimientos.fecha_transito">En transito a ciudad de destino:</dt>
                        <dd class="col-sm-6">{{ movimientos.fecha_transito }}</dd>
                    </template>
                    <template v-if="movimientos.fecha_alistamiento">
                        <dt class="col-sm-6" v-if="movimientos.fecha_alistamiento">En alistamiento:</dt>
                        <dd class="col-sm-6">{{ movimientos.fecha_alistamiento }}</dd>
                    </template>
                    <template v-for="movimiento in movimientos.lista">
                        <dt class="col-sm-6">En Distribución:</dt>
                        <dd class="col-sm-6">{{ movimiento.fecha_entrega }}</dd>
                        <template v-if="movimiento.fecha_recepcion">
                            <dt class="col-sm-6">{{ estado(movimiento) }}:</dt>
                            <dd class="col-sm-6">{{ movimiento.fecha_recepcion }}</dd>
                        </template>
                    </template>
                </dl>
            </div>
        </div>
        <div slot="footer" class="modal-footer"></div>
    </modal>
</div>