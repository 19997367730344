<div>
    <h3 class="d-print-none">{{ titulo }}</h3>
    <form @submit.prevent="generarPlanilla" class="d-print-none vld-parent" ref="form-container">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label class="form-control-label" for="correo-fecha">Fecha:</label>
                    <div class="form-inline">
                        <datetimepicker id="correo-fecha-desde" placeholder="Fecha desde" v-model="filtro.fecha_verificacion_desde"></datetimepicker>
                        <datetimepicker id="correo-fecha-hasta" placeholder="Fecha hasta" v-model="filtro.fecha_verificacion_hasta"></datetimepicker>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-secondary">Generar planilla</button>
                </div>
            </div>
        </div>
    </form>
    <div class="row d-print-block" v-if="planilla">
        <div class="col-12">
            <h3>{{ titulo }}</h3>
            <div class="row">
                <div class="col-4">Período:</div>
                <div class="col-8">{{ filtro.fecha_verificacion_desde }} a {{ filtro.fecha_verificacion_hasta }}
                </div>
            </div>
        </div>
        <div class="col-12">
            <table class="table table-bordered table-sm">
                <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>Orden</th>
                        <th>Tipo</th>
                        <th>Cantidad</th>
                        <th>Estado</th>
                        <th>Fecha verificación</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="fila in planilla">
                        <td>{{ fila.cliente_nombre }}</td>
                        <td>{{ fila.orden_numero }}</td>
                        <td>{{ fila.tipo }}</td>
                        <td class="text-right">{{ fila.procesadas }}</td>
                        <td>{{ fila.estado_verificacion }}</td>
                        <td>{{ fila.fecha_verificacion }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="3">TOTAL VERIFICADAS</td>
                        <td class="text-right">{{ totales.procesadas }}</td>
                        <td colspan="2"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>