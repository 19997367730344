<div>
    <h3 class="d-print-none">{{ titulo }}</h3>
    <form @submit.prevent="descargarXlsx" class="d-print-none">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="form-control-label col-md-4" for="filtro-fecha">Fecha:</label>
                    <div class="col-md-4">
                        <datetimepicker id="filtro-fecha-desde" placeholder="Fecha desde" v-model="filtro.fecha_desde"></datetimepicker>
                    </div>
                    <div class="col-md-4">
                        <datetimepicker id="filtro-fecha-hasta" placeholder="Fecha hasta" v-model="filtro.fecha_hasta"></datetimepicker>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="$security.check(['all', 'acceso_global'])">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="form-control-label col-md-4" for="filtro-oficina">Oficina:</label>
                    <div class="col-md-8">
                        <select class="form-control" id="filtro-oficina" v-model="filtro.oficina_id">
                            <option :value="undefined">Seleccione uno</option>
                            <option v-for="oficina in oficinas" :value="oficina.id">{{ oficina.ciudad }} / {{ oficina.departamento }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col-md-8 offset-4">
                        <button type="submit" class="btn btn-secondary">Generar reporte</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>