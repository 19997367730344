import dialogService from 'services/dialog-service';
import notifyService from 'services/notify-service';
import clientesService from 'services/data/clientes-service';
import enumsService from 'services/data/enums-service';


import render from './clientes.html';

export default render({
    name: 'Clientes',
    data() {
        return {
            titulo: 'Clientes',
            modal: false,
            id: null,
            filtro: {},
            cliente: {},
            clientes: [],
            tipos: [],
            ips: [],
            pagination: {
                limit: 10,
                offset: 0,
                pages: 0,
                currentPage: 1,
            }
        }
    },
    methods: {
        agregar() {
            this.id = null;
            this.ips = [];
            this.cliente = {
                ips_permitidas: [],
            };
            this.modal = true;
        },
        editar(cliente) {
            this.id = cliente.id;
            this.cliente = {
                nombre: cliente.nombre,
                identificacion: cliente.identificacion,
                direccion: cliente.direccion,
                codigo_postal: cliente.codigo_postal,
                tipo: cliente.tipo,
                ips_permitidas: [],
            };
            this.ips = _.map(cliente.ips_permitidas, (ip) => {
                return { value: ip };
            });
            this.modal = true;
        },
        eliminar(cliente) {
            eliminar.call(this, cliente);
        },
        guardar() {
            guardar.call(this);
        },
        filtrar() {
            this.pagination.offset = 0;
            this.pagination.currentPage = 1;
            listar.call(this);
        },
        pageChange(page) {
            this.pagination.offset = (page - 1) * this.pagination.limit;
            listar.call(this);
        },
        //Utils
        getTipo(tipoId) {
            return _.get(_.find(this.tipos, tipo => tipo.id === tipoId), 'nombre');
        },
        agregarIp() {
            this.ips.push({});
        },
        quitarIp(index) {
            this.ips.splice(index, 1);
        }
    },
    created() {
        listar.call(this);
        listarTipos.call(this);
    }
});

function listar() {
    let self = this;
    clientesService.listarFiltrado(this.pagination.limit, this.pagination.offset, this.filtro).then(response => {
        self.clientes = response.data.lista;
        self.pagination.pages = Math.ceil(response.data.total / self.pagination.limit);
    });
}

function listarTipos() {
    let self = this;
    enumsService.listarTiposCliente().then(response => {
        self.tipos = response;
    });
}

function guardar() {
    let self = this;
    this.cliente.ips_permitidas = _.map(this.ips, (ip) => ip.value);
    let promise = this.id ? clientesService.editar(this.id, this.cliente) :  clientesService.agregar(this.cliente);
    promise.then(response => {
        listar.call(self);
        self.modal = false;
        notifyService.success('Cliente guardado correctamente');
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}

function eliminar(cliente) {
    let self = this;
    dialogService.confirm(`Esta seguro de que desea eliminar este cliente ${cliente.nombre}?`, result => {
        if(result) {
            clientesService.eliminar(cliente.id).then(response => {
                listar.call(self);
                notifyService.success('Cliente eliminado correctamente');
            }).catch(error => {
                if(error.response.status === 409 && error.response.data.reasons) {
                    notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
                } else if(error.response.data.message) {
                    notifyService.danger(error.response.data.message);
                }
            });
        }
    });
}