import moment from 'moment';
import notifyService from 'services/notify-service';
import mensajerosService from 'services/data/mensajeros-service';
import movimientosService from 'services/data/movimientos-service';


import render from './planilla-recepcion.html';

export default render({
    name: 'PlanillaRecepcion',
    data() {
        return {
            titulo: 'Planilla de recepción',
            filtro: {},
            mensajero: null,
            mensajeros: [],
            planilla: null,
            totales: null,
            procesadas: null,
            pendientes: null,
            entregadas: null,
            devueltas: null
        }
    },
    computed: {
    },
    methods: {
        cambiarMensajero() {
            obtenerMensajero.call(this);
        },
        generarPlanilla() {
            generarPlanilla.call(this);
        },
    },
    created() {
        listarMensajeros.call(this);
    }
});

function listarMensajeros() {
    mensajerosService.listarActivos().then(response => {
        this.mensajeros = response.data.lista;
    });
}

function obtenerMensajero() {
    if(this.filtro.mensajero_id) {
        mensajerosService.obtener(this.filtro.mensajero_id).then(response => {
            this.mensajero = response.data;
            this.planilla = null;
        });
    }
}

function generarPlanilla() {
    this.planilla = null;
    this.totales = null;
    if(this.filtro.mensajero_id && this.filtro.fecha_desde && this.filtro.fecha_hasta) {
        let planilla = [];
        let totales = {
            procesadas: 0,
            entregadas: 0,
            devueltas: 0,
            movimientos: 0.0,
            bonificaciones: 0.0,
            descuentos: 0.0
        };
        let loader = this.$loading.show({
            container: this.$refs['form-container']
        });
        let self = this;
        movimientosService.listarFiltrado('recepcion', this.filtro).then(response => {
            let movimientos = response.data;
            let indexes = {};

            let j = 0;

            _.each(movimientos, (movimiento, i) => {
                if(["EFECTIVO", "DEVUELTO"].indexOf(movimiento.estado) < 0) {
                    return true;
                }
                movimiento.fecha_entrega = moment(movimiento.fecha_entrega).format('YYYY-MM-DD')
                movimiento.fecha_recepcion = moment(movimiento.fecha_recepcion).format('YYYY-MM-DD')
                let llave = movimiento.orden_numero + movimiento.tipo_tarifa + movimiento.fecha_entrega + movimiento.fecha_recepcion;
                let pos = indexes[llave];
                if(pos != null) {
                    //console.log("!=");
                    planilla[pos].procesadas += movimiento.cantidad;
                    planilla[pos].movimientos += movimiento.valor_movimiento;
                    planilla[pos].bonificaciones += movimiento.valor_bonificacion;
                    planilla[pos].descuentos += movimiento.valor_descuento;
                    planilla[pos].total += (movimiento.valor_movimiento + movimiento.valor_bonificacion - movimiento.valor_descuento);
                    switch(movimiento.estado){
                        case "EFECTIVO":
                            planilla[pos].entregadas += movimiento.cantidad;
                            break;
                        case "DEVUELTO":
                            planilla[pos].devueltas += movimiento.cantidad;
                            break;
                    }
                } else {
                    //console.log("==");
                    pos = j++;
                    indexes[llave] = pos;
                    planilla[pos] = {};
                    planilla[pos].cliente_nombre = movimiento.cliente_nombre + ' - ' + movimiento.proceso_referencia;
                    planilla[pos].orden_numero = movimiento.orden_numero;
                    //planilla[pos].orden_fecha_limite_entrega = movimiento.orden_fecha_limite_entrega;
                    planilla[pos].fecha_limite_entrega = moment(movimiento.fecha_entrega, 'YYYY-MM-DD').add(movimiento.tiempo_limite, 'days').format('YYYY-MM-DD');
                    planilla[pos].tipo_tarifa = movimiento.tipo_tarifa;
                    planilla[pos].tarifa = movimiento.valor_tarifa;
                    planilla[pos].procesadas = movimiento.cantidad;
                    planilla[pos].movimientos = movimiento.valor_movimiento;
                    planilla[pos].bonificaciones = movimiento.valor_bonificacion;
                    planilla[pos].descuentos = movimiento.valor_descuento;
                    planilla[pos].total = movimiento.valor_movimiento + movimiento.valor_bonificacion - movimiento.valor_descuento;
                    planilla[pos].fecha_entrega = movimiento.fecha_entrega;
                    planilla[pos].fecha_recepcion = movimiento.fecha_recepcion;
                    planilla[pos].entregadas = 0;
                    planilla[pos].devueltas = 0;
                    switch(movimiento.estado){
                        case "EFECTIVO":
                            planilla[pos].entregadas += movimiento.cantidad;
                            break;
                        case "DEVUELTO":
                            planilla[pos].devueltas += movimiento.cantidad;
                            break;
                    }
                }

                totales.procesadas += movimiento.cantidad;
                totales.movimientos += movimiento.valor_movimiento;
                totales.bonificaciones += movimiento.valor_bonificacion;
                totales.descuentos += movimiento.valor_descuento;
                switch(movimiento.estado){
                    case "EFECTIVO":
                        totales.entregadas += movimiento.cantidad;
                        break;
                    case "DEVUELTO":
                        totales.devueltas += movimiento.cantidad;
                        break;
                }
            });
            self.planilla = planilla;
            self.totales = totales;
            loader.hide();
        }).catch(error => {
            loader.hide();
        });

        //console.log(this.planilla);
        //this.planilla = planilla;
        //this.totales = totales;
    } else {
        this.planilla = null;
        this.totales = null;
        notifyService.danger("Todos los campos son requeridos");
    }
}