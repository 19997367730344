import 'bootstrap-notify';

class Notify {
    buildMessage(messages) {
        if(!_.isEmpty(messages)) {
            let list = '<h5>Datos incorrectos o insuficientes:</h5><ul>';
            _.forEach(messages, (message, field) => {
                list += `<li>${ message }</li>`;
            });
            list += '</ul>';
            return list;
        }
    }
    success(message) {
        $.notify({ message }, { type: 'success', z_index: 10101 });
    }
    danger(message) {
        $.notify({ message }, { type: 'danger', z_index: 10101 });
    }
}

export default new Notify();