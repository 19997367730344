<div>
    <h3>{{ titulo }}
        <small>
            <a v-if="$security.check(['all', 'agregar_clientes'])" href="" @click.prevent="agregar" title="Agregar">[Agregar]</a>
        </small>
    </h3>
    <form class="form-condensed" @submit.prevent="filtrar">
        <fieldset>
            <legend>Criterios de búsqueda</legend>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="form-control-label col-md-4" for="filtro-orden-numero">Nombre:</label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" id="filtro-nombre" placeholder="Nombre" v-model="filtro.nombre">
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col-md-8 offset-4">
                        <button type="submit" class="btn btn-secondary" @click.prevent="filtrar">Buscar</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Identificación</th>
                            <th>Código postal</th>
                            <th>Tipo</th>
                            <th>Activo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="cliente in clientes">
                            <td>
                                <router-link :to="{ name: 'empresa.clientes.info', params: { id: cliente.id } }">{{ cliente.nombre }}</router-link>
                            </td>
                            <td>{{ cliente.identificacion }}</td>
                            <td>{{ cliente.codigo_postal }}</td>
                            <td>{{ getTipo(cliente.tipo) }}</td>
                            <td>{{ cliente.estado === 'ACTIVO' ? 'Si' : 'No' }}
                            </td>
                            <td>
                                <a v-if="$security.check(['all', 'editar_clientes'])" href="" @click.prevent="editar(cliente)" title="Editar">
                                    <i class="mdi mdi-mode-edit mdi-lg"></i>
                                </a>
                                <a v-if="$security.check(['all', 'eliminar_clientes'])" href="" @click.prevent="eliminar(cliente)" title="Eliminar">
                                    <i class="mdi mdi-delete mdi-lg"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="pageChange"></pagination>
        </div>
    </div>
    <form @submit.prevent="guardar">
        <modal v-model="modal">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">{{ titulo }}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="cliente-nombre">Nombre:</label>
                    <input type="text" class="form-control" id="cliente-nombre" placeholder="Nombre" v-model="cliente.nombre">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="cliente-identificacion">Identificación:</label>
                    <input type="text" class="form-control" id="cliente-identificacion" placeholder="Identificación" v-model="cliente.identificacion">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="cliente-direccion">Dirección:</label>
                    <input type="text" class="form-control" id="cliente-direccion" placeholder="Dirección" v-model="cliente.direccion">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="cliente-codigo-postal">Código postal:</label>
                    <input type="text" class="form-control" id="cliente-codigo-postal" placeholder="Código postal" v-model="cliente.codigo_postal">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="cliente-tipo">Tipo de cliente:</label>
                    <select class="form-control" id="cliente-tipo" v-model="cliente.tipo">
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="tipo in tipos" :value="tipo.id">{{ tipo.nombre }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="cliente-codigo-postal">IPs permitidas: <a href="" @click.prevent="agregarIp">+</a></label>
                    <div class="row">
                        <div class="col-12">
                            <div class="input-group input-group-sm" v-for="(ip, index) in ips">
                                <div class="input-group-prepend">
                                    <button class="btn btn-outline-warning" type="button"  @click.prevent="quitarIp(index)">X</button>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="cliente-codigo-postal" placeholder="IP" v-model="ip.value">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Guardar</button>
                <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
            </div>
        </modal>
    </form>
</div>