<section class="wrapper">
    <header class="d-print-none">
        <nav class="navbar navbar-dark bg-dark fixed-top d-print-none navbar-expand-md">
            <router-link class="nav-link navbar-brand" :to="{ name: 'empresa' }">CorreoXpress 5.0</router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#top-menu"
                aria-controls="top-menu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="top-menu">
                <span class="navbar-text ml-auto">
                    Usted ingresó como: {{ $security.datos.nombre }}
                </span>
                <ul class="nav navbar-nav">
                    <li class="nav-item">
                        <a href="" @click.prevent="imprimir" class="nav-link" title="Imprimir">
                            <i class="mdi mdi-print mdi-lg"></i> Imprimir</a>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'empresa.perfil' }" title="Ver perfil">
                            <i class="mdi mdi-account-box mdi-lg"></i> Perfil
                        </router-link>
                    </li>
                    <template v-if="$security.check(['all', 'oficinas', 'clientes', 'mensajeros'])">
                        <li class="nav-item dropdown  d-md-none">
                            <a class="nav-link dropdown-toggle" href="#" id="admin-drop-menu" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-computer mdi-lg"></i> Adminstración
                            </a>
                            <div class="dropdown-menu" aria-labelledby="admin-drop-menu">
                                <router-link class="dropdown-item" :to="{ name: 'empresa.oficinas' }"
                                    v-if="$security.check(['all', 'oficinas'])">Oficinas
                                </router-link>
                                <router-link class="dropdown-item" :to="{ name: 'empresa.clientes' }"
                                    v-if="$security.check(['all', 'clientes'])">Clientes
                                </router-link>
                                <router-link class="dropdown-item" :to="{ name: 'empresa.mensajeros' }"
                                    v-if="$security.check(['all', 'mensajeros'])">Mensajeros
                                </router-link>
                            </div>
                        </li>
                    </template>
                    <template v-if="$security.check(['all', 'ordenes'])">
                        <li class="nav-item dropdown d-md-none">
                            <a class="nav-link dropdown-toggle" href="#" id="correo-drop-menu" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-mail mdi-lg"></i> Correo
                            </a>
                            <div class="dropdown-menu" aria-labelledby="correo-drop-menu">
                                <router-link class="dropdown-item" :to="{ name: 'empresa.ordenes' }">Ordenes de servicio
                                </router-link>
                                <router-link class="dropdown-item" :to="{ name: 'empresa.impresion' }">Impresión por
                                    lotes</router-link>
                                <router-link class="dropdown-item" :to="{ name: 'empresa.correos' }">Buscar correo
                                </router-link>
                            </div>
                        </li>
                    </template>
                    <template v-if="$security.check(['all', 'operaciones'])">
                        <li class="nav-item dropdown d-md-none">
                            <a class="nav-link dropdown-toggle" href="#" id="operacion-drop-menu" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-swap-vert mdi-lg"></i> Operación
                            </a>
                            <div class="dropdown-menu" aria-labelledby="operacion-drop-menu">
                                <router-link v-if="$security.check(['all', 'verificar_correo'])" class="dropdown-item"
                                    :to="{ name: 'empresa.verificacion' }">Verificación</router-link>
                                <router-link v-if="$security.check(['all', 'preprocesar_correo'])" class="dropdown-item"
                                    :to="{ name: 'empresa.preproceso' }">Preproceso</router-link>
                                <router-link v-if="$security.check(['all', 'entregar_correo'])" class="dropdown-item"
                                    :to="{ name: 'empresa.entrega' }">Entrega a mensajeros</router-link>
                                <router-link v-if="$security.check(['all', 'entregar_correo_lote'])"
                                    class="dropdown-item" :to="{ name: 'empresa.entrega-masiva' }">Entrega a mensajeros
                                    por lotes</router-link>
                                <router-link v-if="$security.check(['all', 'entregar_correo'])" class="dropdown-item"
                                    :to="{ name: 'empresa.reasignacion' }">Reasignación de mensajeros</router-link>
                                <router-link v-if="$security.check(['all', 'descargar_efectivas'])"
                                    class="dropdown-item" :to="{ name: 'empresa.descarga-efectivo' }">Descarga de correo
                                    efectivo</router-link>
                                <router-link v-if="$security.check(['all', 'descargar_devoluciones'])"
                                    class="dropdown-item" :to="{ name: 'empresa.descarga-devuelto' }">Descarga de correo
                                    devuelto</router-link>
                                <router-link v-if="$security.check(['all', 'descargar_novedades'])"
                                    class="dropdown-item" :to="{ name: 'empresa.descarga-novedad' }">Descarga de
                                    novedades</router-link>
                                <router-link v-if="$security.check(['all', 'postprocesar_correo'])"
                                    class="dropdown-item" :to="{ name: 'empresa.postproceso' }">Postproceso
                                </router-link>
                                <router-link v-if="$security.check(['all', 'reversar_movimientos'])"
                                    class="dropdown-item" :to="{ name: 'empresa.reversion-movimiento' }">Reversión de
                                    movimientos</router-link>
                                <router-link v-if="$security.check(['all', 'imprimir_planillas'])" class="dropdown-item"
                                    :to="{ name: 'empresa.planilla-entrega' }">Planilla de entrega</router-link>
                                <router-link v-if="$security.check(['all', 'imprimir_planillas'])" class="dropdown-item"
                                    :to="{ name: 'empresa.planilla-recepcion' }">Planilla de recepción</router-link>
                                <router-link v-if="$security.check(['all', 'imprimir_planillas'])" class="dropdown-item"
                                    :to="{ name: 'empresa.planilla-verificacion' }">Planilla de verificación
                                </router-link>
                                <router-link v-if="$security.check(['all', 'imprimir_planillas'])" class="dropdown-item"
                                    :to="{ name: 'empresa.planilla-resumen' }">Planilla resumen</router-link>
                                <router-link v-if="$security.check(['all', 'imprimir_planillas'])" class="dropdown-item"
                                    :to="{ name: 'empresa.planilla-consolidada' }">Planilla consolidada</router-link>
                                <router-link v-if="$security.check(['all', 'imprimir_planillas'])" class="dropdown-item"
                                    :to="{ name: 'empresa.planilla-consolidada-clientes' }">Planilla consolidada por
                                    clientes</router-link>
                            </div>
                        </li>
                    </template>
                    <template v-if="$security.check(['all', 'reportes'])">
                        <li class="nav-item dropdown d-md-none">
                            <a class="nav-link dropdown-toggle" href="#" id="reportes-drop-menu" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-grid-on mdi-lg"></i> Reportes
                            </a>
                            <div class="dropdown-menu" aria-labelledby="reportes-drop-menu">
                                <router-link class="dropdown-item" :to="{ name: 'empresa.reporte-consolidado' }">Reporte
                                    consolidado</router-link>
                                <router-link class="dropdown-item" :to="{ name: 'empresa.reporte-tarifas' }">Reporte
                                    tarifas
                                </router-link>
                                <router-link class="dropdown-item" :to="{ name: 'empresa.reporte-datos' }">Reporte Min
                                    TIC
                                </router-link>
                                <router-link class="dropdown-item" :to="{ name: 'empresa.reporte-cadena' }">Reporte
                                    Cadena
                                </router-link>
                            </div>
                        </li>
                    </template>
                    <template v-if="$security.check(['all', 'usuarios', 'roles'])">
                        <li class="nav-item dropdown d-md-none">
                            <a class="nav-link dropdown-toggle" href="#" id="seguridad-drop-menu" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-lock mdi-lg"></i> Seguridad</h5>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="seguridad-drop-menu">
                                <router-link class="dropdown-item" :to="{ name: 'empresa.roles' }"
                                    v-if="$security.check(['all', 'roles'])">Roles</router-link>
                                <router-link class="dropdown-item" :to="{ name: 'empresa.usuarios' }"
                                    v-if="$security.check(['all', 'usuarios'])">Usuarios</router-link>
                            </div>
                        </li>
                    </template>
                    <li class="nav-item">
                        <a href="" @click.prevent="logout" class="nav-link" title="Salir">Salir</a>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
    <div class="container-fluid full-height">
        <!--button class="btn btn-success d-lg-none d-xl-none" style="position: fixed; bottom: 30px; right: 30px; z-index: 1050"><i class="material-icons mdi-2x">apps</i></button-->
        <div class="row full-height">
            <div class="col-lg-2 d-none d-lg-block sidebar d-print-none">
                <div class="sidebar-nav">
                    <template v-if="$security.check(['all', 'oficinas', 'clientes', 'mensajeros'])">
                        <h5><i class="mdi mdi-computer mdi-lg"></i> Adminstración</h5>
                        <ul class="nav nav-pills flex-column">
                            <li class="nav-item" v-if="$security.check(['all', 'oficinas'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.oficinas' }">Oficinas</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'clientes'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.clientes' }">Clientes</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'mensajeros'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.mensajeros' }">Mensajeros
                                </router-link>
                            </li>
                        </ul>
                    </template>
                    <template v-if="$security.check(['all', 'ordenes'])">
                        <h5><i class="mdi mdi-mail mdi-lg"></i> Correo</h5>
                        <ul class="nav nav-pills flex-column">
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'empresa.ordenes' }">Ordenes de servicio
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'empresa.impresion' }">Impresión por lotes
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'empresa.correos' }">Buscar correo
                                </router-link>
                            </li>
                        </ul>
                    </template>
                    <template v-if="$security.check(['all', 'operaciones'])">
                        <h5><i class="mdi mdi-swap-vert mdi-lg"></i> Operación</h5>
                        <ul class="nav nav-pills flex-column">
                            <li class="nav-item" v-if="$security.check(['all', 'verificar_correo'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.verificacion' }">Verificación
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'preprocesar_correo'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.preproceso' }">Preproceso
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'entregar_correo'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.entrega' }">Entrega a mensajeros
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'entregar_correo_lote'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.entrega-masiva' }">Entrega a
                                    mensajeros por lote</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'entregar_correo'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.reasignacion' }">Reasignación de
                                    mensajeros</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'descargar_efectivas'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.descarga-efectivo' }">Descarga de
                                    correo efectivo</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'descargar_devoluciones'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.descarga-devuelto' }">Descarga de
                                    correo devuelto</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'descargar_novedades'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.descarga-novedad' }">Descarga de
                                    novedades</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'postprocesar_correo'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.postproceso' }">Postproceso
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'reversar_movimientos'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.reversion-movimiento' }">Reversión
                                    de movimientos</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'imprimir_planillas'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.planilla-entrega' }">Planilla de
                                    entrega</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'imprimir_planillas'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.planilla-recepcion' }">Planilla de
                                    recepción</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'imprimir_planillas'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.planilla-verificacion' }">Planilla
                                    de verificación</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'imprimir_planillas'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.planilla-resumen' }">Planilla
                                    resumen</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'imprimir_planillas'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.planilla-consolidada' }">Planilla
                                    consolidada</router-link>
                            </li>
                            <li class="nav-item" v-if="$security.check(['all', 'imprimir_planillas'])">
                                <router-link class="nav-link" :to="{ name: 'empresa.planilla-consolidada-clientes' }">
                                    Planilla consolidada por clientes</router-link>
                            </li>
                        </ul>
                    </template>
                    <template v-if="$security.check(['all', 'reportes'])">
                        <h5><i class="mdi mdi-grid-on mdi-lg"></i> Reportes</h5>
                        <ul class="nav nav-pills flex-column">
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'empresa.reporte-consolidado' }">Reporte
                                    consolidado</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'empresa.reporte-tarifas' }">Reporte tarifas
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'empresa.reporte-datos' }">Reporte Min TIC
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'empresa.reporte-cadena' }">Reporte Cadena
                                </router-link>
                            </li>
                        </ul>
                    </template>
                    <template v-if="$security.check(['all', 'usuarios', 'roles'])">
                        <h5><i class="mdi mdi-lock mdi-lg"></i> Seguridad</h5>
                        <ul class="nav nav-pills flex-column">
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'empresa.roles' }">Roles</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'empresa.usuarios' }">Usuarios</router-link>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
            <div class="col-md-12 col-lg-10 content d-print-block">
                <transition name="slides" enter-active-class="animated slideInLeft"
                    leave-active-class="animated slideOutRight">
                    <router-view></router-view>
                </transition>
            </div>
        </div>
    </div>
    <footer class="footer d-print-none">
        <nav class="navbar navbar-expand-lg navbar-dark bg-red fixed-bottom">
            <span class="navbar-text">Diseñado y desarrollado por <a href="http://latinosoft.co">Latinososft
                    S.A.S</a></span>
        </nav>
    </footer>
    <iframe id="print" style="display: none;"></iframe>
</section>