import notifyService from 'services/notify-service';
import uploadService from 'services/upload-service';
import mensajerosService from 'services/data/mensajeros-service';
import movimientosService from 'services/data/movimientos-service';
import enumsService from 'services/data/enums-service';

import moment from 'moment';

import render from './entrega-masiva.html';

export default render({
    name: 'EntregaMasiva',
    data() {
        return {
            titulo: 'Entrega de correo por lote',
            fecha: null,
            mensajeroId: null,
            tipo: null,
            tipoTarifa: null,
            fecha: null,
            mensajero: null,
            archivo: null,
            mensajeros: [],
            tipos:[],
            tiposTarifa: [],
        }
    },
    computed: {
        tiposTarifaMensajero() {
            return _.filter(this.tiposTarifa, tipoTarifa =>  this.mensajero && this.mensajero.tipos_tarifa.indexOf(tipoTarifa.id) >= 0 );
        }
    },
    methods: {
        cambiarMensajero() {
            this.$refs.tipo.focus();
            this.$nextTick(() => {
                obtenerMensajero.call(this);
            });
        },
        cambiarTipo() {
            this.$refs.tipoTarifa.focus();
        },
        cambiarTipoTarifa() {
            
        },
        procesarEntrega() {
            //console.log('procesar');
            procesarEntrega.call(this);
        },
        cargar(file) {
            let loader = this.$loading.show({
                container: this.$refs['form-container']
            });
            uploadService.saveFile(file).then(() => {
                loader.hide();
            }).catch(() => {
                notifyService.danger("No se pudo cargar el archivo");
                loader.hide();
            });
        }
    },
    created() {
        listarMensajeros.call(this);
        listarTiposCorreo.call(this);
        listarTiposTarifa.call(this);
    }
});

function listarMensajeros() {
    mensajerosService.listarActivos().then(response => {
        this.mensajeros = response.data.lista;
    });
}

function obtenerMensajero() {
    if(this.mensajeroId) {
        mensajerosService.obtener(this.mensajeroId).then(response => {
            this.mensajero = response.data;
        });
    }
}

function listarTiposCorreo() {
    enumsService.listarTiposCorreo().then(response => {
        this.tipos = response;
    });
}

//Enums
function listarTiposTarifa() {
    enumsService.listarTiposTarifa().then(response => {
        this.tiposTarifa = response;
    });
}

function procesarEntrega() {
    let loader = this.$loading.show({
        container: this.$refs['form-container']
    });
    movimientosService.procesarEntregaLote(this.archivo, this.mensajeroId, this.tipo, this.tipoTarifa, this.fecha).then(() => {
        loader.hide();
        notifyService.success("Datos guardados correctamente");
        //this.$router.push({ name: 'empresa.ordenes.detalle', params: { id: this.ordenId } });
    }).catch(error => {
        loader.hide();
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}