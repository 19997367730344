import authentication from 'empresa/services/authentication-service';

import render from './principal.html';

export default render({
    name: 'Principal',
    data() {
        return {
            toast: false,
            drawer: true
        }
    },
    computed: {
        contentWrapperClass() {
            return {
                drawer: this.drawer
            };
        }
    },
    methods: {
        imprimir() {
            //window.print();
            let print = $('#print');
            let body = print.contents().find('body');
            body.empty();
            $('.content .d-print-block').each((index, element) => {
                body.append($(element).html());
            });
            print.get(0).contentWindow.print();
        },
        logout() {
            authentication.unAuthenticate();
            this.$router.push({ name: 'index' });
        }
    },
    mounted() {
        let self = this;
        this.$nextTick(() => {
            $('#print').contents().find('head').html($('head').html());
            $('.navbar-nav>li a:not(.dropdown-toggle)', self.$el).on('click', () => {
                $('.navbar-collapse').collapse('hide');
            });
        });
    }
});