const apiUrl = settings.baseUrl + settings.apiPath;

class Url {
    base() {
        return apiUrl;
    }
    create(path) {
        return apiUrl + path;
    }
}

export default new Url();