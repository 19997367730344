import dialogService from 'services/dialog-service';
import notifyService from 'services/notify-service';
import ordenesService from 'services/data/ordenes-service';
import clientesService from 'services/data/clientes-service';
import oficinasService from 'services/data/oficinas-service';
//import procesosService from 'services/data/procesos-service';
//import ordenesService from 'services/data/ordenes-service';
import enumsService from 'services/data/enums-service';
import render from './clientes.info.html';

export default render({
    name: 'InfoCliente',
    data() {
        return {
            clienteId: null,
            cliente: {},
            // tiposCliente: [],
            tiposTarifa: [],
            oficinas: [],
            modalContacto: false,
            contactoId: null,
            contacto: {},
            contactos: [],
            modalProceso: false,
            procesoId: null,
            proceso: {},
            procesos: [],
            ordenes: [],
            oficinaId: null,
            valores: {},
            modalValores: false,
            fecha_inicio: null,
            tarifas: {},
            tiempos: {}
        }
    },
    methods: {
        agregarContacto() {
            this.contactoId = null;
            this.contacto = {};
            this.modalContacto = true;
        },
        editarContacto(contacto) {
            this.contactoId = contacto.id;
            this.contacto.nombre = contacto.nombre;
            this.contacto.identificacion = contacto.identificacion;
            this.contacto.email = contacto.email;
            this.contacto.direccion = contacto.direccion;
            this.contacto.telefono = contacto.telefono;
            this.contacto.celular = contacto.celular;
            this.contacto.username = contacto.username;
            this.modalContacto = true;
        },
        agregarProceso() {
            this.procesoId = null;
            this.proceso = {};
            this.modalProceso = true;
        },
        editarProceso(proceso) {
            this.procesoId = proceso.id;
            this.proceso = {
                //oficina_id: proceso.oficina_id,
                referencia: proceso.referencia,
                fecha_inicio: proceso.fecha_inicio,
                fecha_finalizacion: proceso.fecha_finalizacion,
                //valor_devolucion: proceso.valor_devolucion,
                //valor_descuento: proceso.valor_descuento,
                //valor_pago_minimo: proceso.valor_pago_minimo,
                //valor_bonificacion: proceso.valor_bonificacion
            };
            //this.procesoTarifas = proceso.tarifas ? proceso.tarifas : {};
            //this.procesoTiempos = proceso.tiempos ? proceso.tiempos : {};
            this.modalProceso = true;
        },
        eliminarProceso(proceso) {
            eliminarProceso.call(this, proceso);
        },
        guardarProceso() {
            guardarProceso.call(this);
        },
        ingresarValores(proceso) {
            this.procesoId = proceso.id;
            this.oficinaId = undefined;
            this.fecha_inicio = null;
            this.valores = {};
            this.tarifas = {};
            this.tiempos = {};
            this.modalValores = true;
        },
        editarValores(proceso, oficina) {
            this.procesoId = proceso.id;
            this.oficinaId = oficina.id;
            this.fecha_inicio = oficina.fecha_inicio;
            this.modalValores = true;
            obtenerValores.call(this);
        },
        obtenerValores() {
            obtenerValores.call(this);
        },
        guardarValores() {
            guardarValores.call(this);
        },
        guardarContacto() {
            guardarContacto.call(this);
        },
        eliminarContacto(contacto) {
            eliminarContacto.call(this, contacto);
        },
    },
    created() {
        this.clienteId = this.$route.params.id;
        obtenerCliente.call(this);
        listarOrdenes.call(this);
        listarContactos.call(this);
        listarOficinas.call(this);
        listarProcesos.call(this);
        // listarTiposCliente.call(this);
        listarTiposTarifa.call(this);
    }
});

function obtenerCliente() {
    let self = this;
    clientesService.obtener(this.clienteId).then(response => {
        self.cliente = response.data;
    });
}

function obtenerValores() {
    if(this.procesoId && this.oficinaId) {
        let self = this;
        clientesService.obtenerValoresProceso(this.procesoId, this.fecha_inicio, this.oficinaId).then(response => {
            //self.fecha = response.data.fecha;
            self.tarifas = response.data.tarifas;
            self.tiempos = response.data.tiempos;
            self.valores = response.data.valores;
        });
    } else {
        //self.fecha = {};
        this.tarifas = {};
        this.tiempos = {};
        this.valores = {};
    }
}

function listarOficinas() {
    let self = this;
    oficinasService.listar().then(response => {
        self.oficinas = response.data.lista;
    });
}

function listarOrdenes() {
    let self = this;
    ordenesService.listarFiltrado(5, 0, {
        cliente_id: this.clienteId
    }).then(response => {
        self.ordenes = response.data.lista;
    });
}

function listarProcesos() {
    let self = this;
    clientesService.listarProcesosCliente(this.clienteId).then(response => {
        self.procesos = response.data.lista;
    });
}

// function listarTiposCliente() {
//     let self = this;
//     enumsService.listarTiposCliente().then(response => {
//         self.tiposCliente = response;
//     });
// }

function listarTiposTarifa() {
    let self = this;
    enumsService.listarTiposTarifa().then(response => {
        self.tiposTarifa = response;
    });
}

function guardarProceso() {
    let self = this;
    this.proceso.cliente_id = this.clienteId;
    //this.proceso.tarifas = this.procesoTarifas;
    //this.proceso.tiempos = this.procesoTiempos;
    var promise = this.procesoId ? clientesService.editarProceso(this.clienteId, this.procesoId, this.proceso) : clientesService.agregarProceso(this.clienteId, this.proceso);
    promise.then(response => {
        listarProcesos.call(self);
        self.modalProceso = false;
        notifyService.success('Proceso guardado correctamente');
    }).catch(error => {
        if(error.response.status === 409) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        }
    });
}

function eliminarProceso(proceso) {
    let self = this;
    dialogService.confirm(`Esta seguro de que desea eliminar este proceso ${proceso.referencia}?`, result => {
        if(result) {
            clientesService.eliminarProceso(proceso.id).then(response => {
                listarProcesos.call(self);
                notifyService.success('Proceso eliminado correctamente');
            }).catch(error => {
                if(error.response.status === 409 && error.response.data.reasons) {
                    notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
                } else if(error.response.data.message) {
                    notifyService.danger(error.response.data.message);
                }
            });
        }
    });
}

function guardarValores() {
    let self = this;
    clientesService.editarValoresProceso(this.procesoId, this.oficinaId, {
        fecha_inicio: this.fecha_inicio,
        valores: this.valores,
        tarifas: this.tarifas,
        tiempos: this.tiempos
    }).then(response => {
        listarProcesos.call(self);
        self.modalValores = false;
        notifyService.success('Valores guardados correctamente');
    }).catch(error => {
        notifyService.danger('Ocurió un error, intente de nuevo mas tarde');
    });
}

function listarContactos() {
    let self = this;
    clientesService.listarContactos(this.clienteId).then(response => {
        self.contactos = response.data.lista;
    });
}

function guardarContacto() {
    let self = this;
    var promise = this.contactoId ? clientesService.editarContacto(this.clienteId, this.contactoId, this.contacto) : clientesService.agregarContacto(this.clienteId, this.contacto);
    promise.then(response => {
        listarContactos.call(self);
        self.modalContacto = false;
        notifyService.success('Contacto guardado correctamente');
    }).catch(error => {
        if(error.response.status === 409) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        }
    });
}

function eliminarContacto(contacto) {
    let self = this;
    dialogService.confirm(`Esta seguro de que desea eliminar este contacto ${contacto.nombre}?`, result => {
        if(result) {
            clientesService.eliminarContacto(contacto.id).then(response => {
                listarContactos.call(self);
                notifyService.success('Contacto eliminado correctamente');
            }).catch(error => {
                if(error.response.status === 409 && error.response.data.reasons) {
                    notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
                } else if(error.response.data.message) {
                    notifyService.danger(error.response.data.message);
                }
            });
        }
    });
}