<div>
    <h3>{{ titulo }} <small><a v-if="$security.check(['all', 'agregar_usuarios'])" href="" @click.prevent="agregar"
                title="Agregar">[Agregar]</a></small></h3>
    <form @submit.prevent="filtrar">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="form-control-label col-md-4" for="filtro-username">Username:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="filtro-username" placeholder="Nombre"
                                    v-model="filtro.like_username">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="form-control-label col-md-4" for="filtro-estado">Estado:</label>
                            <div class="col-md-8">
                                <select class="form-control" id="filtro-estado" v-model="filtro.estado">
                                    <option :value="undefined">Seleccione uno</option>
                                    <option v-for="estado in estados" :value="estado.id">{{ estado.nombre }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="$security.check(['all', 'acceso_global'])">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="form-control-label col-md-4" for="filtro-oficina">Oficina:</label>
                            <div class="col-md-8">
                                <select class="form-control" id="filtro-oficina" v-model="filtro.oficina_id">
                                    <option :value="undefined">Seleccione uno</option>
                                    <option v-for="oficina in oficinas" :value="oficina.id">{{ oficina.ciudad }} /
                                        {{ oficina.departamento }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <div class="col-md-8 offset-4">
                                <button type="submit" class="btn btn-secondary" @click.prevent="filtrar">Buscar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Nombre de usuario</th>
                            <th>Email</th>
                            <th>Oficina</th>
                            <th>Rol</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="usuario in usuarios">
                            <td>{{ usuario.nombre }}</td>
                            <td>{{ usuario.username }}</td>
                            <td>{{ usuario.email }}</td>
                            <td>{{ (usuario.oficina_ciudad ? usuario.oficina_ciudad : '') + (usuario.oficina_departamento ? '/' + usuario.oficina_departamento : '') }}
                            </td><!-- ciudad_departamento -->
                            <td>{{ usuario.rol_nombre }}</td>
                            <td>{{ getEstado(usuario.estado) }}</td>
                            <td>
                                <a v-if="$security.check(['all', 'editar_usuarios'])" href=""
                                    @click.prevent="editar(usuario)" title="Editar"><i
                                        class="mdi mdi-edit mdi-lg"></i></a>
                                <!--a v-if="$security.check(['all', 'eliminar_usuarios'])" href="" @click.prevent="eliminar(usuario)" title="Eliminar"><i class="mdi mdi-delete mdi-lg"></i></a-->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="pageChange"></pagination>
        </div>
    </div>
    <form @submit.prevent="guardar">
        <modal v-model="modal">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">{{ titulo }}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="usuario-nombre">Nombre:</label>
                    <input type="text" class="form-control" id="usuario-nombre" placeholder="Nombre"
                        v-model="usuario.nombre">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="usuario-email">Email:</label>
                    <input type="text" class="form-control" id="usuario-email" placeholder="Email"
                        v-model="usuario.email">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="usuario-username">Nombre de usuario:</label>
                    <input type="text" class="form-control" id="usuario-username" placeholder="Nombre de usuario"
                        v-model="usuario.username">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="usuario-password">Password:</label>
                    <input type="text" class="form-control" id="usuario-password" placeholder="Password"
                        v-model="usuario.password">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="usuario-oficina">Oficina:</label>
                    <select class="form-control" id="usuario-oficina" v-model="usuario.oficina_id">
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="oficina in oficinas" :value="oficina.id">
                            {{ oficina.ciudad + '/' + oficina.departamento }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="usuario-supervisor">Jefe de Oficina:</label>
                    <select class="form-control" id="usuario-supervisor" v-model="usuario.supervisor">
                        <option :value="0">No</option>
                        <option :value="1">Si</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="usuario-rol">Rol:</label>
                    <select class="form-control" id="usuario-rol" v-model="usuario.rol_id">
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="rol in roles" :value="rol.id">{{ rol.nombre }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="usuario-estado">Estado:</label>
                    <select class="form-control" id="usuario-estado" v-model="usuario.estado">
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="estado in estados" :value="estado.id">{{ estado.nombre }}</option>
                    </select>
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Guardar</button>
                <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
            </div>
        </modal>
    </form>
</div>