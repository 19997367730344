import Chart from "chart.js";

let options = {
    responsive: true,
    maintainAspectRatio: false
}

export default {
    name: 'ChartJS',
    template: '<canvas ref="canvas" :width="width" :height="height"></canvas>',
    props: {
        type: {
            type: String,
            default: 'line'
        },
        width: {
            type: String,
            default: '720'
        },
        height: {
            type: String,
            default: '480'
        },
        data: {
            type: Object,
            default() {
                return { labels: [], datasets: [] };
            }
        },
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            chart: null
        };
    },
    watch: {
        data(val) {
            this.chart.config.data = val;
            this.$nextTick(function() {
                this.chart.update();
            });
        },
        options(val) {
            this.chart.config.options = _.assign({}, options, val);
            this.$nextTick(function() {
                this.chart.update();
            });
        }
    },
    mounted() {
        this.chart = new Chart(this.$el, {
            type: this.type,
            data: this.data,
            options: _.assign({}, options, this.options)
        });
    }
}
