<div>
    <h3>{{ titulo }}
        <small>
            <a v-if="$security.check(['all', 'agregar_ordenes'])" href="" @click.prevent="agregar" title="Agregar">[Agregar]</a>
        </small>
    </h3>
    <form class="form-condensed vld-parent" @submit.prevent="filtrar" ref="form-container">
        <fieldset>
            <legend>Criterios de búsqueda</legend>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="form-control-label col-md-4" for="filtro-anio">A&#xF1;o:</label>
                        <div class="col-md-8">
                            <select class="form-control" id="filtro-anio" v-model="filtro.anio">
                                <option v-for="anio in anios" :value="anio">{{ anio }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="form-control-label col-md-4" for="filtro-oficina">Oficina:</label>
                        <div class="col-md-8">
                            <select class="form-control" id="filtro-oficina" v-model="filtro.oficina_id">
                                <option :value="undefined">Seleccione uno</option>
                                <option v-for="oficina in oficinas" :value="oficina.id">{{ oficina.ciudad }} / {{ oficina.departamento }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="form-control-label col-md-4" for="filtro-referencia-1">No. interno:</label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" id="filtro-referencia-1" placeholder="Referencia" v-model="filtro.orden_referencia_1">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="form-control-label col-md-4" for="filtro-cliente">Cliente:</label>
                        <div class="col-md-8">
                            <select class="form-control" id="filtro-cliente" v-model="filtro.proceso_id" v-searchable>
                                <option :value="undefined">Seleccione uno</option>
                                <option v-for="proceso in procesos" :value="proceso.id">{{ proceso.cliente_nombre }} - {{ proceso.referencia }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="form-control-label col-md-4" for="filtro-orden-numero">Número de orden:</label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" id="filtro-orden-numero" placeholder="Número de orden" v-model="filtro.orden_numero">
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col-md-8 offset-4">
                        <button type="submit" class="btn btn-secondary" @click.prevent="filtrar">Buscar</button>
                        <button type="button" class="btn btn-success" @click="descargarXlsx">Exportar a xlsx</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-12" v-if="ordenes.length > 0">
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Número</th>
                            <th>Cliente</th>
                            <th>Oficina</th>
                            <th>Fecha</th>
                            <th>Producto</th>
                            <th>Referencia</th>
                            <th>Cantidad esperada/cargada</th>
                            <th>Estado</th>
                            <th>Guías escaneadas</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="orden in ordenes">
                            <td>
                                <router-link :to="{ name: 'empresa.ordenes.detalle', params: { id: orden.id } }">{{ orden.numero }}</router-link>
                            </td>
                            <td>{{ orden.nacional ? '(Nacional) ' : '' }}{{ orden.cliente_nombre }}: {{ orden.proceso_referencia }}</td>
                            <td>{{ orden.oficina_ciudad }} / {{ orden.oficina_departamento }}</td>
                            <td>{{ orden.fecha }}</td>
                            <td>{{ orden.producto }}</td>
                            <td>{{ orden.referencia_1 }}</td>
                            <td>{{ orden.cantidad_esperada }} / {{ orden.cantidad_cargada }}</td>
                            <td>{{ orden.pagada ? 'Cerrada' : estado(orden) }}</td>
                            <td>{{ orden.estado ? orden.estado.guias : 0 }}</td>
                            <td>
                                <a href="" v-if="!orden.cerrada" @click.prevent="cerrar(orden)" title="Finalizar">
                                    <i class="mdi mdi-done mdi-lg"></i>
                                </a>
                                <a href="" v-if="$security.check(['all', 'facturar_ordenes']) && orden.cerrada && !orden.facturada" @click.prevent="facturar(orden)" title="Registrar facturación">
                                    <i class="mdi mdi-description mdi-lg"></i>
                                </a>
                                <a href="" v-if="$security.check(['all', 'pagar_ordenes']) && orden.facturada && !orden.pagada" @click.prevent="pagar(orden)" title="Registrar pago y cerrar">
                                    <i class="mdi mdi-payment mdi-lg"></i>
                                </a>
                                <a v-if="$security.check(['all', 'editar_ordenes'])" href="" @click.prevent="editar(orden)" title="Editar">
                                    <i class="mdi mdi-mode-edit mdi-lg"></i>
                                </a>
                                <a v-if="$security.check(['all', 'eliminar_ordenes'])" href="" @click.prevent="eliminar(orden)" title="Eliminar">
                                    <i class="mdi mdi-delete mdi-lg"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="pageChange"></pagination>
        </div>
    </div>
    <form @submit.prevent="guardar">
        <modal v-model="modal">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">{{ titulo }}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group" v-if="$security.check(['all', 'acceso_global'])">
                    <label class="form-control-label" for="orden-oficina">Oficina:</label>
                    <select class="form-control" id="orden-oficina" v-model="orden.oficina_id">
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="oficina in oficinas" :value="oficina.id">{{ oficina.ciudad }} / {{ oficina.departamento }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-proceso">Cliente:</label>
                    <select class="form-control" id="orden-proceso" v-model="orden.proceso_id" v-searchable>
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="proceso in procesos" :value="proceso.id">{{ proceso.cliente_nombre }} - {{ proceso.referencia }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-numero">Número:</label>
                    <input type="text" class="form-control" id="orden-numero" placeholder="Número" v-model="orden.numero">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-producto">Producto:</label>
                    <input type="text" class="form-control" id="orden-producto" placeholder="Producto" v-model="orden.producto">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-fecha">Fecha:</label>
                    <datetimepicker id="orden-fecha" placeholder="Fecha" v-model="orden.fecha" :disabled="this.id && !$security.check(['all', 'editar_fechas_ordenes'])"></datetimepicker>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-fecha-llegada-producto">Fecha de llegada del producto:</label>
                    <datetimepicker id="orden-fecha-llegada-producto" placeholder="Fecha de llegada del producto" v-model="orden.fecha_llegada_producto" :disabled="this.id && !$security.check(['all', 'editar_fechas_ordenes'])"></datetimepicker>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-fecha-limite-entrega">Fecha límite de entrega:</label>
                    <datetimepicker id="orden-fecha-limite-entrega" placeholder="Fecha límite de entrega" v-model="orden.fecha_limite_entrega"></datetimepicker>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-fecha-limite-pago">Fecha límite de pago:</label>
                    <datetimepicker id="orden-fecha-limite-pago" placeholder="Fecha límite de pago" v-model="orden.fecha_limite_pago"></datetimepicker>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-cantidad-esperada">Cantidad esperada:</label>
                    <input type="text" class="form-control" id="orden-cantidad-esperada" placeholder="Cantidad esperada" v-model="orden.cantidad_esperada">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-referencia_1">Referencia 1 (No. interno):</label>
                    <input type="text" class="form-control" id="orden-referencia_1" placeholder="Referencia" v-model="orden.referencia_1">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-referencia_2">Referencia 2 (Tipo de producto claro):</label>
                    <select class="form-control" id="orden-referencia_2" v-model="orden.referencia_2">
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="tipoProductoClaro in tiposProductoClaro" :value="tipoProductoClaro.id">{{ tipoProductoClaro.nombre }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-nacional">Orden nacional:</label>
                    <select class="form-control" id="orden-nacional" v-model="orden.nacional">
                        <option :value="0">No</option>
                        <option :value="1">Si</option>
                    </select>
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Guardar</button>
                <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
            </div>
        </modal>
    </form>
    <form @submit.prevent="guardarFacturacion">
        <modal v-model="modalFactura">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">Registro de facturación</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="orden-fecha-factura-facturacion">Fecha de facturación:</label>
                    <datetimepicker id="orden-fecha-facturacion" placeholder="Fecha de facturación" v-model="orden.fecha_facturacion"></datetimepicker>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="orden-fecha-plazo">Plazo:</label>
                    <datetimepicker id="orden-fecha-plazo" placeholder="Plazo" v-model="orden.fecha_plazo"></datetimepicker>
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Guardar</button>
                <button type="button" class="btn btn-secondary" @click="modalFactura = false">Salir</button>
            </div>
        </modal>
    </form>
    <form @submit.prevent="guardarPago">
        <modal v-model="modalPago">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">Registro de pago</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="orden-fecha-pago">Fecha de pago:</label>
                    <datetimepicker id="orden-fecha-pago" placeholder="Fecha" v-model="orden.fecha_pago"></datetimepicker>
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Guardar</button>
                <button type="button" class="btn btn-secondary" @click="modalPago = false">Salir</button>
            </div>
        </modal>
    </form>
</div>