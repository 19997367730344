<nav aria-label="Page navigation">
    <ul class="pagination" :class="paginationClass">
        <li class="page-item">
            <a href="" aria-label="Previous" @click.prevent="previous()" class="page-link">
                <span aria-hidden="true">&#xAB;</span>
            </a>
        </li>
        <li v-for="page in pageRange" :class="{ active: page === value }" class="page-item">
            <a href="" @click.prevent="changePage(page)" class="page-link">{{ page }}</a>
        </li>
        <li class="page-item">
            <a href="" aria-label="Next" @click.prevent="next()" class="page-link">
                <span aria-hidden="true">&#xBB;</span>
            </a>
        </li>
    </ul>
</nav>