import axios from 'axios';
import url from 'services/url-service';

class Upload {
    saveFile(file, path = '/private/comun/upload') {
        let data = new FormData();
        data.append(this.name, file);
        return axios.post(url.create(path), data);
    }
}

export default new Upload();