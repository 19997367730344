import notifyService from 'services/notify-service';
import uploadService from 'services/upload-service';
//import clientesService from 'services/data/clientes-service';
import ordenesService from 'services/data/ordenes-service';
//import correosService from 'services/data/correos-service';

import papa from 'papaparse';

import render from './ordenes.detalle.cargar.html';

papa.SCRIPT_PATH = 'lib/papaparse/papaparse.min.js';

export default render({
    name: 'DetalleOrden',
    data() {
        return {
            ordenId: null,
            orden: {},
            datos: {
                delimitador: null,
                columnas: [],
                omitir: false
            },
            campos: [],
            //file: null,
            fileSize: null,
            tableData: []
        }
    },
    methods: {
        previsualizar(file) {
            //this.file = file;
            let self = this;
            uploadService.saveFile(file).then((response) => {
                papa.parse(file, {
                    preview: 10,
                    complete(results) {
                        self.tableData = results.data;
                        self.datos.delimitador = results.meta.delimiter;
                        self.datos.columnas = _.fill(Array(results.data[0].length), null);
                    }
                });
            }).catch((error) => {
                notifyService.danger("No se pudo cargar el archivo");
            });
            
        },
        cargar() {
            agregarCorreos.call(this);
            //if(this.file) {
                //console.log(this.columnas);
                
                /*new Promise((resolve, reject) => {
                    papa.parse(this.file, {
                        //worker: true,
                        chunkSize: 1024 * 1024,
                        before(file, input) {
                            self.fileSize = file.size;
                        },
                        chunk(results, parser) {
                            let base = [];
                            if(!results.data || results.data.length <= 0) {
                                parser.abort();
                            }
                            _.each(results.data, (fila, i) => {
                                if(self.carga.omitir && i === 0 ) {
                                    return true;
                                }
                                let correo = {};
                                _.each(fila, function(dato, i) {
                                    let campo = self.columnas[i];
                                    if(campo) {
                                        correo[campo] = dato;
                                    }
                                });
                                base.push(correo);
                            });
                            results.meta.cursor - (self.carga.omitir ? 1 : 0);
                            let res = agregarCorreos.call(self, base);
                            if(!res) {
                                parser.abort();
                                reject();
                            }
                            self.progreso = Math.round(results.meta.cursor / self.fileSize * 100);
                        },
                        complete() {
                            resolve();
                        }
                    });
                });*/
            //}
        }
    },
    created() {
        this.ordenId = this.$route.params.id;
        obtenerOrden.call(this);
        obtenerCampos.call(this);
    }
});

function obtenerOrden() {
    let self = this;
    ordenesService.obtener(this.ordenId).then(response => {
        self.orden = response.data;
    });
}

function agregarCorreos() {
    let loader = this.$loading.show({
        container: this.$refs['form-container']
    });
    ordenesService.agregarCorreos(this.ordenId, this.datos).then((response) => {
        loader.hide();
        notifyService.success("Datos guardados correctamente");
        this.$router.push({ name: 'empresa.ordenes.detalle', params: { id: this.ordenId } });
    }).catch(error => {
        loader.hide();
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}

//Por el momento dejo la info fija (futuro => API)
function obtenerCampos() {
    this.campos = [
        {id: 'numero_guia', nombre: 'Número de guía'},
        {id: 'codigo_cliente', nombre: 'Código del cliente'},
        {id: 'codigo_destinatario', nombre: 'Código del destinatario (custcode)'},
        {id: 'codigo_destinatario_alt', nombre: 'Código del destinatario alternativo (custcode 2)'},
        {id: 'nombre_destinatario', nombre: 'Nombre del destinatario'},
        {id: 'empresa_destinatario', nombre: 'Empresa del destinatario'},
        {id: 'identificacion_destinatario', nombre: 'Identificación del destinatario'},
        {id: 'direccion_destinatario', nombre: 'Dirección del destinatario'},
        {id: 'barrio_destinatario', nombre: 'Barrio del destinatario'},
        {id: 'ciudad_destinatario', nombre: 'Ciudad del destinatario'},
        {id: 'departamento_destinatario', nombre: 'Departamento del destinatario'},
        {id: 'email_destinatario', nombre: 'Email del destinatario'},
        {id: 'telefono_destinatario', nombre: 'Teléfono del destinatario'},
        {id: 'celular_destinatario', nombre: 'Celular del destinatario'},
        {id: 'zona_destinatario', nombre: 'Zona del destinatario'},
        {id: 'producto', nombre: 'Producto'},
        {id: 'descripcion', nombre: 'Descripcion'},
        {id: 'alto', nombre: 'Alto'},
        {id: 'ancho', nombre: 'Ancho'},
        {id: 'largo', nombre: 'Largo'},
        {id: 'unidades', nombre: 'Unidades'},
        {id: 'peso', nombre: 'Peso'},
        {id: 'valor_declarado', nombre: 'Valor declarado'},
        {id: 'valor_recaudar', nombre: 'Valor a recaudar'},
        {id: 'codigo_postal', nombre: 'Código postal'},
        {id: 'codigo_dane', nombre: 'Código DANE'},
        {id: 'referencia_1', nombre: 'Referencia 1'},
        {id: 'referencia_2', nombre: 'Referencia 2'},
        {id: 'referencia_3', nombre: 'Referencia 3'},
        //{id: 'referencia_4', nombre: 'Referencia 4'},
        //{id: 'referencia_5', nombre: 'Referencia 5'},
        {id: 'nombre_remitente', nombre: 'Nombre del remitente'},
        {id: 'identificacion_remitente', nombre: 'Identificación del remitente'},
        {id: 'direccion_remitente', nombre: 'Dirección del remitente'},
        {id: 'ciudad_remitente', nombre: 'Ciudad del remitente'},
        {id: 'departamento_remitente', nombre: 'Departamento del remitente'},
        {id: 'email_remitente', nombre: 'Email del remitente'},
        {id: 'telefono_remitente', nombre: 'Teléfono del remitente'},
    ];
}