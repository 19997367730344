import axios from 'axios';

import url from 'services/url-service';

const path = '/private/correos';
const pathRastreo = '/public/rastreo';

class CorreosService {
    listar() {
        return axios.get(url.create(path));
    }
    listarFiltrado(limit, offset, criteria) {
        return axios.post(url.create(path + '/busqueda'), {
            limit,
            offset,
            criteria
            //criteria: encodeURI(JSON.stringify(criteria))
        });
    }
    obtenerEstadisticas(criteria) {
        return axios.post(url.create(path + '/estadisticas'), { criteria });
    }
    obtenerEnlaceXlsx(criteria) {
        return axios.post(url.create(path + '/busqueda/xlsx'), { criteria });
    }
    obtenerEnlaceCsv(criteria) {
        return axios.post(url.create(path + '/busqueda/csv'), { criteria });
    }
    obtener(id) {
        return axios.get(url.create(path + '/' + id));
    }
    editar(id, oficina) {
        return axios.put(url.create(path + '/' + id), oficina);
    }
    listarMovimientos(id) {
        return axios.get(url.create(path + '/' + id + '/movimientos'));
    }
    listarMovimientosRastreo(id) {
        return axios.get(url.create(pathRastreo + '/' + id + '/movimientos'));
    }
}

export default new CorreosService();