import objectStorageService from 'services/object-storage-service';
//import notifyService from 'services/notify-service';

//import clientesService from 'services/data/clientes-service';
import ordenesService from 'services/data/ordenes-service';
//import correosService from 'services/data/correos-service';
import guiasService from 'services/data/guias-service';
import enumsService from 'services/data/enums-service';

import render from './ordenes.detalle.html';

export default render({
    name: 'DetalleOrden',
    data() {
        return {
            ordenId: null,
            orden: {},
            campos: [],
            correos: [],
            modalGuias: false,
            templates: [],
            params: {
                template: null,
                margen_superior: 0,
                margen_izquierdo: 0,
            },
            pagination: {
                limit: 10,
                offset: 0,
                pages: 0,
                currentPage: 1,
            }
        }
    },
    methods: {
        impresion() {
            this.params = {
                orden_id: this.ordenId
            };
            this.modalGuias = true;
        },
        imprimir() {
            imprimir.call(this);
        },
        pageChange(page) {
            this.pagination.offset = (page - 1) * this.pagination.limit;
            listarCorreos.call(this);
        },
        templateChange() {
            let marginCorrections = objectStorageService.getValue(this.params.template) ?? {};
            this.params.margen_superior = marginCorrections.superior ?? 0;
            this.params.margen_izquierdo = marginCorrections.izquierdo ?? 0;
        }
    },
    created() {
        this.ordenId = this.$route.params.id;
        obtenerOrden.call(this);
        listarCorreos.call(this);
        listarTemplatesGuias.call(this);
    }
});

function obtenerOrden() {
    let self = this;
    ordenesService.obtener(this.ordenId).then(response => {
        self.orden = response.data;
    });
}

function listarCorreos() {
    let self = this;
    ordenesService.listarCorreos(this.ordenId, this.pagination.limit, this.pagination.offset).then(response => {
        self.correos = response.data.lista;
        self.pagination.pages = Math.ceil(response.data.total / self.pagination.limit);
    });
}

function listarTemplatesGuias() {
    let self = this;
    enumsService.listarTemplatesGuias().then(response => {
        self.templates = response;
    });
}

function imprimir() {
    let self = this;
    guiasService.obtenerEnlacePdf(this.params).then(response => {
        self.modalGuias = false;
        window.location.href = response.data.enlace;
        objectStorageService.setValue(this.params.template, {
            superior: self.params.margen_superior,
            izquierdo: self.params.margen_izquierdo,
        });
    });
}