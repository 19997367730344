import dialogService from 'services/dialog-service';
import notifyService from 'services/notify-service';
import mensajerosService from 'services/data/mensajeros-service';
import oficinasService from 'services/data/oficinas-service';
import enumsService from 'services/data/enums-service';


import render from './mensajeros.html';

export default render({
    name: 'Mensajeros',
    data() {
        return {
            titulo: 'Mensajeros',
            modal: false,
            id: null,
            filtro: {},
            mensajero: {
                tipos_tarifa: []
            },
            mensajeros: [],
            oficinas: [],
            tipos: [],
            tiposTarifa: [],
            estados: [],
            pagination: {
                limit: 10,
                offset: 0,
                pages: 0,
                currentPage: 1,
            }
        }
    },
    methods: {
        agregar() {
            this.id = null;
            this.mensajero = {
                tipos_tarifa: []
            };
            this.modal = true;
        },
        editar(mensajero) {
            this.id = mensajero.id;
            this.mensajero = {
                nombre: mensajero.nombre,
                identificacion: mensajero.identificacion,
                oficina_id: mensajero.oficina_id,
                tipo: mensajero.tipo,
                tipos_tarifa: mensajero.tipos_tarifa,
                estado: mensajero.estado,
            };
            this.modal = true;
        },
        eliminar(mensajero) {
            eliminar.call(this, mensajero);
        },
        guardar() {
            guardar.call(this);
        },
        filtrar() {
            this.pagination.offset = 0;
            this.pagination.currentPage = 1;
            listar.call(this);
        },
        pageChange(page) {
            this.pagination.offset = (page - 1) * this.pagination.limit;
            listar.call(this);
        },
        //Utils
        getTipo(tipoId) {
            return _.get(_.find(this.tipos, tipo => tipo.id === tipoId), 'nombre');
        },
        getEstado(estadoId) {
            return _.get(_.find(this.estados, estado => estado.id === estadoId), 'nombre');
        },
    },
    created() {
        listar.call(this);
        listarOficinas.call(this);
        listarTipos.call(this);
        listarTiposTarifa.call(this);
        listarEstados.call(this);
    }
});

function listar() {
    let self = this;
    mensajerosService.listarFiltrado(this.pagination.limit, this.pagination.offset, this.filtro).then(response => {
        self.mensajeros = response.data.lista;
        self.pagination.pages = Math.ceil(response.data.total / self.pagination.limit);
    });
}

function listarOficinas() {
    let self = this;
    oficinasService.listar().then(response => {
        self.oficinas = response.data.lista;
    });
}

function listarTipos() {
    let self = this;
    enumsService.listarTiposMensajero().then(response => {
        self.tipos = response;
    });
}

function listarEstados() {
    let self = this;
    enumsService.listarEstadosMensajero().then(response => {
        self.estados = response;
    });
}

function listarTiposTarifa() {
    let self = this;
    enumsService.listarTiposTarifa().then(response => {
        self.tiposTarifa = response;
    });
}

function guardar() {
    let self = this;
    let promise = this.id ? mensajerosService.editar(this.id, this.mensajero) : mensajerosService.agregar(this.mensajero);
    promise.then(response => {
        listar.call(self);
        self.modal = false;
        notifyService.success('Mensajero guardado correctamente');
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}

function eliminar(mensajero) {
    let self = this;
    dialogService.confirm(`Esta seguro de que desea eliminar este mensajero ${mensajero.nombre}?`, result => {
        if(result) {
            mensajerosService.eliminar(mensajero.id).then(response => {
                listar.call(self);
                notifyService.success('Mensajero eliminado correctamente');
            }).catch(error => {
                if(error.response.status === 409 && error.response.data.reasons) {
                    notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
                } else if(error.response.data.message) {
                    notifyService.danger(error.response.data.message);
                }
            });
        }
    });
}