<div>
    <h2>Dashboard</h2>
    <div class="row">
        <div class="col-4"></div>
        <div class="col-4"></div>
        <div class="col-4"></div>
    </div>
    <div class="row">
        <div class="col-12">
            <chart type="bar" :data="graph"></chart>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <h4>Usuarios bloqueados por inactividad</h4>
            <ul>
                <li v-for="usuario in usuariosInactivos">{{ usuario.nombre }}</li>
            </ul>
        </div>
        <div class="col-6">
            <h4>Mensajeros atrasados</h4>
            <ul>
                <li v-for="mensajero in mensajerosAtrasados">{{ mensajero.nombre }}</li>
            </ul>
        </div>
    </div>
</div>