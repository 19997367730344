<section class="wrapper">
    <header class="d-print-none">
        <nav class="navbar navbar-dark bg-dark fixed-top d-print-none navbar-expand-md">
            <router-link class="nav-link navbar-brand" :to="{ name: 'cliente' }">CorreoXpress 5.0</router-link>
            <div class="collapse navbar-collapse">
                <span class="navbar-text ml-auto">
                    Usted ingresó como: {{ $security.datos.nombre }}
                </span>
                <ul class="nav navbar-nav">
                    <li class="nav-item">
                        <a href="" @click.prevent="logout" class="nav-link" title="Salir">Salir</a>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
    <div class="container-fluid  full-height">
        <div class="row full-height">
            <div class="col-12 content d-print-block">
                <component v-bind:is="vista"></component>
                <!--transition name="slides" enter-active-class="animated slideInLeft" leave-active-class="animated slideOutRight">
                    <router-view></router-view>
                </transition-->
            </div>
        </div>
    </div>
    <footer class="footer d-print-none">
        <nav class="navbar navbar-expand-lg navbar-dark bg-red fixed-bottom">
            <span class="navbar-text">Diseñado y desarrollado por <a href="http://latinosoft.co">Latinososft S.A.S</a></span>
        </nav>
    </footer>
</section>