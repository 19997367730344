<div>
  <h3>{{ titulo }}</h3>
  <form class="form-condensed vld-parent" @submit.prevent="filtrar" ref="form-container">
    <fieldset>
      <legend>Criterios de búsqueda</legend>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-anio">Año:</label>
            <div class="col-md-8">
              <select class="form-control form-control-sm" id="filtro-anio" v-model="filtro.anio">
                <option v-for="anio in anios" :value="anio">{{ anio }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-cliente">Cliente:</label>
            <div class="col-md-8">
              <select class="form-control form-control-sm" id="filtro-cliente" v-model="filtro.proceso_id"
                v-searchable="{placeholder: 'Procesos', actionsBox: true}" multiple>
                <option v-for="proceso in procesos" :value="proceso.id">{{ proceso.cliente_nombre }} -
                  {{ proceso.referencia }}</option>
              </select>
            </div>
          </div>
        </div>
        <!--<div class="col-md-6" v-if="$security.check(['all', 'acceso_global'])">-->
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-oficina">Oficina:</label>
            <div class="col-md-8">
              <select class="form-control" id="filtro-oficina" v-model="filtro.oficina_id">
                <option :value="undefined">Seleccione uno</option>
                <option v-for="oficina in oficinas" :value="oficina.id">{{ oficina.ciudad }} / {{
                  oficina.departamento }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-orden-fecha-desde">Fecha orden:</label>
            <div class="col-md-8">
              <div class="row no-gutters">
                <div class="col">
                  <datetimepicker id="filtro-orden-fecha-desde" input-size="sm" placeholder="Fecha orden desde"
                    v-model="filtro.orden_fecha_desde">
                  </datetimepicker>
                </div>
                <div class="col">
                  <datetimepicker id="filtro-orden-fecha-hasta" input-size="sm" placeholder="Hasta"
                    v-model="filtro.orden_fecha_hasta"></datetimepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-orden-numero">Número de orden:</label>
            <div class="col-md-8">
              <input type="text" class="form-control form-control-sm" id="filtro-orden-numero"
                placeholder="Número de orden" v-model="filtro.orden_numero">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-numeros-guia">Códigos de barra:</label>
            <div class="col-md-8">
              <textarea class="form-control form-control-sm" id="filtro-numeros-guia" placeholder="Códigos de barra"
                v-model="filtro.numeros_guia"></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-producto">Producto:</label>
            <div class="col-md-8">
              <input type="text" class="form-control form-control-sm" id="filtro-producto" placeholder="Producto"
                v-model="filtro.producto">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-nombre-destinatario">Nombre del
              remitente:</label>
            <div class="col-md-8">
              <input type="text" class="form-control form-control-sm" id="filtro-nombre-remitente"
                placeholder="Nombre del remitente" v-model="filtro.nombre_remitente">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-nombre-destinatario">Nombre del
              destinatario:</label>
            <div class="col-md-8">
              <input type="text" class="form-control form-control-sm" id="filtro-nombre-destinatario"
                placeholder="Nombre del destinatario" v-model="filtro.nombre_destinatario">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-direccion-destinatario">Dirección del
              destinatario:</label>
            <div class="col-md-8">
              <input type="text" class="form-control form-control-sm" id="filtro-direccion-destinatario"
                placeholder="Dirección del destinatario" v-model="filtro.direccion_destinatario">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-ciudad-destinatario">Ciudad-Departamento del
              destinatario:</label>
            <div class="col-md-8">
              <div class="input-group">
              <input type="text" class="form-control form-control-sm" id="filtro-ciudad-destinatario"
                placeholder="Ciudad" v-model="filtro.ciudad_destinatario">
              
              <input type="text" class="form-control form-control-sm" id="filtro-departamento-destinatario"
                placeholder="Departamento" v-model="filtro.departamento_destinatario">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-entrega-fecha-desde">Distribución:</label>
            <div class="col-md-8">
              <div class="row no-gutters">
                <div class="col">
                  <datetimepicker id="filtro-entrega-fecha-desde" input-size="sm" placeholder="Distribución desde"
                    v-model="filtro.fecha_entrega_desde">
                  </datetimepicker>
                </div>
                <div class="col">
                  <datetimepicker id="filtro-fecha-entrega-hasta" input-size="sm" placeholder="Hasta"
                    v-model="filtro.fecha_entrega_hasta"></datetimepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-fecha-recepcion-desde">Descargado
              desde:</label>
            <div class="col-md-8">
              <div class="row no-gutters">
                <div class="col">
                  <datetimepicker id="filtro-fecha-recepcion-desde" input-size="sm" placeholder="Descargado desde"
                    v-model="filtro.fecha_recepcion_desde">
                  </datetimepicker>
                </div>
                <div class="col">
                  <datetimepicker id="filtro-fecha-recepcion-hasta" input-size="sm" placeholder="Hasta"
                    v-model="filtro.fecha_recepcion_hasta"></datetimepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-codigo-destinatario">Código del
              destinatario:</label>
            <div class="col-md-8">
              <input type="text" class="form-control form-control-sm" id="filtro-codigo-destinatario"
                placeholder="Código del destinatario" v-model="filtro.codigo_destinatario">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-estado">Estado:</label>
            <div class="col-md-8">
              <select class="form-control form-control-sm" id="filtro-estado" v-model="filtro.estado">
                <option :value="undefined">Seleccione uno</option>
                <option v-for="estado in estados" :value="estado.id">{{ estado.nombre }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="form-control-label col-md-4" for="filtro-mensajero">Mensajero:</label>
            <div class="col-md-8">
              <select class="form-control form-control-sm" id="filtro-mensajero" v-model="filtro.mensajero_id"
                v-searchable>
                <option :value="undefined">Seleccione uno</option>
                <option v-for="mensajero in mensajeros" :value="mensajero.id">{{
                  mensajero.oficina_ciudad }}/{{ mensajero.oficina_departamento }} - {{
                  mensajero.nombre }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-8 offset-4">
            <button type="submit" class="btn btn-secondary" @click.prevent="filtrar">Buscar</button>
            <button v-if="$security.check(['all', 'exportar_excel'])" type="button" class="btn btn-success"
              @click="descargarXlsx">Exportar a xlsx</button>
            <button v-if="$security.check(['all', 'exportar_excel'])" type="button" class="btn btn-success"
              @click="descargarCsv">Exportar a csv</button>
            <button v-if="$security.check(['all', 'generar_estadisticas'])" type="button" class="btn btn-primary"
              @click="verEstadisticas">Ver estadísticas</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row" v-if="estadisticas">
    <div class="col-md-6">
      <table class="table">
        <tr>
          <td>Sin asignar</td>
          <td class="text-right">{{ estadisticas.totales.por_entregar + estadisticas.totales.sin_procesar }}
          </td>
          <td class="text-right">{{ estadisticas.total > 0 ? (((estadisticas.totales.por_entregar * 100 +
            estadisticas.totales.sin_procesar) / estadisticas.total).toFixed(2)) + '%' : 'ND' }}</td>
        </tr>
        <tr>
          <td>En distribución</td>
          <td class="text-right">{{ estadisticas.totales.distribucion }}</td>
          <td class="text-right">{{ estadisticas.total > 0 ? ((estadisticas.totales.distribucion * 100 /
            estadisticas.total).toFixed(2)) + '%' : 'ND' }}</td>
        </tr>
        <tr>
          <td>Efectivo</td>
          <td class="text-right">{{ estadisticas.totales.efectivo }}</td>
          <td class="text-right">{{ estadisticas.total > 0 ? ((estadisticas.totales.efectivo * 100 /
            estadisticas.total).toFixed(2)) + '%' : 'ND' }}</td>
        </tr>
        <tr>
          <td>Devuelto</td>
          <td class="text-right">{{ estadisticas.totales.devuelto + estadisticas.totales.novedad }}</td>
          <td class="text-right">{{ estadisticas.total > 0 ? (((estadisticas.totales.devuelto +
            estadisticas.totales.novedad) * 100 / estadisticas.total).toFixed(2)) + '%' : 'ND' }}</td>
        </tr>
        <tr>
          <td>Inconsistente</td>
          <td class="text-right">{{ estadisticas.totales.inconsistentes }}</td>
          <td class="text-right">{{ estadisticas.total > 0 ? ((estadisticas.totales.inconsistentes * 100 /
            estadisticas.total).toFixed(2)) + '%' : 'ND' }}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td class="text-right">{{ estadisticas.total }}</td>
        </tr>
        <tr>
          <td>Total guías</td>
          <td class="text-right">{{ estadisticas.guias }}</td>
        </tr>
      </table>
    </div>
    <div class="col-md-6">
      <chart type="pie" :data="chart1" height="280"></chart>
    </div>
  </div>
  <div class="row" v-if="correos.length > 0">
    <div class="col-12">
      <div>Mostrando del {{ pagination.offset + 1 }} al {{ pagination.offset + correos.length }} de {{
        pagination.total }} registros</div>
      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Guía No.</th>
              <th>Cliente</th>
              <th>Código</th>
              <th>Identificación</th>
              <th>Remitente</th>
              <th>Destinatario</th>
              <th>Barrio</th>
              <th>Ciudad</th>
              <th>Producto</th>
              <th>Fecha entrega</th>
              <th>Fecha recepción</th>
              <th>Mensajero</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="correo in correos">
              <td>{{ correo.numero_guia }}
                <br>
                <strong>OS No.: {{ correo.orden_numero }}</strong>
                <br>{{ correo.orden_fecha }}
              </td>
              <td>{{ correo.cliente_nombre }} - {{ correo.proceso_referencia }}</td>
              <td>{{ correo.codigo_destinatario }}</td>
              <td>{{ correo.identificacion_destinatario }}</td>
              <td>{{ correo.nombre_remitente }}</td>
              <td>{{ correo.nombre_destinatario }}<br>{{ correo.direccion_destinatario }}</td>
              <td>{{ correo.barrio_destinatario }}</td>
              <td>{{ correo.ciudad_destinatario }} / {{ correo.departamento_destinatario }}</td>
              <td>{{ correo.producto }}</td>
              <td>{{ correo.fecha_entrega }}</td>
              <td>{{ correo.fecha_recepcion }}</td>
              <td>{{ correo.mensajero_nombre }}</td>
              <td>{{ estado(correo) }}</td>
              <td>
                <a href="" :class="{guia: correo.guia, documento: correo.documento}" @click.prevent="verGuia(correo)"
                  title="Ver guia">
                  <i class="mdi mdi-receipt mdi-lg"></i>
                </a>
                <a v-if="$security.check(['all', 'editar_correos'])" href="" @click.prevent="editar(correo)"
                  title="Editar">
                  <i class="mdi mdi-mode-edit mdi-lg"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="pageChange"></pagination>
    </div>
  </div>
  <modal v-model="modalGuia">
    <div slot="header" class="modal-header">
      <h4 class="modal-title">{{ tituloGuia }}</h4>
      <button type="button" class="close" @click="modalGuia = false"><span>&times;</span></button>
    </div>
    <div class="modal-body">
      <div v-if="srcGuia">
        <img :src="srcGuia" :alt="tituloGuia">
      </div>
      <div v-if="srcDocumento">
        <a :href="srcDocumento" target="_blank">Descargar documento (PDF)</a>
      </div>
      <div v-if="datosGuia">
        <h4>Datos de la guía</h4>
        <dl class="row">
          <dt class="col-sm-4">Cliente:</dt>
          <dd class="col-sm-8">{{ datosGuia.cliente }}</dd>
          <dt class="col-sm-4">Nombre:</dt>
          <dd class="col-sm-8">{{ datosGuia.nombre_destinatario }}</dd>
          <dt class="col-sm-4">Dirección:</dt>
          <dd class="col-sm-8">{{ datosGuia.direccion_destinatario }}</dd>
        </dl>
      </div>
      <div v-if="movimientos">
        <h4>Tiempos y movimientos</h4>
        <dl class="row">
          <dt class="col-sm-6">Ingreso de la orden:</dt>
          <dd class="col-sm-6">{{ movimientos.fecha_orden }}</dd>
          <dt class="col-sm-6">Fecha de cargue del detalle:</dt>
          <dd class="col-sm-6">{{ movimientos.fecha_cargue }}</dd>
          <dt class="col-sm-6">Fecha de llegada del producto:</dt>
          <dd class="col-sm-6">{{ movimientos.fecha_llegada_producto }}</dd>
          <template v-if="movimientos.fecha_verificacion">
            <dt class="col-sm-6">Fecha de verificación:</dt>
            <dd class="col-sm-6">{{ movimientos.fecha_verificacion }}</dd>
          </template>
          <template v-if="movimientos.fecha_transito">
            <dt class="col-sm-6">En transito a ciudad de destino:</dt>
            <dd class="col-sm-6">{{ movimientos.fecha_transito }}</dd>
          </template>
          <template v-if="movimientos.fecha_alistamiento">
            <dt class="col-sm-6">En alistamiento:</dt>
            <dd class="col-sm-6">{{ movimientos.fecha_alistamiento }}</dd>
          </template>
          <template v-for="movimiento in movimientos.lista">
            <dt class="col-sm-6">En Distribución:</dt>
            <dd class="col-sm-6">{{ movimiento.fecha_entrega }} a {{ movimiento.mensajero_nombre }} ({{
              movimiento.operador_entrega }})</dd>
            <template v-if="movimiento.fecha_recepcion">
              <dt class="col-sm-6">{{ estado(movimiento) }}:</dt>
              <dd class="col-sm-6">{{ movimiento.fecha_recepcion }} ({{ movimiento.operador_recepcion }})
              </dd>
            </template>
          </template>
          <template v-if="movimientos.fecha_devolucion_transito">
            <dt class="col-sm-6">Envío a CEDIS para devolución:</dt>
            <dd class="col-sm-6">{{ movimientos.fecha_devolucion_transito }}</dd>
          </template>
          <template v-if="movimientos.fecha_recibido_cedis">
            <dt class="col-sm-6">Recibido en CEDIS:</dt>
            <dd class="col-sm-6">{{ movimientos.fecha_recibido_cedis }}</dd>
          </template>
          <template v-if="movimientos.fecha_devolucion_remitente">
            <dt class="col-sm-6">Devuelto al remitente:</dt>
            <dd class="col-sm-6">{{ movimientos.fecha_devolucion_remitente }}</dd>
          </template>
        </dl>
      </div>
    </div>
    <div slot="footer" class="modal-footer"></div>
  </modal>
  <form @submit.prevent="guardar">
    <modal v-model="modal">
      <div slot="header" class="modal-header">
        <h4 class="modal-title">{{ titulo }}</h4>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label class="form-control-label" for="correo-nombre">Nombre:</label>
          <input type="text" class="form-control" id="correo-nombre" placeholder="Nombre"
            v-model="correo.nombre_destinatario">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-identificacion">Identificación:</label>
          <input type="text" class="form-control" id="correo-identificacion" placeholder="Identificación"
            v-model="correo.identificacion_destinatario">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-direccion">Dirección:</label>
          <input type="text" class="form-control" id="correo-direccion" placeholder="Dirección"
            v-model="correo.direccion_destinatario">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-barrio">Barrio:</label>
          <input type="text" class="form-control" id="correo-barrio" placeholder="Barrio"
            v-model="correo.barrio_destinatario">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-ciudad">Ciudad:</label>
          <input type="text" class="form-control" id="correo-ciudad" placeholder="Ciudad"
            v-model="correo.ciudad_destinatario">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-departamento">Departamento:</label>
          <input type="text" class="form-control" id="correo-departamento" placeholder="Departamento"
            v-model="correo.departamento_destinatario">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-email">Email:</label>
          <input type="text" class="form-control" id="correo-email" placeholder="Email"
            v-model="correo.email_destinatario">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-telefono">Teléfono:</label>
          <input type="text" class="form-control" id="correo-telefono" placeholder="Teléfono"
            v-model="correo.telefono_destinatario">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-celular">Celular:</label>
          <input type="text" class="form-control" id="correo-celular" placeholder="Celular"
            v-model="correo.celular_destinatario">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-producto">Producto:</label>
          <input type="text" class="form-control" id="correo-producto" placeholder="Producto" v-model="correo.producto">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-referencia-1">Referencia 1:</label>
          <input type="text" class="form-control" id="correo-referencia-1" placeholder="Referencia 1"
            v-model="correo.referencia_1">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-referencia-2">Referencia 2:</label>
          <input type="text" class="form-control" id="correo-referencia-2" placeholder="Referencia 2"
            v-model="correo.referencia_2">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-referencia-3">Referencia 3:</label>
          <input type="text" class="form-control" id="correo-referencia-3" placeholder="Referencia 3"
            v-model="correo.referencia_3">
        </div>
        <!--
                <div class="form-group">
                    <label class="form-control-label" for="correo-referencia-4">Referencia 4:</label>
                    <input type="text" class="form-control" id="correo-referencia-4" placeholder="Referencia 4" v-model="correo.referencia_4">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="correo-referencia-5">Referencia 5:</label>
                    <input type="text" class="form-control" id="correo-referencia-5" placeholder="Referencia 5" v-model="correo.referencia_5">
                </div>
                -->
        <div class="form-group">
          <label class="form-control-label" for="correo-peso">Peso:</label>
          <input type="text" class="form-control" id="correo-referencia-3" placeholder="Peso" v-model="correo.peso">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-valor-declarado">Valor declarado:</label>
          <input type="text" class="form-control" id="correo-valor-declarado" placeholder="Valor declarado"
            v-model="correo.valor_declarado">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-valor-recaudar">Valor a recaudar:</label>
          <input type="text" class="form-control" id="correo-valor-recaudar" placeholder="Valor a recaudar"
            v-model="correo.valor_recaudar">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-email-remitente">Email del remitente:</label>
          <input type="text" class="form-control" id="correo-email-remitente" placeholder="Email del remitente"
            v-model="correo.email_remitente">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-telefono-remitente">Teléfono del remitente:</label>
          <input type="text" class="form-control" id="correo-telefono-remitente" placeholder="Teléfono del remitente"
            v-model="correo.telefono_remitente">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-observaciones">Observaciones:</label>
          <textarea class="form-control" id="correo-observaciones" placeholder="Observaciones"
            v-model="correo.observaciones"></textarea>
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-gestion-1">Gestión 1:</label>
          <input type="text" class="form-control" id="correo-gestion-1" placeholder="Gestión 1"
            v-model="correo.gestion_1">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-gestion-1">Gestión 2:</label>
          <input type="text" class="form-control" id="correo-gestion-2" placeholder="Gestión 2"
            v-model="correo.gestion_2">
        </div>
        <div class="form-group">
          <label class="form-control-label" for="correo-gestion-3">Gestión 3:</label>
          <input type="text" class="form-control" id="correo-gestion-3" placeholder="Gestión 3"
            v-model="correo.gestion_3">
        </div>
      </div>
      <div slot="footer" class="modal-footer">
        <button type="submit" class="btn btn-success">Guardar</button>
        <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
      </div>
    </modal>
  </form>
</div>