import urlService from 'services/url-service';

import guiasService from 'services/data/guias-service';

import windows1252 from 'windows-1252';
import moment from 'moment';
import papa from 'papaparse';

import render from './cadena.html';
export default render({
    name: 'ReporteCadena',
    data() {
        return {
            titulo: 'Reporte cadena',
            archivo: null,
            filtro: {},
            //estadosVerificacion: [],
            detallesEstado: [],
        }
    },
    methods: {
        cargarArchivo(file) {
            this.archivo = file;
        },
        descargarReporte() {
            descargarReporte.call(this);
        }
    },
    created() {
        //listarEstadosVerificacionCorreo.call(this);
        listarDetallesEstadoCorreo.call(this);
    }
});

function descargarReporte() {
    let self = this;
    papa.parse(this.archivo, {
        encoding: 'cp1252',
        complete(results) {
            (async () => {
                //_.each(results.data, (fila, i) => {
                // const CARGADO = 'Cargado';
                // const VERIFICADO = 'Verificado';
                // const DISTRIBUCION = 'En distribución';
                // const EFECTIVO = 'Entrega efectiva';
                // const DEVUELTO = 'Devuelto';
                // const REASIGNADO = 'Reasignado';
                // const NOVEDAD = 'Novedad';
                for(let i = 0; i < results.data.length; i++) {
                    let fila = results.data[i];
                    if(i > 0) {
                        if(fila.length > 15) {
                            let guias = await guiasService.obtener(fila[15], 'gg');
                            if(guias.data && guias.data.length > 0) {
                                let guia = guias.data[0];
                                //let detalleEstado = '';
                                if(guia.estado_verificacion == 'CORRECTO') {
                                    switch(guia.estado) {
                                        case 'VERIFICADO':
                                            results.data[i][18] = 'Proceso de Distribución';
                                            results.data[i][19] = moment(guia.fecha_verificacion).format('DD/MM/YYYY');
                                            break;
                                        case 'DISTRIBUCION':
                                            results.data[i][18] = 'Proceso de Distribución';
                                            results.data[i][19] = moment(guia.fecha_entrega).format('DD/MM/YYYY');
                                            break;
                                        case 'EFECTIVO':
                                            results.data[i][18] = 'Entregado';
                                            results.data[i][19] = moment(guia.fecha_recepcion).format('DD/MM/YYYY');
                                            break;
                                        case 'DEVUELTO':
                                            results.data[i][18] = 'Devuelto';
                                            results.data[i][19] = moment(guia.fecha_recepcion).format('DD/MM/YYYY');
                                            //detalleEstado = _.find(self.detallesEstado[guia.estado], estado => estado.id === guia.detalle_estado);
                                            results.data[i][20] = self.detallesEstado[guia.detalle_estado];
                                            break;
                                        case 'NOVEDAD':
                                            results.data[i][18] = 'Siniestro';
                                            results.data[i][19] = moment(guia.fecha_recepcion).format('DD/MM/YYYY');
                                            //detalleEstado = _.find(self.detallesEstado[guia.estado], estado => estado.id === guia.detalle_estado);
                                            //results.data[i][20] = detalleEstado.nombre;
                                            results.data[i][20] = '';
                                            break;
                                    }
                                } else {
                                    if(guia.estado === 'CARGADO') {
                                        results.data[i][18] = 'Proceso de Distribución';
                                        results.data[i][19] = moment(guia.creado_en).format('DD/MM/YYYY');
                                    } else {
                                        results.data[i][18] = 'Inconsistencia';
                                        results.data[i][19] = moment(guia.fecha_verificacion).format('DD/MM/YYYY');
                                    }
                                    //let estadoVerificacion = _.find(self.estadosVerificacion, estado => estado.id === guia.estado_verificacion);
                                    //results.data[i][20] = estadoVerificacion.nombre;
                                    results.data[i][20] = '';
                                }
                                results.data[i][21] = guia.orden_numero;
                                results.data[i][22] = guia.guia ? urlService.create('/public/guias/' + guia.ciudad + '/' + guia.orden_numero + '/' + guia.numero_guia) : '';
                            }
                        }
                    } else {
                        results.data[i][18] = 'Estado';
                        results.data[i][19] = 'Fecha de Estado';
                        results.data[i][20] = 'Causa de Devolución';
                        results.data[i][21] = 'Orden de Servicio';
                        results.data[i][22] = 'Nombre de la imagen';
                    }
                };
                //console.log(results.data);

                exportToCsv('cadena.txt', results.data);
            })();
        }
    });
}

function exportToCsv(filename, rows) {
    let csv = _.map(rows, (row) => {
        return row.join('|');
    }).join('\r\n');
    let encodedCsv = windows1252.encode(csv);
    var blob = new Blob([encodedCsv], { type: 'text/csv;charset=windows-1252;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            
        }
    }
}

//function listarEstadosVerificacionCorreo() {
//    let self = this;
//    enumsService.listarEstadosVerificacionCorreo().then(response => {
//        self.estadosVerificacion = response;
//    });
//}

function listarDetallesEstadoCorreo() {
    this.detallesEstado = {
        'NO_EXISTE': 'No existe',
        'DESTINATARIO_DESCONOCIDO': 'Destinatario Desconocido',
        'RECHAZADO': 'Rehusado',
        'CAMBIO_DOMICILIO': 'Cambio de Domicilio',
        'DIRECCION_INCOMPLETA': 'Dirección Incompleta',
        'DIRECCION_ERRONEA': 'Dirección Incompleta',
        'CERRADO': 'Cerrado',
        'FALLECIDO': 'Fallecido',
        'DIFICIL_ACCESO': 'Zona Roja',
        'ZONA_ROJA': 'Zona Roja',
    };
}

//const NO_EXISTE = 'No existe (NE)';
//const DESTINATARIO_DESCONOCIDO = 'Destinatario desconocido (DD)';
//const RECHAZADO = 'Rechazado (RH)';
//const CAMBIO_DOMICILIO = 'Cambio domicilio (CD)';
//const DIRECCION_INCOMPLETA = 'Dirección incompleta (DI)';//Tocó repetir
//const DIRECCION_ERRONEA = 'Dirección erronea (DE)';
//const CERRADO = 'Cerrado (CE)';
//const FALLECIDO = 'Fallecido (F)';
//const DIFICIL_ACCESO = 'Difícil acceso (DA)';
//const ZONA_ROJA = 'Zona roja (ZR)';