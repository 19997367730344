import dialogService from 'services/dialog-service';
import notifyService from 'services/notify-service';
import oficinasService from 'services/data/oficinas-service';
import render from './oficinas.html';


export default render({
    name: 'Oficinas',
    data() {
        return {
            titulo: 'Oficinas principales',
            modal: false,
            id: null,
            oficina: {},
            oficinas: []
        }
    },
    methods: {
        agregar() {
            this.id = null;
            this.oficina = {};
            this.modal = true;
        },
        editar(oficina) {
            this.id = oficina.id;
            this.oficina = {
                ciudad: oficina.ciudad,
                departamento: oficina.departamento,
                direccion: oficina.direccion,
                codigo_postal: oficina.codigo_postal,
                guia_margen_superior: oficina.guia_margen_superior,
                guia_margen_izquierdo: oficina.guia_margen_izquierdo
            };
            this.modal = true;
        },
        eliminar(oficina) {
            eliminar.call(this, oficina);
        },
        guardar() {
            guardar.call(this);
        },
    },
    created() {
        listar.call(this);
    }
});

function listar() {
    let self = this;
    oficinasService.listarFiltrado().then(response => {
        self.oficinas = response.data.lista;
    });
}

function guardar() {
    let self = this;
    let promise = this.id ? oficinasService.editar(this.id, this.oficina) : oficinasService.agregar(this.oficina);
    promise.then(response => {
        listar.call(self);
        self.modal = false;
        notifyService.success('Oficina guardada correctamente');
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}

function eliminar(oficina) {
    let self = this;
    dialogService.confirm(`Esta seguro de que desea eliminar esta oficina ${oficina.ciudad} / ${oficina.departamento}?`, result => {
        if(result) {
            oficinasService.eliminar(oficina.id).then(response => {
                listar.call(self);
                notifyService.success('Oficina eliminada correctamente');
            }).catch(error => {
                if(error.response.status === 409 && error.response.data.reasons) {
                    notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
                } else if(error.response.data.message) {
                    notifyService.danger(error.response.data.message);
                }
            });
        }
    });
}