<div>
    <h3>Orden No.
        <span class="text-info">{{ orden.numero }}</span> de
        <span class="text-info">{{ orden.fecha }}</span>
    </h3>
    <form class="vld-parent" @submit.prevent="cargar" ref="form-container">
        <div class="row">
            <div class="col-12">
                <div class="form-group row">
                    <label for="subir-archivo" class="col-2 form-control-label">Archivo plano:</label>
                    <div class="col-10">
                        <fileupload id="subir-archivo" placeholder="Archivo plano" @change="previsualizar" v-model="datos.archivo"></fileupload>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="subir-omitir-linea" class="col-2 form-control-label">Omitir la primera línea:</label>
                    <div class="col-10">
                        <div class="form-check">
                            <label>
                                <input type="checkbox" id="subir-omitir-linea" v-model="datos.omitir">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-10 offset-2">
                        <button type="submit" class="btn btn-secondary">Procesar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12"></div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table table-sm" v-if="tableData.length">
                        <thead>
                            <tr>
                                <th v-for="(n, i) in tableData[0].length">
                                    <select v-model="datos.columnas[i]">
                                        <option :value="null">Seleccione uno</option>
                                        <option v-for="campo in campos" :value="campo.id">{{ campo.nombre }}</option>
                                    </select>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in tableData">
                                <td v-for="(val, j) in row">{{ val }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
</div>