import axios from 'axios';

import url from 'services/url-service';

class EnumsService {
    constructor() {
        this.listas = {};
    }
    
    listarTiposCliente() {        
        return getEnum.call(this, '/private/clientes/tipos');
    }

    listarTiposTarifa() {
        return getEnum.call(this, '/private/clientes/procesos/tipos-tarifa');
    }

    listarTiposMensajero() {        
        return getEnum.call(this, '/private/mensajeros/tipos');
    }

    listarEstadosMensajero() {        
        return getEnum.call(this, '/private/mensajeros/estados');
    }

    listarTiposProductoClaro() {
        return getEnum.call(this, '/private/ordenes/tipos-producto-claro');
    }

    listarTiposCorreo() {
        return getEnum.call(this, '/private/correos/tipos');
    }

    listarEstadosCorreo() {
        return getEnum.call(this, '/public/correos/estados');
    }

    listarEstadosVerificacionCorreo() {
        return getEnum.call(this, '/public/correos/estados-verificacion');
    }
    
    listarEstadosPreprocesoCorreo() {
        return getEnum.call(this, '/public/correos/estados-preproceso');
    }

    listarEstadosPostprocesoCorreo() {
        return getEnum.call(this, '/public/correos/estados-postproceso');
    }

    listarDetallesEstadoCorreo() {
        return getEnum.call(this, '/public/correos/detalles-estado');
    }

    listarPermisos() {
        return getEnum.call(this, '/private/roles/permisos');
    }

    listarEstadosUsuario() {        
        return getEnum.call(this, '/private/usuarios/estados');
    }

    listarTemplatesGuias() {
        return getEnum.call(this, '/private/guias/templates');
    }

}

function getEnum(path) {
    let self = this;
    return new Promise(function(resolve, reject) {
        if(self.listas[path] && self.listas[path].length > 0) {
            resolve(self.listas[path]);
        } else {
            axios.get(url.create(path)).then(response => {
                self.listas[path] = response.data;
                resolve(self.listas[path]);
            }).catch(reject);
        }
    });
}

export default new EnumsService();