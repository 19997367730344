import axios from 'axios';

import url from 'services/url-service';
import jwt from './jwt-service';

class AuthenticationService {
    valid() {
        let token = jwt.getToken();
        return token ? token.valid() : token;
    }
    verify() {
        return new Promise((resolve, reject) => {
            var token = jwt.getToken();
            if(token.valid()) {
                resolve();
            } else {
                var refreshToken = jwt.getRefreshToken();
                if(refreshToken.valid()) {
                    let authPath = url.create('/private/auth/refresh');
                    axios.post(authPath, [], {
                        headers: {
                            'Authorization': 'Bearer ' + jwt.getRawRefreshToken()
                        }
                    }).then((response) => {
                        jwt.saveToken(response.data.token);
                        jwt.saveRefreshToken(response.data.refresh_token);
                        resolve();
                    }).catch((error) => {
                        //console.log(error.response);
                        reject();
                    });
                } else {
                    reject();
                }
            }
        });
    }
    authenticate(credenciales) {
        let authPath = url.create('/public/auth/token');
        return axios.post(authPath, credenciales).then(response => {
            jwt.saveToken(response.data.token);
            jwt.saveRefreshToken(response.data.refresh_token);
            return response;
        });
    }
    unAuthenticate() {
        jwt.deleteToken();
        jwt.deleteRefreshToken();
    }

}

export default new AuthenticationService();