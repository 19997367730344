import axios from 'axios';

import url from 'services/url-service';

const path = '/private/guias';
const pathRastreo = '/public/rastreo';

class GuiasService {
    obtener(guia, tipoVerificacion = 'g') {
        return axios.get(url.create(path + '/' + guia), {
            params: {
                'tipo_verificacion': tipoVerificacion
            }
        });
    }
    obtenerRastreo(guia, tipoVerificacion = 'r') {
        return axios.get(url.create(pathRastreo + '/' + guia), {
            params: {
                'tipo_verificacion': tipoVerificacion
            }
        });
    }
    obtenerEnlacePdf(params) {
        return axios.get(url.create(path + '/pdf'), {
            params
        });
    }
    obtenerEnlaceLotesPdf(params) {
        return axios.get(url.create(path + '/lotes/pdf'), {
            params
        });
    }
}

export default new GuiasService();