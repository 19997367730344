import render from './fileupload.html';

export default render({
    name: 'FileUpload',
    props: {
        value: {
            type: String
        },
        id: {
            type: String
        },
        inputSize: {
            type: String
        },
        placeholder: {
            type: String
        },
        //path: {
        //    type: String,
        //    default: '/private/comun/upload'
        //},
        name: {
            type: String,
            default: 'file'
        }
    },
    mounted() {
        let self = this;
        $(this.$el).find('input[type="file"]').on('change', function(event) {
            let files = event.target.files || event.dataTransfer.files;
            if (files.length) {
                let value = files[0].name;
                self.$emit('change', files[0]);
                self.$emit('input', value);
                $(event.target).replaceWith($(event.target).val('').clone(true));
            }
        });
    }
});