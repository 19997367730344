<div :id="id" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li v-for="(image, index) in images" :data-target="'#' + id" :data-slide-to="index" :class="index == 0 ? 'active' : ''"></li>
    </ol>
    <div class="carousel-inner">
        <div v-for="(image, index) in images" :class="'carousel-item' +  (index == 0 ? ' active' : '')">
            <!-- Set the first background image using inline CSS below. -->
            <!--
            <div class="fill" :style="'background-image:url(\'' + image.src + '\');'"></div>
            -->
            <div class="d-block w-100">
                <img class="d-block w-100" :src="image.src" :alt="image.caption">
            </div>
            <div class="carousel-caption">
                <h2>{{ image.caption }}</h2>
            </div>
        </div>
    </div>
    <a class="carousel-control-prev" :href="'#' + id" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" :href="'#' + id" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>