<div>
    <h3 class="d-print-none">{{ titulo }}</h3>
    <form @submit.prevent="descargarXlsx" class="d-print-none">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col-md-8">
                        <button type="submit" class="btn btn-secondary">Generar reporte</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>