import axios from 'axios';

import url from 'services/url-service';

const path = '/private/ordenes';

class ordensService {
    listar() {
        return axios.get(url.create(path));
    }
    listarFiltrado(limit, offset, criteria) {
        return axios.get(url.create(path), {
            params: {
                limit,
                offset,
                criteria
                //criteria: encodeURI(JSON.stringify(criteria))
            }
        });
    }
    obtenerEnlaceXlsx(criteria) {
        return axios.get(url.create(path + '/xlsx'), {
            params: {
                criteria
            }
        });
    }
    obtener(id) {
        return axios.get(url.create(path + '/' + id));
    }
    obtenerPorNumero(numero) {
        return axios.get(url.create(path + '/numero/' + numero));
    }
    eliminar(id) {
        return axios.delete(url.create(path + '/' + id));
    }
    agregar(orden) {
        return axios.post(url.create(path), orden);
    }
    editar(id, orden) {
        return axios.put(url.create(path + '/' + id), orden);
    }
    cerrar(orden) {
        return axios.post(url.create(path + '/cerradas'), orden);
    }
    facturar(orden) {
        return axios.post(url.create(path + '/facturadas'), orden);
    }
    pagar(orden) {
        return axios.post(url.create(path + '/pagadas'), orden);
    }
    /*listarTipos() {
        return axios.get(url.create(path + '/tipos'));
    }*/
    listarCorreos(id, limit, offset) {
        return axios.get(url.create(path + '/' + id + '/correos'), {
            params: {
                limit,
                offset
                //criteria: encodeURI(JSON.stringify(criteria))
            }
        });
    }
    agregarCorreos(id, datos) {
        return axios.put(url.create(path + '/' + id + '/correos'), datos);
    }
    /*obtenerEnlaceGuiasPdf(id, params) {
        return axios.get(url.create(path + '/' + id + '/guias/pdf'), {
            params
        });
    }*/
}

export default new ordensService();