import notifyService from 'services/notify-service';
import usuariosService from 'services/data/usuarios-service';
import oficinasService from 'services/data/oficinas-service';
import rolesService from 'services/data/roles-service';
import enumsService from 'services/data/enums-service';

import render from './usuarios.html'

export default render({
    name: 'Usuarios',
    data() {
        return {
            titulo: 'Usuarios',
            modal: false,
            id: null,
            filtro: {},
            usuario: {},
            usuarios: [],
            oficinas: [],
            roles: [],
            estados: [],
            pagination: {
                limit: 10,
                offset: 0,
                pages: 0,
                currentPage: 1,
            }
        }
    },
    methods: {
        agregar() {
            this.id = null;
            this.usuario = {
                supervisor: 0
            };
            this.modal = true;
        },
        editar(usuario) {
            this.id = usuario.id;
            this.usuario = {
                nombre: usuario.nombre,
                email: usuario.email,
                username: usuario.username,
                oficina_id: usuario.oficina_id,
                supervisor: usuario.supervisor,
                rol_id: usuario.rol_id,
                estado: usuario.estado,
            };
            this.modal = true;
        },
        //eliminar(id) {
        //},
        guardar() {
            guardarUsuario.call(this);
        },
        filtrar() {
            this.pagination.offset = 0;
            this.pagination.currentPage = 1;
            listar.call(this);
        },
        pageChange(page) {
            this.pagination.offset = (page - 1) * this.pagination.limit;
            listar.call(this);
        },
        getEstado(estadoId) {
            return _.get(_.find(this.estados, estado => estado.id === estadoId), 'nombre');
        },
    },
    created() {
        listar.call(this);
        listarOficinas.call(this);
        listarRoles.call(this);
        listarEstados.call(this);
    }
});

function listar() {
    let self = this;
    usuariosService.listarFiltrado(this.pagination.limit, this.pagination.offset, this.filtro).then(response => {
        self.usuarios = response.data.lista;
        self.pagination.pages = Math.ceil(response.data.total / self.pagination.limit);
    });
}

function listarOficinas() {
    let self = this;
    oficinasService.listar().then(response => {
        self.oficinas = response.data.lista;
    });
}

function listarRoles() {
    let self = this;
    rolesService.listar().then(response => {
        self.roles = response.data.lista;
    });
}

function listarEstados() {
    let self = this;
    enumsService.listarEstadosUsuario().then(response => {
        self.estados = response;
    });
}

function guardarUsuario() {
    let self = this;
    let promise = this.id ? usuariosService.editar(this.id, this.usuario) : usuariosService.agregar(this.usuario);
    promise.then(response => {
        listar.call(self);
        self.modal = false;
        notifyService.success('Usuario guardado correctamente');
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}