import 'bootstrap-datetimepicker';
import 'moment/locale/es';
import render from './datetimepicker.html';

export default render({
    name: 'DateTimepickerComponent',
    props: {
        value: {
            type: String,
            default: null
        },
        id: {
            type: String
        },
        inputSize: {
            type: String
        },
        placeholder: {
            type: String
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(newValue, oldValue) {
            var picker = $(this.$el).data("DateTimePicker");
            if(picker) {
                picker.clear();
                picker.date(newValue || null);
            }
        }
    },
    mounted() {
        var $el = $(this.$el);
        $el.datetimepicker({
            locale: 'es',
            ignoreReadonly: true,
            useCurrent: false,
            format: this.format
        });
        var self = this;
        $el.on('dp.change', event => {
            let value = $el.children('input').val().trim();
            self.$emit('input', value || null);
        });
    }
});