<div>
    <h3 class="d-print-none">{{ titulo }}</h3>
    <form @submit.prevent="generarPlanilla" class="d-print-none vld-parent" ref="form-container">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label class="form-control-label" for="correo-fecha">Fecha:</label>
                    <div class="form-inline">
                        <datetimepicker id="correo-fecha-desde" placeholder="Fecha desde" v-model="filtro.fecha_desde"></datetimepicker>
                        <datetimepicker id="correo-fecha-hasta" placeholder="Fecha hasta" v-model="filtro.fecha_hasta"></datetimepicker>
                    </div>
                </div>
                <div class="form-group" v-if="$security.check(['all', 'acceso_global'])">
                    <label class="form-control-label" for="filtro-oficina">Oficina:</label>
                    <div class="form-inline">
                        <select class="form-control" id="filtro-oficina" v-model="filtro.oficina_id">
                            <option :value="undefined">Seleccione uno</option>
                            <option v-for="oficina in oficinas" :value="oficina.id">{{ oficina.ciudad }} / {{ oficina.departamento }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-secondary">Generar planilla</button>
                </div>
            </div>
        </div>
    </form>
    <div class="row d-print-block" v-if="planilla">
        <div class="col-12">
            <h3>{{ titulo }}</h3>
        </div>
        <div class="col-12">
            <table class="table table-bordered table-sm">
                <thead>
                    <tr>
                        <!-- Columnas -->
                        <th>Mensajero</th>
                        <th>Cantidad</th>
                        <th v-for="cliente in clientes">{{ cliente }}</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="fila in planilla">
                        <td>{{ fila.mensajero_nombre }}</td>
                        <td class="text-right">{{ fila.procesadas }}</td>
                        <td class="text-right" v-for="cliente, index in clientes">{{ fila.clientes[index] }}</td>
                        <td class="text-right">{{ fila.total }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <!-- Totales -->
                        <td>Totales</td>
                        <td class="text-right">{{ totales.procesadas }}</td>
                        <td class="text-right" v-for="cliente, index in clientes">{{ totales.clientes[index] }}</td>
                        <td class="text-right"><strong>{{ totales.movimientos + totales.bonificaciones - totales.descuentos }}</strong></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>