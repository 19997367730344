<div class="modal" :class="modalClass" tabindex="-1" role="dialog">
    <div class="modal-dialog" :class="dialogClass">
        <div class="modal-content">
            <!--Header-->
            <slot v-if="hasHeaderSlot" name="header"></slot>
            <!--Container-->
            <slot></slot>
            <!--Footer-->
            <slot v-if="hasFooterSlot" name="footer"></slot>
        </div>
    </div>
</div>