import notifyService from 'services/notify-service';
import dialogService from 'services/dialog-service';
import guiasService from 'services/data/guias-service';
import movimientosService from 'services/data/movimientos-service';
import enumsService from 'services/data/enums-service';

import moment from 'moment';
import papa from 'papaparse';

import render from './postproceso.html';

export default render({
    name: 'Postproceso',
    data() {
        return {
            titulo: 'Postproceso de correo',
            estado: null,
            tipo: null,
            numeroGuia: null,
            fecha: null,
            estados: [],
            procesar: [],
            procesadas: [],
            errores: [],
            modalUpload: false,
            archivo: null,
            nombreArchivo: null
        }
    },
    methods: {
        cambiarEstado() {
            this.$refs.guia.focus();
        },
        procesarPostproceso() {
            //console.log('procesar');
            procesarPostproceso.call(this);
        },
        comprobar() {
            //console.log('comprobar');
            comprobar.call(this, this.numeroGuia);
            this.numeroGuia = null;
        },
        cargar(archivo) {
            this.archivo = archivo;
        },
        procesarArchivo() {
            let self = this;
            papa.parse(this.archivo, {
                complete(results) {
                    _.each(results.data, (fila, i) => {
                        if(fila[0]) {
                            comprobar.call(self, fila[0]);
                        }
                    });
                    self.nombreArchivo = null;
                    self.archivo = null;
                    self.modalUpload = false;
                }
            });
        }
    },
    created() {
        listarEstadosPostprocesoCorreo.call(this);
    }
});

function comprobar(numeroGuia) {
    let self = this;
    guiasService.obtener(numeroGuia).then(response => {
        let data = response.data;
        if(data.length > 1) {
            //Permitir al usuario seleccionar
            dialogService.choose('Multiples correos encontrados',
            _.map(data, (d, i) => {
                return {
                    label: `Orden ${ d.orden_numero } de ${ d.orden_fecha }`,
                    value: i
                }
            }), (i) => {
                let correo = data[i];
                encolar.call(self, correo);
            });
        } else if(data.length === 1) {
            let correo = data[0];
            encolar.call(self, correo);
        } else {
            if(!_.find(self.errores, { 'numero_guia': numeroGuia })) {
                self.errores.push({ 'numero_guia': numeroGuia, 'error': 'No encontrada' });
            }
        }
    }).catch(error => {
        if(!_.find(self.errores, { 'numero_guia': numeroGuia })) {
            self.errores.push({ 'numero_guia': numeroGuia, 'error': 'Error código: ' + error.response.status });
        }
    });
}

function encolar(correo) {
    if(correo.estado === 'DEVUELTO' || correo.estado === 'DEVOLUCION_TRANSITO' || correo.estado === 'RECIBIDO_CEDIS' || correo.estado === 'DEVUELTO_REMITENTE') {
        if(this.fecha && correo.fecha_estado){
            let currentDate = moment(this.fecha)
            let lastMovementDate = moment(correo.fecha_estado)
            if(currentDate.isBefore(lastMovementDate)) {
                this.errores.push({ 'numero_guia': correo.numero_guia, 'error': 'Fechas inconsistentes' });
                return;
            }
        }
        if(!_.find(this.procesar, {'id': correo.id})) {
            this.procesar.push(correo);
        }
    } else {
        if(!_.find(this.procesadas, {'id': correo.id})) {
            this.procesadas.push(correo);
        }
    }
}

function procesarPostproceso() {
    if(!this.estado) {
        notifyService.danger('Estado es requerido');
        return;
    }
    movimientosService.procesarPostproceso(this.procesar, this.estado, this.fecha).then(response => {
        this.procesar = [];
        this.procesadas = [];
        this.errores = [];
        this.estado = null;
        notifyService.success('Finalizado correctamente');
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}

function listarEstadosPostprocesoCorreo() {
    enumsService.listarEstadosPostprocesoCorreo().then(response => {
        this.estados = response;
    });
}