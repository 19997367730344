import notifyService from "services/notify-service";
import dialogService from "services/dialog-service";
import guiasService from "services/data/guias-service";
import mensajerosService from "services/data/mensajeros-service";
import movimientosService from "services/data/movimientos-service";
import enumsService from "services/data/enums-service";

import moment from "moment";
import papa from "papaparse";

import render from "./entrega.html";

export default render({
  name: "Entrega",
  data() {
    return {
      titulo: "Entrega de correo",
      fecha: null,
      mensajero_id: null,
      mensajero: null,
      tipoTarifa: null,
      numeroGuia: null,
      mensajeros: [],
      tiposTarifa: [],
      procesar: [],
      procesadas: [],
      errores: [],
      modalUpload: false,
      archivo: null,
      nombreArchivo: null,
    };
  },
  computed: {
    tiposTarifaMensajero() {
      return _.filter(
        this.tiposTarifa,
        (tipoTarifa) =>
          this.mensajero &&
          this.mensajero.tipos_tarifa.indexOf(tipoTarifa.id) >= 0
      );
    },
  },
  methods: {
    cambiarMensajero() {
      this.$refs.tipoTarifa.focus();
      this.$nextTick(() => {
        obtenerMensajero.call(this);
      });
    },
    cambiarTipoTarifa() {
      this.$refs.guia.focus();
    },
    procesarEntrega() {
      //console.log('procesar');
      procesarEntrega.call(this);
    },
    comprobar() {
      //console.log('comprobar');
      comprobar.call(this, this.numeroGuia);
      this.numeroGuia = null;
    },
    cargar(archivo) {
      this.archivo = archivo;
    },
    procesarArchivo() {
      let self = this;
      papa.parse(this.archivo, {
        complete(results) {
          _.each(results.data, (fila, i) => {
            if (fila[0]) {
              comprobar.call(self, fila[0]);
            }
          });
          self.nombreArchivo = null;
          self.archivo = null;
          self.modalUpload = false;
        },
      });
    },
  },
  created() {
    listarMensajeros.call(this);
    listarTiposTarifa.call(this);
  },
});

function comprobar(numeroGuia) {
  let self = this;
  //let numeroGuia = this.numeroGuia;
  //this.numeroGuia = null;
  guiasService
    .obtener(numeroGuia, "e")
    .then((response) => {
      let data = response.data;
      if (data.length > 1) {
        //Permitir al usuario seleccionar
        dialogService.choose(
          "Multiples correos encontrados",
          _.map(data, (d, i) => {
            return {
              label: `Orden ${d.orden_numero} de ${d.orden_fecha}`,
              value: i,
            };
          }),
          (i) => {
            let correo = data[i];
            encolar.call(self, correo);
          }
        );
      } else if (data.length === 1) {
        let correo = data[0];
        encolar.call(self, correo);
      } else {
        if (!_.find(self.errores, { numero_guia: numeroGuia })) {
          self.errores.push({
            numero_guia: numeroGuia,
            error: "No encontrada",
          });
        }
      }
    })
    .catch((error) => {
      //console.log(error);
      if (!_.find(self.errores, { numero_guia: numeroGuia })) {
        self.errores.push({
          numero_guia: numeroGuia,
          error: "Error código: " + error.response.status,
        });
      }
    });
}

function _encolar(correo) {
  let fechaRecepcion = correo.fecha_recepcion
    ? moment(correo.fecha_recepcion)
    : null;
  let fechaEntrega = this.fecha ? moment(this.fecha) : moment();

  if (this.fecha && correo.fecha_estado) {
    let currentDate = moment(this.fecha + ' 08:00:00');
    let lastMovementDate = moment(correo.fecha_estado);
    if (currentDate.isBefore(lastMovementDate)) {
      this.errores.push({
        numero_guia: correo.numero_guia,
        error: "Fechas inconsistentes",
      });
      return;
    }
  }
  if (
    (fechaRecepcion &&
      fechaEntrega &&
      fechaRecepcion.isSameOrBefore(fechaEntrega)) ||
    !fechaRecepcion
  ) {
    if (!_.find(this.procesar, { id: correo.id })) {
      this.procesar.push(correo);
    }
  } else {
    this.errores.push({
      numero_guia: correo.numero_guia,
      error: "Incongruencia de fechas",
    });
  }
}

function encolar(correo) {
  if (
    _.includes(
      [
        "DISTRIBUCION",
        "EFECTIVO",
        "DEVOLUCION_TRANSITO",
        "RECIBIDO_CEDIS",
        "DEVUELTO_REMITENTE",
      ],
      correo.estado
    )
  ) {
    if (!_.find(this.procesadas, { id: correo.id })) {
      this.procesadas.push(correo);
    }
  } else {
    if (_.includes(["CORRECTO"], correo.estado_verificacion)) {
      if (correo.verificacion.devoluciones > 0) {
        dialogService.confirm(
          `Este correo ${correo.numero_guia} aparece devuelto ${correo.verificacion.devoluciones
          } veces, Desea seguir?`,
          (res) => {
            if (res) {
              _encolar.call(this, correo);
            } else {
              this.errores.push({
                numero_guia: correo.numero_guia,
                error: "Devolución anterior",
              });
            }
          }
        );
      } else if (correo.verificacion.movimientos >= 12) {
        //Verificar permisos
        this.errores.push({
          numero_guia: correo.numero_guia,
          error: "Muchos movimientos",
        });
      } else {
        _encolar.call(this, correo);
      }
    } else {
      if (!_.find(this.errores, { numero_guia: correo.numero_guia })) {
        this.errores.push({
          numero_guia: correo.numero_guia,
          error: "Correo inconsistente",
        });
      }
    }
  }
}

const procesarEntrega = _.debounce(function () {
  if (!(this.mensajero_id && this.tipoTarifa)) {
    notifyService.danger("Mensajero y tipo de tarifa son requeridos");
    return;
  }
  movimientosService
    .procesarEntrega(
      this.procesar,
      this.mensajero_id,
      this.tipoTarifa,
      this.fecha
    )
    .then((response) => {
      this.procesar = [];
      this.procesadas = [];
      this.errores = [];
      this.mensajero_id = null;
      this.mensajero = null;
      this.tipoTarifa = null;
      notifyService.success("Finalizado correctamente");
    })
    .catch((error) => {
      if (error.response.status === 409 && error.response.data.reasons) {
        notifyService.danger(
          notifyService.buildMessage(error.response.data.reasons)
        );
      } else if (error.response.data.message) {
        notifyService.danger(error.response.data.message);
      }
    });
}, 500)

function listarMensajeros() {
  mensajerosService.listarActivos().then((response) => {
    this.mensajeros = response.data.lista;
  });
}

function obtenerMensajero() {
  if (this.mensajero_id) {
    mensajerosService.obtener(this.mensajero_id).then((response) => {
      this.mensajero = response.data;
    });
  }
}

//Enums
function listarTiposTarifa() {
  enumsService.listarTiposTarifa().then((response) => {
    this.tiposTarifa = response;
  });
}

//function listarEstadosVerificacionCorreo() {
//    enumsService.listarEstadosVerificacionCorreo().then(response => {
//        this.estados = response;
//    });
//}
