<section id="landing" class="wrapper">
    <nav class="navbar navbar-dark bg-dark fixed-top navbar-expand-md" role="navigation">
        <div class="container">
            <!-- Brand and toggle get grouped for better mobile display -->
            <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"> <span class="sr-only">Toggle navigation</span>&#x2630;</button> <a class="navbar-brand" href="#">CorreoXpress 5.0</a>
            <!-- Collect the nav links, forms, and other
            content for toggling -->
            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul class="nav navbar-nav">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'empresa' }">Empresa</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'cliente' }">Clientes</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'cliente.rastreo' }">Rastreo de guías</router-link>
                    </li>
                </ul>
            </div>
            <!-- /.navbar-collapse -->
        </div>
        <!-- /.container -->
    </nav>
    <!-- Full Page Image Background Carousel Header -->
    <header>
        <carousel id="slider" :images="images"></carousel>
    </header>
    <!-- Page Content -->
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <h1>Bienvenidos a CorreoXpress 5.0</h1>
                <hr>
                <h3>POLÍTICA DE CALIDAD</h3>
                <p>Es un compromiso del personal de <strong>TEMPO EXPRESS S.A.S.</strong>, enfocar sus esfuerzos hacia el cumplimiento de los requisitos legales y de sus clientes en la prestación de los servicios de mensajería, toma de lectura de medidores de servicios públicos y Gestión de Cobro.
                Para lograrlo, la Dirección ha adoptado como estrategia fundamental la participación activa del personal en la implementación y aplicación de su SGC bajo la Norma ISO 9001  con la mejora continua de la eficacia de sus procesos y la infraestructura adecuada.</p>
                <h3>MISIÓN</h3>
                <p>Somos una empresa con amplia trayectoria a nivel nacional, ofreciendo el mejor servicio integral de mensajería y logística de forma oportuna, con alta calidad, confiabilidad y eficiencia.</p>
                <h3>VISIÓN</h3>
                <p>Para el 2023, ser los líderes en mensajería y logística en el país, fortalecer la presencia en el mercado de los demás servicios y nuevos productos, afianzando nuestra cobertura a nivel nacional.</p>
            </div>
        </div>
        <hr>
    </div>
    <!-- /.container -->
    <!-- Footer -->
    <footer class="container">
        <div class="row">
            <div class="col-xl-12">
                <p>Copyright &#xA9; Tempo Express S.A.S {{anio}}</p>
            </div>
        </div>
        <!-- /.row -->
    </footer>
</section>