import render from './modal.html';

export default render({
    name: 'ModalComponent',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'large'
        },
        backdrop: {
            default: 'static',
        },
        effect: {
            type: String,
            default: 'fade'
        }
    },
    computed: {
        dialogClass() {
            switch(this.size) {
                case 'large': return 'modal-lg';
                case 'small': return 'modal-sm';
                case 'full': return 'modal-full';
            }
        },
        modalClass() {
            return this.effect;
        },
        hasHeaderSlot () {
            return !!this.$slots['header']
        },
        hasFooterSlot () {
            return !!this.$slots['footer']
        }
    },
    mounted() {
        let $el = $(this.$el);
        
        $el.modal({
            backdrop: this.backdrop,
            show: false
        });
        $el.modal(this.value ? 'show' : 'hide');
        /*if (this.value) {
            $el.modal('show');
        }*/
        let self = this;
        $el.on('hidden.bs.modal', function (event) {
            if (self.value) {
                //self.value = false;
                self.$emit('input', false);
            }
        });
    },
    watch: {
        value(value, oldValue) {
            let $el = $(this.$el);
            if (value) {
                $el.modal('show');
            } else {
                $el.modal('hide');
            }
        }
    }
});