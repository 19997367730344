<div class="container">
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <div class="login-panel card">
                <div class="card-header">
                    <img class="rounded mx-auto d-block" src="assets/images/logo_tempo_lg.png" style="width: 90%;">
                </div>
                <div class="card-body">
                    <form @submit.prevent="login" role="form">
                        <fieldset>
                            <div class="form-group">
                                <div class="input-group">
                                    <span class="input-group-addon">
                                        <i class="glyphicon glyphicon-user"></i>
                                    </span>
                                    <input autocomplete="off" autofocus class="form-control" placeholder="Usuario" type="text" v-model="credenciales.username">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <span class="input-group-addon">
                                        <i class="glyphicon glyphicon-lock"></i>
                                    </span>
                                    <input class="form-control" placeholder="Password" type="password" v-model="credenciales.password" value="">
                                </div>
                            </div>
                            <!--div class="form-check">
                            
                                                            <label>
                            
                                                                <input name="remember" type="checkbox" value="Remember Me">Remember Me
                            
                                                            </label>
                            
                                                        </div-->
                            <!-- Change this to a button or input when using this as a form -->
                            <button class="btn btn-success btn-block" type="submit">Ingresar</button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
