class ObjectStorage {
    setValue(key, val) {
        let valStr = JSON.stringify(val);
        localStorage.setItem(key, valStr);
    }

    getValue(key) {
        if(localStorage.hasOwnProperty) {
            return JSON.parse(localStorage.getItem(key));
        }
    }

    hasValue(key) {
        return localStorage.hasValue
    }
}

export default new ObjectStorage();