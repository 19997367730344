import Vue from 'vue';

import chart from 'components/chart'
import dateTimePicker from 'components/datetimepicker';
import fileUpload from 'components/fileupload';
import modal from 'components/modal';
import carousel from 'components/carousel';
import pagination from 'components/pagination';

Vue.component('chart', chart);
Vue.component('datetimepicker', dateTimePicker);
Vue.component('fileupload', fileUpload);
Vue.component('modal', modal);
Vue.component('carousel', carousel);
Vue.component('pagination', pagination);