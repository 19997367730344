import notifyService from 'services/notify-service';
import dialogService from 'services/dialog-service';
import guiasService from 'services/data/guias-service';
import mensajerosService from 'services/data/mensajeros-service';
import movimientosService from 'services/data/movimientos-service';
import enumsService from 'services/data/enums-service';

import moment from 'moment';
import papa from 'papaparse';

import render from './descarga-novedad.html';

export default render({
    name: 'DescargaNovedad',
    data() {
        return {
            titulo: 'Descarga de novedades',
            fecha: null,
            mensajero_id: null,
            mensajero: null,
            detalleEstado: null,
            numeroGuia: null,
            mensajeros: [],
            detallesEstado: [],
            procesar: [],
            procesadas: [],
            errores: [],
            modalUpload: false,
            archivo: null,
            nombreArchivo: null
        }
    },
    methods: {
        cambiarMensajero() {
            this.$refs.detalleEstado.focus();
            this.$nextTick(() => {
                obtenerMensajero.call(this);
            })
        },
        cambiarDetalleEstado() {
            this.$refs.guia.focus();
        },
        procesarDescargaNovedad() {
            console.log('procesar');
            procesarDescargaNovedad.call(this);
        },
        comprobar() {
            //console.log('comprobar');
            comprobar.call(this, this.numeroGuia);
            this.numeroGuia = null;
        },
        cargar(archivo) {
            this.archivo = archivo;
        },
        procesarArchivo() {
            let self = this;
            papa.parse(this.archivo, {
                complete(results) {
                    _.each(results.data, (fila, i) => {
                        if(fila[0]) {
                            comprobar.call(self, fila[0]);
                        }
                    });
                    self.nombreArchivo = null;
                    self.archivo = null;
                    self.modalUpload = false;
                }
            });
        }
    },
    created() {
        listarMensajeros.call(this);
        listarDetallesEstado.call(this, 'NOVEDAD');
    }
});

function comprobar(numeroGuia) {
    let self = this;
    //let numeroGuia = this.numeroGuia;
    //this.numeroGuia = null;
    guiasService.obtener(numeroGuia).then(response => {
        let data = response.data;
        if(data.length > 1) {
            //Permitir al usuario seleccionar
            dialogService.choose('Multiples correos encontrados',
            _.map(data, (d, i) => {
                return {
                    label: `Orden ${ d.orden_numero } de ${ d.orden_fecha }`,
                    value: i
                }
            }), (i) => {
                let correo = data[i];
                encolar.call(self, correo);
            });
        } else if(data.length === 1) {
            let correo = data[0];
            encolar.call(self, correo);
        } else {
            if(!_.find(self.errores, { 'numero_guia': numeroGuia })) {
                self.errores.push({ 'numero_guia': numeroGuia, 'error': 'No encontrada' });
            }
        }
    }).catch(error => {
        //console.log(error);
        if(!_.find(self.errores, { 'numero_guia': numeroGuia })) {
            self.errores.push({ 'numero_guia': numeroGuia, 'error': 'Error código: ' + error.response.status });
        }
    });
}

function encolar(correo) {
    if(_.includes(['DISTRIBUCION'], correo.estado)) {
        if(correo.mensajero_id === this.mensajero_id) {
            let fechaEntrega = correo.fecha_entrega ? moment(correo.fecha_entrega) : null;
            let fechaRecepcion = this.fecha ? moment(this.fecha + ' 16:00:00') : moment();
            if(this.fecha && correo.fecha_estado){
                let currentDate = moment(this.fecha + ' 16:00:00')
                let lastMovementDate = moment(correo.fecha_estado)
                if(currentDate.isBefore(lastMovementDate)) {
                    this.errores.push({ 'numero_guia': correo.numero_guia, 'error': 'Fechas inconsistentes' });
                    return;
                }
            }
            if((fechaRecepcion && fechaEntrega && fechaRecepcion.isSameOrAfter(fechaEntrega))) {
                if(!_.find(this.procesar, { 'id': correo.id })) {
                    this.procesar.push(correo);
                }
            } else {
                this.errores.push({ 'numero_guia': correo.numero_guia, 'error': 'Incongruencia de fechas' });
            }
        } else {
            if(!_.find(this.errores, { 'numero_guia': correo.numero_guia })) {
                this.errores.push({ 'numero_guia': correo.numero_guia, 'error': 'Mensajero no coincide' });
            }
        }
    } else {
        if(_.includes(['VERIFICADO', 'CARGADO', null], correo.estado)) {
            if(!_.find(this.errores, { 'numero_guia': correo.numero_guia })) {
                this.errores.push({ 'numero_guia': correo.numero_guia, 'error': 'No se encuentra en distribución' });
            }
        } else {
            if(!_.find(this.procesadas, { 'id': correo.id })) {
                this.procesadas.push(correo);
            }
        }
    }
}

function procesarDescargaNovedad() {
    if(!(this.mensajero_id && this.detalleEstado)) {
        notifyService.danger('Mensajero y tipo de novedad son requeridos');
        return;
    }
    movimientosService.procesarDescargaNovedad(this.procesar, this.mensajero_id, this.detalleEstado, this.fecha).then(response => {
        this.procesar = [];
        this.procesadas = [];
        this.errores = [];
        this.mensajero_id = null;
        this.mensajero = null;
        this.detalleEstado = null;
        notifyService.success('Finalizado correctamente');
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}

function listarMensajeros() {
    mensajerosService.listarActivos().then(response => {
        this.mensajeros = response.data.lista;
    });
}

function obtenerMensajero() {
    if(this.mensajero_id) {
        mensajerosService.obtener(this.mensajero_id).then(response => {
            this.mensajero = response.data;
        });
    }
}

//Enums
function listarDetallesEstado(tipo) {
    enumsService.listarDetallesEstadoCorreo().then(response => {
        this.detallesEstado = response[tipo];
    });
}

function listarEstadosVerificacionCorreo() {
    enumsService.listarEstadosVerificacionCorreo().then(response => {
        this.estados = response;
    });
}