import './bootstrap';

import axios from 'axios';

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueLoading from 'vue-loading-overlay';

import url from 'services/url-service';
import security from 'services/security-service';

import './components';
import './directives';

import routes from './routes';

Vue.use(VueRouter);
Vue.use(VueLoading, {
    color: 'blue',
    loader: 'dots'
});
Vue.use({
    install(Vue) {
        Vue.prototype.$security = security;
    }
});

const router = new VueRouter({
    routes
});

var authentication;

router.beforeEach((to, from, next) => {
    let record = to.matched.find(record => record.meta.auth);
    (async () => {
        if(record && record.meta.auth == 'cliente') {
            authentication = (await import('cliente/services/authentication-service')).default;
            security.jwt = (await import('cliente/services/jwt-service')).default;
        } else {
            authentication = (await import('empresa/services/authentication-service')).default;
            security.jwt = (await import('empresa/services/jwt-service')).default;
        }
        if (record) {
            authentication.verify().then(() => {
                next();
            }, () => {
                next({ name: record.meta.login });
            });
        } else {
            next();
        }
    })();
});

axios.interceptors.request.use((request) => {
    if(request.url !== url.create('/token') && 'OPTIONS' !== request.method.toUpperCase()) {
        let token = security.jwt.getToken();
        if(token) {
            if(!request.headers['Authorization']) {
                request.headers['Authorization'] = 'Bearer ' + security.jwt.getRawToken();
            }
        }
    }
    return request;
}, (error) => Promise.reject(error));

axios.interceptors.response.use(
    response => response,
    error => new Promise((resolve, reject) => {
      if (error.response.status === 401) {
        authentication
          .verify()
          .then(() => resolve(axios(error.response.config)))
          .catch(() => reject(error));
      } else {
        reject(error)
      }
    }
  )
)

// axios.interceptors.response.use((response) => {
//     return new Promise((resolve, reject) => {
//         if(response.status === 401) {
//             authentication.verify().then(() => {
//                 axios(response.config).then((newResponse) => {
//                     resolve(newResponse);
//                 }).catch((error) => {
//                     reject(error.response);
//                 });
//             }, () => { reject(response) });
//         } else {
//             resolve(response);
//         }
//     });
// }, (error) => Promise.reject(error));

var app = new Vue({
    router
}).$mount('#root');