<div>
    <h3>{{ titulo }}</h3>
    <form @submit.prevent="procesarEntrega" class="row" ref="form-container">
        <div class="col-6">
            <div class="form-group" v-if="$security.check(['all', 'establecer_fecha'])">
                <label class="form-control-label" for="correo-fecha">Fecha:</label>
                <datetimepicker id="correo-fecha" placeholder="Fecha" v-model="fecha"></datetimepicker>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="correo-mensajero">Mensajero:</label>
                <select class="form-control" id="correo-mensajero" v-model="mensajeroId" @change="cambiarMensajero" v-searchable>
                    <option :value="null">Seleccione uno</option>
                    <option v-for="mensajero in mensajeros" :value="mensajero.id">{{ mensajero.oficina_ciudad }}/{{ mensajero.oficina_departamento }} {{ mensajero.nombre }}</option>
                </select>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="correo-tipo">Tipo:</label>
                <select class="form-control" id="correo-tipo" v-model="tipo" ref="tipo" @change="cambiarTipo">
                    <option :value="null">Seleccione uno</option>
                    <option v-for="tipo in tipos" :value="tipo.id">{{ tipo.nombre }}</option>
                </select>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="correo-tipo-tarifa">Tipo de tarifa:</label>
                <select class="form-control" id="correo-tipo-tarifa" v-model="tipoTarifa" ref="tipoTarifa"
                    @change="cambiarTipoTarifa">
                    <option :value="null">Seleccione uno</option>
                    <option v-for="tipoTarifa in tiposTarifaMensajero" :value="tipoTarifa.id">{{ tipoTarifa.nombre }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="subir-archivo">Archivo plano:</label>
                <fileupload id="subir-archivo" placeholder="Archivo plano" v-model="archivo" @change="cargar">
                </fileupload>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-secondary">Procesar</button>
            </div>
        </div>
        <div class="col-6">
            <div class="card bg-light card-body mb-3" v-if="mensajero &amp;&amp; mensajero.pendientes">
                <h4>Total correo pendiente: {{ mensajero.pendientes.total }}</h4>
                <h4>Total correo atrasado: {{ mensajero.pendientes.atrasados }}</h4>
            </div>
        </div>
    </form>
</div>