<div>
    <h3>{{ titulo }}
        <small>
            <a v-if="$security.check(['all', 'agregar_mensajeros'])" href="" @click.prevent="agregar" title="Agregar">[Agregar]</a>
        </small>
    </h3>
    <form class="form-condensed" @submit.prevent="filtrar">
        <fieldset>
            <legend>Criterios de búsqueda</legend>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="form-control-label col-md-4" for="filtro-nombre">Nombre:</label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" id="filtro-nombre" placeholder="Nombre" v-model="filtro.nombre">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="$security.check(['all', 'acceso_global'])">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="form-control-label col-md-4" for="filtro-oficina">Oficina:</label>
                        <div class="col-md-8">
                            <select class="form-control" id="filtro-oficina" v-model="filtro.oficina_id">
                                <option :value="undefined">Seleccione uno</option>
                                <option v-for="oficina in oficinas" :value="oficina.id">{{ oficina.ciudad }} / {{ oficina.departamento }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col-md-8 offset-4">
                        <button type="submit" class="btn btn-secondary" @click.prevent="filtrar">Buscar</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Identificación</th>
                            <th>Tipo</th>
                            <th>Oficina</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="mensajero in mensajeros">
                            <td>{{ mensajero.nombre }}</td>
                            <td>{{ mensajero.identificacion }}</td>
                            <td>{{ getTipo(mensajero.tipo) }}</td>
                            <td>{{ (mensajero.oficina_ciudad ? mensajero.oficina_ciudad : '') + (mensajero.oficina_departamento ? '/' + mensajero.oficina_departamento : '') }}</td><!-- ciudad_departamento -->
                            <td>{{ getEstado(mensajero.estado) }}</td>
                            <td>
                                <a v-if="$security.check(['all', 'editar_mensajeros'])" href="" @click.prevent="editar(mensajero)" title="Editar">
                                    <i class="mdi mdi-mode-edit mdi-lg"></i>
                                </a>
                                <a v-if="$security.check(['all', 'eliminar_mensajeros'])" href="" @click.prevent="eliminar(mensajero)" title="Eliminar">
                                    <i class="mdi mdi-delete mdi-lg"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="pageChange"></pagination>
        </div>
    </div>
    <form @submit.prevent="guardar">
        <modal v-model="modal">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">{{ titulo }}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="mensajero-nombre">Nombre:</label>
                    <input type="text" class="form-control" id="mensajero-nombre" placeholder="Nombre" v-model="mensajero.nombre">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="mensajero-identificacion">Identificación:</label>
                    <input type="text" class="form-control" id="mensajero-identificacion" placeholder="Identificación" v-model="mensajero.identificacion">
                </div>
                <div class="form-group" v-if="$security.check(['all', 'acceso_global'])">
                    <label class="form-control-label" for="mensajero-oficina">Oficina:</label>
                    <select class="form-control" id="mensajero-oficina" v-model="mensajero.oficina_id">
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="oficina in oficinas" :value="oficina.id">{{ oficina.ciudad }}/{{ oficina.departamento }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="mensajero-tipo">Tipo de mensajero:</label>
                    <select class="form-control" id="mensajero-tipo" placeholder="Tipo de mensajero" v-model="mensajero.tipo">
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="tipo in tipos" :value="tipo.id">{{ tipo.nombre }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="mensajero-tipo-tarifa">Tipos de tarifa:</label>
                    <select class="form-control" id="mensajero-tipo-tarifa" v-model="mensajero.tipos_tarifa" multiple>
                        <option v-for="tipoTarifa in tiposTarifa" :value="tipoTarifa.id">{{ tipoTarifa.nombre }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="mensajero-estado">Estado:</label>
                    <select class="form-control" id="mensajero-estado" v-model="mensajero.estado">
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="estado in estados" :value="estado.id">{{ estado.nombre }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="mensajero-password">Password:</label>
                    <input type="password" class="form-control" id="mensajero-password" placeholder="Password" v-model="mensajero.password">
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Guardar</button>
                <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
            </div>
        </modal>
    </form>
</div>