<div>
    <h3>{{ titulo }}
        <small>
            <a v-if="$security.check(['all', 'agregar_oficinas'])" href="" @click.prevent="agregar" title="Agregar">[Agregar]</a>
        </small>
    </h3>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Ciudad</th>
                            <th>Departamento</th>
                            <th>Dirección</th>
                            <th>Código postal</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="oficina in oficinas">
                            <td>{{ oficina.ciudad }}</td>
                            <td>{{ oficina.departamento }}</td>
                            <td>{{ oficina.direccion }}</td>
                            <td>{{ oficina.codigo_postal }}</td>
                            <td>
                                <a v-if="$security.check(['all', 'editar_oficinas'])" href="" @click.prevent="editar(oficina)" title="Editar">
                                    <i class="mdi mdi-mode-edit mdi-lg"></i>
                                </a>
                                <a v-if="$security.check(['all', 'eliminar_oficinas'])" href="" @click.prevent="eliminar(oficina)" title="Eliminar">
                                    <i class="mdi mdi-delete mdi-lg"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <form @submit.prevent="guardar">
        <modal v-model="modal">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">{{ titulo }}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="oficina-ciudad">Ciudad:</label>
                    <input type="text" class="form-control" id="oficina-ciudad" placeholder="Nombre" v-model="oficina.ciudad">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="oficina-departamento">Departamento:</label>
                    <input type="text" class="form-control" id="oficina-departamento" placeholder="Departamento" v-model="oficina.departamento">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="oficina-direccion">Dirección:</label>
                    <input type="text" class="form-control" id="oficina-direccion" placeholder="Dirección" v-model="oficina.direccion">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="oficina-codigo-postal">Código postal:</label>
                    <input type="text" class="form-control" id="oficina-codigo-postal" placeholder="Código postal" v-model="oficina.codigo_postal">
                </div>
                <fieldset>
                    <legend>Par&#xE1;metros para impresión de guias</legend>
                    <div class="form-group">
                        <label class="form-control-label" for="oficina-guia-margen-superior">Corrección margen superior (mm):</label>
                        <input type="text" class="form-control" id="oficina-guia-margen-superior" placeholder="Corrección margen superior" v-model="oficina.guia_margen_superior">
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="oficina-guia-margen-izquierdo">Corrección margen izquierdo (mm):</label>
                        <input type="text" class="form-control" id="oficina-guia-margen-izquierdo" placeholder="Corrección margen izquierdo" v-model="oficina.guia_margen_izquierdo">
                    </div>
                </fieldset>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Guardar</button>
                <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
            </div>
        </modal>
    </form>
</div>