import axios from 'axios';

import url from 'services/url-service';

const path = '/private/mensajeros';

class MensajerosService {
    listar() {
        return axios.get(url.create(path));
    }
    listarActivos() {
        return axios.get(url.create(path + '/activos'));
    }
    listarAtrasados() {
        return axios.get(url.create(path + '/atrasados'));
    }
    listarFiltrado(limit, offset, criteria) {
        return axios.get(url.create(path), {
            params: {
                limit,
                offset,
                criteria
                //criteria: encodeURI(JSON.stringify(criteria))
            }
        });
    }
    obtener(id) {
        return axios.get(url.create(path + '/' + id));
    }
    agregar(mensajero) {
        return axios.post(url.create(path), mensajero);
    }
    editar(id, mensajero) {
        return axios.put(url.create(path + '/' + id), mensajero);
    }
    eliminar(id) {
        return axios.delete(url.create(path + '/' + id));
    }
    /*listarTipos() {
        return axios.get(url.create(path + '/tipos'));
    }*/
    /*listarProcesos(id) {
        return axios.get(url.create(path + '/' + id + '/procesos'));
    }*/
}

export default new MensajerosService();