import landing from 'index/landing';
//Empresa
import empresaLogin from 'empresa/login';
import empresaPrincipal from 'empresa/principal';
import empresaDashboard from 'empresa/dashboard';
//import perfil from 'empresa/perfil';

import adminOficinas from 'empresa/admin/oficinas';
import adminMensajeros from 'empresa/admin/mensajeros';
import adminClientes from 'empresa/admin/clientes';
import adminClientesInfo from 'empresa/admin/clientes.info';

import correoOrdenes from 'empresa/correo/ordenes';
import correoImpresion from 'empresa/correo/impresion';
import correoOrdenesDetalle from 'empresa/correo/ordenes.detalle';
import correoOrdenesDetalleCargar from 'empresa/correo/ordenes.detalle.cargar';
import correoCorreos from 'empresa/correo/correos';

import operacionVerificacion from 'empresa/operacion/verificacion';
import operacionPreproceso from 'empresa/operacion/preproceso';
import operacionPostproceso from 'empresa/operacion/postproceso';
import operacionEntrega from 'empresa/operacion/entrega';
import operacionEntregaMasiva from 'empresa/operacion/entrega-masiva';
import operacionReasignacion from 'empresa/operacion/reasignacion';
import operacionDescargaEfectivo from 'empresa/operacion/descarga-efectivo';
import operacionDescargaDevuelto from 'empresa/operacion/descarga-devuelto';
import operacionDescargaNovedad from 'empresa/operacion/descarga-novedad';
import operacionReversionMovimiento from 'empresa/operacion/reversion-movimiento';
import operacionPlanillaEntrega from 'empresa/operacion/planilla-entrega';
import operacionPlanillaRecepcion from 'empresa/operacion/planilla-recepcion';
import operacionPlanillaResumen from 'empresa/operacion/planilla-resumen';
import operacionPlanillaVerificacion from 'empresa/operacion/planilla-verificacion';
import operacionPlanillaConsolidada from 'empresa/operacion/planilla-consolidada';
import operacionPlanillaConsolidadaClientes from 'empresa/operacion/planilla-consolidada-clientes';

import reporteConsolidado from 'empresa/reportes/consolidado';
import reporteTarifas from 'empresa/reportes/tarifas';
import reporteDatos from 'empresa/reportes/datos';
import reporteCadena from 'empresa/reportes/cadena';

import seguridadRoles from 'empresa/seguridad/roles';
import seguridadUsuarios from 'empresa/seguridad/usuarios';

//Cliente

import clienteRastreo from 'cliente/rastreo';
import clienteLogin from 'cliente/login';
import clientePrincipal from 'cliente/principal';
//import clienteCorreo from 'cliente/correo/correos';

export default [
    { name: 'index', path: '/', component: landing },
    { name: 'empresa.login', path: '/empresa/login', component: empresaLogin },
    {
        path: '/empresa',
        component: empresaPrincipal,
        children: [
            { name: 'empresa', path: '', component: empresaDashboard },
            { name: 'empresa.perfil', path: 'perfil', component: { name: 'empresa.perfil' } },
            
            { name: 'empresa.oficinas', path: 'oficinas', component: adminOficinas },
            { name: 'empresa.mensajeros', path: 'mensajeros', component: adminMensajeros },
            { name: 'empresa.clientes', path: 'clientes', component: adminClientes },
            { name: 'empresa.clientes.info', path: 'clientes/:id', component: adminClientesInfo },

            { name: 'empresa.ordenes', path: 'ordenes', component: correoOrdenes },
            { name: 'empresa.impresion', path: 'impresion', component: correoImpresion },
            { name: 'empresa.ordenes.detalle', path: 'ordenes/:id', component: correoOrdenesDetalle },
            { name: 'empresa.ordenes.detalle.cargar', path: 'ordenes/:id/cargar', component: correoOrdenesDetalleCargar },
            { name: 'empresa.correos', path: 'correos', component: correoCorreos },

            { name: 'empresa.verificacion', path: 'verificacion', component: operacionVerificacion },
            { name: 'empresa.preproceso', path: 'preproceso', component: operacionPreproceso },
            { name: 'empresa.postproceso', path: 'postproceso', component: operacionPostproceso },
            { name: 'empresa.entrega', path: 'entrega', component: operacionEntrega },
            { name: 'empresa.entrega-masiva', path: 'entrega-masiva', component: operacionEntregaMasiva },
            { name: 'empresa.reasignacion', path: 'reasignacion', component: operacionReasignacion },
            { name: 'empresa.descarga-efectivo', path: 'descarga-efectivo', component: operacionDescargaEfectivo },
            { name: 'empresa.descarga-devuelto', path: 'descarga-devuelto', component: operacionDescargaDevuelto },
            { name: 'empresa.descarga-novedad', path: 'descarga-novedad', component: operacionDescargaNovedad },
            { name: 'empresa.reversion-movimiento', path: 'reversion-movimiento', component: operacionReversionMovimiento },
            { name: 'empresa.planilla-entrega', path: 'planilla-entrega', component: operacionPlanillaEntrega },
            { name: 'empresa.planilla-recepcion', path: 'planilla-recepcion', component: operacionPlanillaRecepcion },
            { name: 'empresa.planilla-resumen', path: 'planilla-resumen', component: operacionPlanillaResumen },
            { name: 'empresa.planilla-verificacion', path: 'planilla-verificacion', component: operacionPlanillaVerificacion },
            { name: 'empresa.planilla-consolidada', path: 'planilla-consolidada', component: operacionPlanillaConsolidada },
            { name: 'empresa.planilla-consolidada-clientes', path: 'planilla-consolidada-clientes', component: operacionPlanillaConsolidadaClientes },

            { name: 'empresa.reporte-consolidado', path: 'reporte-consolidado', component: reporteConsolidado },
            { name: 'empresa.reporte-tarifas', path: 'reporte-tarifas', component: reporteTarifas },
            { name: 'empresa.reporte-datos', path: 'reporte-datos', component: reporteDatos },
            { name: 'empresa.reporte-cadena', path: 'reporte-cadena', component: reporteCadena },

            { name: 'empresa.roles', path: 'roles', component: seguridadRoles },
            { name: 'empresa.usuarios', path: 'usuarios', component: seguridadUsuarios }
        ],
        meta: {
            auth: 'empresa',
            login: 'empresa.login'
        }
    },
    { name: 'cliente.login', path: '/cliente/login', component: clienteLogin },
    {
        name: 'cliente',
        path: '/cliente',
        component: clientePrincipal,
        /*children: [
            { name: 'clientes', path: '', component: clienteCorreo },
        ],*/
        meta: {
            auth: 'cliente',
            login: 'cliente.login'
        }
    },
    { name: 'cliente.rastreo', path: '/cliente/rastreo', component: clienteRastreo },
]
