import notifyService from 'services/notify-service';

import oficinasService from 'services/data/oficinas-service';
import reportesService from 'services/data/reportes-service';

import render from './datos.html';
export default render({
    name: 'ReporteDatos',
    data() {
        return {
            titulo: 'Reporte Min TIC',
            filtro: {},
            oficinas: [],
        }
    },
    methods: {
        descargarXlsx() {
            descargarXlsx.call(this);
        }
    },
    created() {
        listarOficinas.call(this);
    }
});

function listarOficinas() {
    let self = this;
    oficinasService.listar().then(response => {
        self.oficinas = response.data.lista;
    });
}

function descargarXlsx() {
    reportesService.obtenerEnlaceReporteDatosXlsx(this.filtro).then(response => {
        window.location.href = response.data.enlace;
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}