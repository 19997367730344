import Vue from 'vue';

Vue.directive('searchable', {
    inserted(el, bindings) {

        let defaultProps = {
            liveSearch: true,
            liveSearchStyle: 'contains',
            noneSelectedText: 'Ninguno seleccionado',
            size: 10
        }

        let options = {};
        options = _.extend({}, defaultProps, bindings.value || {});
                
        $(el).selectpicker(options);
    },
    componentUpdated(el, binding, vnode, oldVnode) {
        if(vnode.children.length != oldVnode.children.length) {
            $(el).selectpicker('refresh');
        }
    }
});

Vue.directive('fillHeight', {
    inserted(el, binding, vnode) {
        let $el = $(el);
        let ops = binding.value || {};
        $(window).on('resize', event => {
            let height = $(window).height();
            $el.css('overflow-y', 'auto');
            $el.css('overflow-x', 'hidden');
            $el.css('margin-top', '0');
            $el.css('margin-bottom', '0');
            $el.height(height - (ops.headerFix || 0) - (ops.footerFix || 0));
        });
        $(window).trigger('resize');
    }
});