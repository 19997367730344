<div>
    <h3>Impresión por lotes</h3>
    <form @submit.prevent="imprimir">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label class="form-control-label" for="params-template">Template:</label>
                    <select class="form-control" id="params-template" v-model="template" @change="templateChange">
                        <option :value="null">Seleccione uno</option>
                        <option v-for="template in templates" :value="template.id">{{ template.nombre }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="params-fecha">Utilizar fecha:</label>
                    <datetimepicker id="params-fecha" placeholder="Fecha" v-model="fecha"></datetimepicker>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="correo-numero-guia">Numero de guía:</label>
                    <div class="input-group mb-3">
                        <textarea class="form-control" id="correo-numero-guia" placeholder="Número de guía" v-model="numeroGuia"></textarea>
                        <div class="input-group-append">
                            <button class="btn btn-secondary" type="button" @click.prevent="agregarNumeroGuia">
                                <i class="mdi mdi-add mdi-lg"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="correo-numero-guia">Orden:</label>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" id="correo-numero-orden" placeholder="Número de orden" v-model="ordenNumero">
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="correo-numero-guia">Rango:</label>
                    <div class="input-group mb-3">
                        <input type="number" class="form-control" id="correo-consecutivo-desde" placeholder="Desde" v-model="consecutivoDesde">
                        <input type="number" class="form-control" id="correo-consecutivo-hasta" placeholder="Hasta" v-model="consecutivoHasta">
                        <div class="input-group-append">                            
                            <button class="btn btn-secondary" type="button" @click.prevent="agregarOrden">
                                <i class="mdi mdi-add mdi-lg"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="correo-numero-guia">Corrección de margenes:</label>
                    <div class="input-group mb-3">
                        <input type="number" step="0.1" class="form-control" id="correo-consecutivo-desde" placeholder="Margen superior" v-model="superior">
                        <input type="number" step="0.1" class="form-control" id="correo-consecutivo-hasta" placeholder="Margen izquierdo" v-model="izquierdo">
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-secondary">Imprimir</button>
                </div>
            </div>
        </div>
    </form>
    <div class="row" v-if="guias.length > 0">
        <div class="col-12">
            <h3>Guias</h3>
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>Número</th>
                        <th>Nombre</th>
                        <th>Dirección</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="guia in guias">
                        <td>{{ guia.numero_guia }}</td>
                        <td>{{ guia.nombre_destinatario }}</td>
                        <td>{{ guia.direccion_destinatario }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" v-if="ordenes.length > 0">
        <div class="col-12">
            <h3>Ordenes</h3>
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>Número</th>
                        <th>Desde</th>
                        <th>Hasta</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="orden in ordenes">
                        <td>{{ orden.numero }}</td>
                        <td>{{ orden.desde }}</td>
                        <td>{{ orden.hasta }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>