import axios from 'axios';

import url from 'services/url-service';

const path = '/private/clientes';

class ClientesService {
    listar() {
        return axios.get(url.create(path));
    }
    listarFiltrado(limit, offset, criteria) {
        return axios.get(url.create(path), {
            params: {
                limit,
                offset,
                criteria
                //criteria: encodeURI(JSON.stringify(criteria))
            }
        });
    }
    obtener(id) {
        return axios.get(url.create(path + '/' + id));
    }
    agregar(cliente) {
        return axios.post(url.create(path), cliente);
    }
    editar(id, cliente) {
        return axios.put(url.create(path + '/' + id), cliente);
    }
    eliminar(id) {
        return axios.delete(url.create(path + '/' + id));
    }
    /*listarTipos() {
        return axios.get(url.create(path + '/tipos'));
    }*/
    listarContactos(clienteId) {
        return axios.get(url.create(path + '/' + clienteId + '/contactos'));
    }
    listarContactosFiltrado(clienteId, limit, offset, criteria) {
        return axios.get(url.create(path + '/' + clienteId + '/contactos'), {
            params: {
                limit,
                offset,
                criteria
                //criteria: encodeURI(JSON.stringify(criteria))
            }
        });
    }
    agregarContacto(clienteId, contacto) {
        return axios.post(url.create(path + '/' + clienteId + '/contactos'), contacto);
    }
    editarContacto(clienteId, id, contacto) {
        return axios.put(url.create(path + '/' + clienteId + '/contactos/' + id), contacto);
    }
    eliminarContacto(id) {
        return axios.delete(url.create(path + '/contactos/' + id));
    }
    listarProcesos(activos = false) {
        let params = {};
        if(activos) {
            params.activos = activos
        }
        return axios.get(url.create(path + '/procesos', { params }));
    }
    listarProcesosCliente(clienteId, activos = false) {
        let params = {};
        if(activos) {
            params.activos = activos
        }
        return axios.get(url.create(path + '/' + clienteId + '/procesos', { params }));
    }
    listarProcesosFiltrado(clienteId, limit, offset, criteria) {
        return axios.get(url.create(path + '/procesos'), {
            params: {
                limit,
                offset,
                criteria
                //criteria: encodeURI(JSON.stringify(criteria))
            }
        });
    }
    listarProcesosClienteFiltrado(clienteId, limit, offset, criteria) {
        return axios.get(url.create(path + '/' + clienteId + '/procesos'), {
            params: {
                limit,
                offset,
                criteria
                //criteria: encodeURI(JSON.stringify(criteria))
            }
        });
    }
    agregarProceso(clienteId, proceso) {
        return axios.post(url.create(path + '/' + clienteId + '/procesos'), proceso);
    }
    editarProceso(clienteId, id, proceso) {
        return axios.put(url.create(path + '/' + clienteId + '/procesos/' + id), proceso);
    }
    eliminarProceso(id) {
        return axios.delete(url.create(path + '/procesos/' + id));
    }
    obtenerValoresProceso(id, fecha_inicio, oficinaId) {
        return axios.get(url.create(path + '/procesos/' + id + '/valores/' + oficinaId + '/' + fecha_inicio));
    }
    editarValoresProceso(id, oficinaId, valores) {
        return axios.put(url.create(path + '/procesos/' + id + '/valores/' + oficinaId), valores);
    }
}

export default new ClientesService();