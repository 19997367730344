import axios from 'axios';

import url from 'services/url-service';

const path = '/private/movimientos';

class MovimientosService {
    listarFiltrado(tipo, criterios) {
        return axios.get(url.create(path + '/' + tipo), {
            params: {
                criterios
            }
        });
    }

    procesarVerificacion(guias, estado, tipo, fecha = null) {
        return axios.post(url.create(path + '/verificacion'), {
            guias,
            estado,
            tipo,
            fecha
        });
    }
    
    procesarPreproceso(guias, estado, fecha = null) {
        return axios.post(url.create(path + '/preproceso'), {
            guias,
            estado,
            fecha
        });
    }
    
    procesarPostproceso(guias, estado, fecha = null) {
        return axios.post(url.create(path + '/postproceso'), {
            guias,
            estado,
            fecha
        });
    }

    procesarEntrega(guias, mensajeroId, tipoTarifa, fecha = null) {
        return axios.post(url.create(path + '/entrega'), {
            guias,
            mensajero_id: mensajeroId,
            tipo_tarifa: tipoTarifa,
            fecha
        });
    }

    procesarDescargaEfectivo(guias, mensajeroId, detalleEstado, fecha = null) {
        return axios.post(url.create(path + '/descarga-efectivo'), {
            guias,
            mensajero_id: mensajeroId,
            detalle_estado: detalleEstado,
            fecha
        });
    }

    procesarDescargaDevuelto(guias, mensajeroId, detalleEstado, fecha = null) {
        return axios.post(url.create(path + '/descarga-devuelto'), {
            guias,
            mensajero_id: mensajeroId,
            detalle_estado: detalleEstado,
            fecha
        });
    }

    procesarDescargaNovedad(guias, mensajeroId, detalleEstado, fecha = null) {
        return axios.post(url.create(path + '/descarga-novedad'), {
            guias,
            mensajero_id: mensajeroId,
            detalle_estado: detalleEstado,
            fecha
        });
    }

    procesarReasignacion(guias, mensajeroId, tipoTarifa, fecha = null) {
        return axios.post(url.create(path + '/reasignacion'), {
            guias,
            mensajero_id: mensajeroId,
            tipo_tarifa: tipoTarifa,
            fecha
        });
    }

    procesarReversion(guias, fecha = null) {
        return axios.post(url.create(path + '/reversion'), {
            guias,
            fecha
        });
    }

    procesarEntregaLote(archivo, mensajeroId, tipo, tipoTarifa, fecha = null) {
        return axios.post(url.create(path + '/lote'), {
            archivo,
            mensajero_id: mensajeroId,
            tipo,
            tipo_tarifa: tipoTarifa,
            fecha
        });
    }
}

export default new MovimientosService();