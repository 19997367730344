//import 'Base64';
import { CookieStorage } from 'cookie-storage';

const TOKEN_KEY = '__$correoexpress_token__';
const REFRESH_TOKEN_KEY = '__$correoxpress_refresh-token__';

const $storage = new CookieStorage();

function b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str).replace(/(.)/g, function (m, p) {
        let code = p.charCodeAt(0).toString(16).toUpperCase();
        if (code.length < 2) {
            code = '0' + code;
        }
        return '%' + code;
    }));
}

//Utilities
function parseToken(token) {
    let parts = token.split('.');
    if (parts.length < 3) throw new BadTokenException('Malformed token');

    let obj = new Token(JSON.parse(b64DecodeUnicode(parts[0])), JSON.parse(b64DecodeUnicode(parts[1])));
    return obj;
}

class BadTokenException {
    constructor(message) {
        this.message = message;
    }
}
//End utilities

class Token {
    constructor(header, payload) {
        this.header = header;
        this.payload = payload;
    }

    valid() {
        //Date.now() It returns the number of milliseconds elapsed since January 1, 1970, 00:00:00 UTC.
        return this.payload.exp > (Date.now() / 1000);
    }

    verify(requiredScopes, type = 'any') {
        if (this.payload.scopes) {
            if (!_.isArray(this.payload.scopes)) {
                throw new BadTokenException('Payload scopes should be an array');
            }
            if(!requiredScopes) {
                return false;
            }
            let payloadScopes = this.payload.scopes;
            if(_.isString(requiredScopes)) {
                requiredScopes = requiredScopes.split[','];
            }
            if (!_.isArray(requiredScopes)) {
                throw new BadTokenException('Payload scopes should be as array or a comma separated string');
            }
            switch (type) {
                case 'any':
                    return _.intersection(requiredScopes, payloadScopes).length > 0;
                case 'all':
                    return _.difference(requiredScopes, payloadScopes).length === 0;
                case 'none':
                    return _.intersection(requiredScopes, payloadScopes).length === 0;
                    
            }
        }
        return false;
    }
}

class Jwt {
    getToken() {
        let token = this.getRawToken();
        if (token) {
            return parseToken(token);
        }
        return false;
    }

    getRawToken() {
        return $storage.getItem(TOKEN_KEY);
    }

    getRefreshToken() {
        let token = this.getRawRefreshToken();
        if (token) {
            return parseToken(token);
        }
        return false;
    }

    getRawRefreshToken() {
        return $storage.getItem(REFRESH_TOKEN_KEY);
    }

    saveToken(token) {
        $storage.setItem(TOKEN_KEY, token);
    }

    saveRefreshToken(token) {
        $storage.setItem(REFRESH_TOKEN_KEY, token);
    }

    deleteToken() {
        $storage.removeItem(TOKEN_KEY);
    }

    deleteRefreshToken() {
        $storage.removeItem(REFRESH_TOKEN_KEY);
    }
}

export default new Jwt();