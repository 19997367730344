import axios from 'axios';

import url from 'services/url-service';

const path = '/private/oficinas';

class OficinasService {
    listar() {
        return axios.get(url.create(path));
    }
    listarFiltrado(limit, offset, criteria) {
        return axios.get(url.create(path), {
            params: {
                limit,
                offset,
                criteria
                //criteria: encodeURI(JSON.stringify(criteria))
            }
        });
    }
    obtener(id) {
        return axios.get(url.create(path + '/' + id));
    }
    agregar(oficina) {
        return axios.post(url.create(path), oficina);
    }
    editar(id, oficina) {
        return axios.put(url.create(path + '/' + id), oficina);
    }
    eliminar(id) {
        return axios.delete(url.create(path + '/' + id));
    }
    /*listarTipos() {
        return axios.get(url.create(path + '/tipos'));
    }*/
}

export default new OficinasService();