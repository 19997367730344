import notifyService from "services/notify-service";
import dialogService from "services/dialog-service";
import objectStorageService from "services/object-storage-service";

import guiasService from "services/data/guias-service";
import ordenesService from "services/data/ordenes-service";
import enumsService from "services/data/enums-service";

import render from "./impresion.html";

export default render({
  name: "Correos",
  data() {
    return {
      numeroGuia: null,
      ordenNumero: null,
      consecutivoDesde: null,
      consecutivoHasta: null,
      superior: null,
      izquierdo: null,
      templates: [],
      template: null,
      fecha: null,
      guias: [],
      ordenes: [],
    };
  },
  methods: {
    templateChange() {
      let marginCorrections =
        objectStorageService.getValue(this.template) ?? {};
      this.superior = marginCorrections.superior ?? 0;
      this.izquierdo = marginCorrections.izquierdo ?? 0;
    },
    imprimir() {
      let self = this;
      let params = {
        template: this.template,
        fecha: this.fecha,
        guias: _.map(this.guias, (guia) => guia.id),
        ordenes: this.ordenes,
        margen_superior: this.superior,
        margen_izquierdo: this.izquierdo,
      };
      guiasService
        .obtenerEnlaceLotesPdf(params)
        .then((response) => {
          self.guias = [];
          self.ordenes = [];
          objectStorageService.setValue(this.template, {
            superior: this.superior,
            izquierdo: this.izquierdo,
          });
          window.location.href = response.data.enlace;
        })
        .catch((error) => {
          if (error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(
              notifyService.buildMessage(error.response.data.reasons)
            );
          } else if (error.response.data.message) {
            notifyService.danger(error.response.data.message);
          }
        });
    },
    async agregarNumeroGuia() {
      let self = this;
      for (const numeroGuia of this.numeroGuia.split("\n")) {
        console.log(numeroGuia);
        if(!numeroGuia) continue;
        try {
          let response = await guiasService.obtener(numeroGuia);
          let data = response.data;
          if (data.length > 1) {
            //Permitir al usuario seleccionar
            dialogService.choose(
              "Multiples correos encontrados",
              _.map(data, (d, i) => {
                return {
                  label: `Orden ${d.orden_numero} de ${d.orden_fecha}`,
                  value: i,
                };
              }),
              (i) => {
                let guia = data[i];
                self._encolarGuia(guia);
              }
            );
          } else if (data.length === 1) {
            let guia = data[0];
            self._encolarGuia(guia);
          }
        } catch (error) {
          if (error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(
              notifyService.buildMessage(error.response.data.reasons)
            );
          } else if (error.response.data.message) {
            notifyService.danger(error.response.data.message);
          }
        }
      }
      self.numeroGuia = null;
    },
    agregarOrden() {
      let self = this;
      ordenesService
        .obtenerPorNumero(this.ordenNumero)
        .then((response) => {
          let orden = response.data;
          self._encolarOrden({
            id: orden.id,
            numero: orden.numero,
            desde: self.consecutivoDesde,
            hasta: self.consecutivoHasta,
          });
          self.ordenNumero = null;
          self.consecutivoDesde = null;
          self.consecutivoHasta = null;
        })
        .catch((error) => {
          if (error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(
              notifyService.buildMessage(error.response.data.reasons)
            );
          } else if (error.response.data.message) {
            notifyService.danger(error.response.data.message);
          }
        });
    },
    _encolarGuia(guia) {
      if (!_.find(this.guias, { id: guia.id })) {
        this.guias.push(guia);
      }
    },
    _encolarOrden(orden) {
      if (!_.find(this.ordenes, { id: orden.id })) {
        this.ordenes.push(orden);
      }
    },
  },
  created() {
    let self = this;

    enumsService.listarTemplatesGuias().then((response) => {
      self.templates = response;
    });
  },
});
