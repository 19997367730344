import urlService from "services/url-service";
import notifyService from "services/notify-service";
import enumsService from "services/data/enums-service";
import guiasService from "services/data/guias-service";
import correosService from "services/data/correos-service";

import render from "./rastreo.html";

export default render({
  name: "rastreo",
  data() {
    return {
      correos: [],
      estados: [],
      estadosVerificacion: [],
      detallesEstado: [],
      guia: "",
      movimientos: [],
      modalGuia: false,
      tituloGuia: "",
      srcGuia: "",
      datosGuia: {},
    };
  },
  methods: {
    async rastrear() {
      try {
        let response = await guiasService.obtenerRastreo(this.guia);
        this.correos = response.data;
      } catch (error) {
        if (error.response.data.message) {
          notifyService.danger(error.response.data.message);
        }
      }
    },
    estado(correo) {
      if (correo) {
        let estado = _.find(
          this.estados,
          (estado) => estado.id === correo.estado
        );
        //console.log(estado);
        switch (correo.estado) {
          case "VERIFICADO":
            let estadoVerificacion = _.find(
              this.estadosVerificacion,
              (estado) => estado.id === correo.estado_verificacion
            );
            return estado.nombre + " (" + estadoVerificacion.nombre + ")";
          case "CARGADO":
          case "CENTRO_ACOPIO":
          case "EN_TRANSITO":
          case "REASIGNADO":
          case "DISTRIBUCION":
            return estado.nombre;
          default:
            let detalleEstado = _.find(
              this.detallesEstado[correo.estado],
              (estado) => estado.id === correo.detalle_estado
            );
            return estado.nombre + " (" + detalleEstado.nombre + ")";
        }
      }
    },
    async verGuia(correo) {
      this.tituloGuia = "Guía No. " + correo.numero_guia;
      this.srcGuia = null;
      if (correo.guia || correo.documento) {
        this.srcGuia = urlService.create(
          "/public/guias/" +
          correo.n_oficina_ciudad +
          "/" +
          correo.orden_numero +
          "/" +
          correo.numero_guia
        );
      }
      this.datosGuia = {
        estado: this.estado(correo),
        cliente: correo.cliente_nombre + " - " + correo.proceso_referencia,
        nombre_destinatario: correo.nombre_destinatario,
        direccion_destinatario:
          (correo.barrio_destinatario
            ? correo.barrio_destinatario + ", "
            : "") + correo.direccion_destinatario,
      };
      this.movimientos = (await correosService.listarMovimientosRastreo(
        correo.id
      )).data;
      this.modalGuia = true;
    },
  },
  async created() {
    this.estados = await enumsService.listarEstadosCorreo();
    //console.log(this.estados);
    this.estadosVerificacion = await enumsService.listarEstadosVerificacionCorreo();
    this.detallesEstado = await enumsService.listarDetallesEstadoCorreo();
  },
});
