import notifyService from 'services/notify-service';

import oficinasService from 'services/data/oficinas-service';
import reportesService from 'services/data/reportes-service';

import render from './tarifas.html';
export default render({
    name: 'ReporteTarifas',
    data() {
        return {
            titulo: 'Reporte tarifas',
        }
    },
    methods: {
        descargarXlsx() {
            descargarXlsx.call(this);
        }
    },
    created() {

    }
});

function descargarXlsx() {
    reportesService.obtenerEnlaceReporteTarifasXlsx().then(response => {
        window.location.href = response.data.enlace;
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}