
import 'jquery-confirm';
class Dialog {
    alert(message, callback) {
        // bootbox.dialog({
        //     message,
        //     buttons: {
        //         ok: {
        //             label: 'Aceptar',
        //             className: 'btn-success',
        //             callback() {
        //                 callback.call(this, true);
        //             }
        //         }
        //     }
        // });
        $.alert({
            title: 'Alerta',
            content: message,
            buttons: {
                ok: {
                    text: 'Aceptar',
                    action() {
                        callback.call(this, true);
                    }
                }
            }
        });
    }   
    confirm(message, callback) {
        $.confirm({
            title: 'Confirmar',
            content: message,
            buttons: {
                cancel: {
                    text: 'Cancelar',
                    btnClass: 'btn-warning',
                    keys: ['esc'],
                    action() {
                        callback.call(this, false);
                    }
                },
                confirm: {
                    text: 'Aceptar',
                    btnClass: 'btn-success',
                    keys: ['enter'],
                    action() {
                        callback.call(this, true);
                    }
                }
            }
        });
        //let box = bootbox.confirm(message, callback);
        // bootbox.dialog({
        //     message,
        //     buttons: {
        //         cancel: {
        //             label: 'Cancelar',
        //             className: 'btn-warning',
        //             callback() {
        //                 callback.call(this, false);
        //             }
        //         },
        //         ok: {
        //             label: 'Aceptar',
        //             className: 'btn-success',
        //             callback() {
        //                 callback.call(this, true);
        //             }
        //         }
        //     }
        // });
    }
    choose(title, options, callback) {
        let ul = '<ul>';
        _.each(options, function(option, i) {
            ul += `<li><a class="dialog-option" href="" data-id="${ option.value }">${ option.label }</a></li>`;
        });
        ul += '</ul>';


        $.confirm({
            title,
            content: ul,
            buttons: {
                cancel: {
                    text: 'Cancelar',
                    btnClass: 'btn-warning',
                    keys: ['esc'],
                    action() {
                        callback.call(this, false);
                    }
                }
            },
            onOpen() {
                let self = this;
                this.$content.find('a.dialog-option').on('click', function(event){
                    event.preventDefault();
                    let index = $(event.target).data('id');
                    callback.call(self, index);
                    self.close();
                });
            }
        })

        //let box = bootbox.dialog({
        //    title,
        //    message: ul,
        //    buttons: {
        //        cancel: {
        //            label: 'Cancelar',
        //            className: 'btn-warning',
        //        }
        //    }
        //});
        ////box.on('shown.bs.modal', (event) => {
        ////    $('button', event.target).blur();
        ////});
        //box.init(() => {
        //    $('a.dialog-option', box).on('click', (event) => {
        //        event.preventDefault();
        //        let index = $(event.target).data('id');
        //        callback.call(box, index);
        //        box.modal('hide');
        //    });
        //});
    }
}

export default new Dialog();