import dialogService from 'services/dialog-service';
import notifyService from 'services/notify-service';
import oficinasService from 'services/data/oficinas-service';
import clientesService from 'services/data/clientes-service';
import ordenesService from 'services/data/ordenes-service';
import enumsService from 'services/data/enums-service';

import moment from 'moment';


import render from './ordenes.html';

export default render({
    name: 'Ordenes',
    data() {
        return {
            titulo: 'Ordenes de servicio',
            modal: false,
            modalFactura: false,
            modalPago: false,
            id: null,
            filtro: {},
            orden: {},
            ordenes: [],
            oficinas: [],
            procesos: [],
            anios: [],
            tiposProductoClaro: [],
            pagination: {
                limit: 10,
                offset: 0,
                pages: 0,
                currentPage: 1,
            }
        }
    },
    computed: {
        procesosOficina() {

        }
    },
    methods: {
        agregar() {
            this.id = null;
            this.orden = {
                nacional: 0,
            };
            this.modal = true;
        },
        editar(orden) {
            this.id = orden.id;
            this.orden = {
                oficina_id: orden.oficina_id,
                proceso_id: orden.proceso_id,
                numero: orden.numero,
                producto: orden.producto,
                fecha: orden.fecha,
                fecha_llegada_producto: orden.fecha_llegada_producto,
                fecha_limite_entrega: orden.fecha_limite_entrega,
                fecha_limite_pago: orden.fecha_limite_pago,
                cantidad_esperada: orden.cantidad_esperada,
                nacional: orden.nacional,
                referencia_1: orden.referencia_1 || undefined,
                referencia_2: orden.referencia_2 || undefined,
            };
            this.modal = true;
        },
        pagar(orden) {
            this.id = orden.id;
            this.orden = {};
            this.modalPago = true;
        },
        facturar(orden) {
            this.id = orden.id;
            this.orden = {};
            this.modalFactura = true;
        },
        cerrar(orden) {
            this.id = orden.id;
            this.orden = {};
            cerrar.call(this, orden);
        },
        eliminar(orden) {
            eliminar.call(this, orden);
        },
        guardar() {
            guardar.call(this);
        },
        guardarFacturacion() {
            facturar.call(this)
        },
        guardarPago() {
            pagar.call(this)
        },
        filtrar() {
            this.pagination.offset = 0;
            this.pagination.currentPage = 1;
            listar.call(this);
        },
        descargarXlsx() {
            descargarXlsx.call(this);
        },
        pageChange(page) {
            this.pagination.offset = (page - 1) * this.pagination.limit;
            listar.call(this);
        },
        //Utils
        estado(orden) {
            if(orden.cantidad_cargada) {
                return (((orden.estado.efectivo + orden.estado.devuelto + orden.estado.novedad + orden.estado.incorrecto) / orden.cantidad_cargada) * 100.0).toFixed(2) + '%';
            } else {
                return 'Creada';
            }
        }
    },
    created() {
        listar.call(this);
        listarAnios.call(this);
        listarOficinas.call(this);
        listarProcesos.call(this);
        listarTiposProductoClaro.call(this);
    }
});

function descargarXlsx() {
    let loader = this.$loading.show({
        container: this.$refs['form-container']
    });
    ordenesService.obtenerEnlaceXlsx(this.filtro).then(response => {
        loader.hide();
        window.location.href = response.data.enlace;
    }).catch(error => {
        loader.hide();
    });
}

function listar() {
    let self = this;
    let loader = this.$loading.show({
        container: this.$refs['form-container']
    });
    ordenesService.listarFiltrado(this.pagination.limit, this.pagination.offset, this.filtro).then(response => {
        loader.hide();
        self.ordenes = response.data.lista;
        self.pagination.pages = Math.ceil(response.data.total / self.pagination.limit);
    }).catch(error => {
        loader.hide();
    });
}

function listarAnios() {
    let anio = moment().year();
    if(moment().month() === 11){
        anio += 1;
    }
    this.anios = _.range(anio, anio - 10);
    this.filtro.anio = anio;
}

function listarOficinas() {
    let self = this;
    oficinasService.listar().then(response => {
        self.oficinas = response.data.lista;
    });
}

function listarProcesos() {
    let self = this;
    clientesService.listarProcesos(true).then(response => {
        self.procesos = response.data.lista;
    });
}

function eliminar(orden) {
    let self = this;
    dialogService.confirm(`Esta seguro de que desea eliminar esta orden ${orden.numero}?`, result => {
        if(result) {
            ordenesService.eliminar(orden.id).then(response => {
                listar.call(self);
                notifyService.success('Orden eliminada correctamente');
            }).catch(error => {
                if(error.response.status === 409 && error.response.data.reasons) {
                    notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
                } else if(error.response.data.message) {
                    notifyService.danger(error.response.data.message);
                }
            });
        }
    });
}

function cerrar(orden) {
    let self = this;
    dialogService.confirm(`Esta seguro de que desea finalizar esta orden ${orden.numero}?`, result => {
        if(result) {
            let orden = {
                id: self.id
            }
            ordenesService.cerrar(orden).then(response => {
                listar.call(self);
                notifyService.success('Orden finalizar correctamente');
            }).catch(error => {
                if(error.response.status === 409 && error.response.data.reasons) {
                    notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
                } else if(error.response.data.message) {
                    notifyService.danger(error.response.data.message);
                }
            });
        }
    });
}

function facturar() {
    let self = this;
    let orden = {
        id: this.id,
        fecha_facturacion: this.orden.fecha_facturacion,
        fecha_plazo: this.orden.fecha_plazo
    }
    ordenesService.facturar(orden).then(response => {
        listar.call(self);
        self.modalFactura = false;
        notifyService.success('Orden facturada correctamente');
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}

function pagar() {
    let self = this;
    let orden = {
        id: this.id,
        fecha_pago: this.orden.fecha_pago
    }
    ordenesService.pagar(orden).then(response => {
        listar.call(self);
        self.modalPago = false;
        notifyService.success('Orden pagada y cerrada correctamente');
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}

function guardar() {
    let self = this;
    let promise = this.id ? ordenesService.editar(this.id, this.orden) :  ordenesService.agregar(this.orden);
    promise.then(response => {
        listar.call(self);
        self.modal = false;
        notifyService.success('Orden guardada correctamente');
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}

function listarTiposProductoClaro() {
    let self = this;
    enumsService.listarTiposProductoClaro().then(response => {
        self.tiposProductoClaro = response;
    });
}