import axios from 'axios';

import url from 'services/url-service';

const path = '/private/reportes';

class ReportesService {
    obtenerEnlaceReporteConsolidadoXlsx(criteria) {
        return axios.get(url.create(path + '/consolidado/xlsx'), {
            params: {
                criteria
            }
        });
    }

    obtenerEnlaceReporteDatosXlsx(criteria) {
        return axios.get(url.create(path + '/datos/xlsx'), {
            params: {
                criteria
            }
        });
    }

    obtenerEnlaceReporteTarifasXlsx() {
        return axios.get(url.create(path + '/tarifas/xlsx'));
    }
}

export default new ReportesService();