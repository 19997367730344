import notifyService from "services/notify-service";
import urlService from "services/url-service";

import correosService from "services/data/correos-service";
import clientesService from "services/data/clientes-service";
import oficinasService from "services/data/oficinas-service";
import mensajerosService from "services/data/mensajeros-service";
import enumsService from "services/data/enums-service";
import render from "./correos.html";

import moment from "moment";

export default render({
  name: "Correos",
  data() {
    return {
      titulo: "Correos",
      modal: false,
      tituloGuia: "Guia",
      srcGuia: null,
      datosGuia: null,
      modalGuia: false,
      id: null,
      filtro: {
        proceso_id: [],
      },
      correo: {},
      correos: [],
      anios: [],
      oficinas: [],
      procesos: [],
      oficinas: [],
      mensajeros: [],
      tipos: [],
      estados: [],
      estadosVerificacion: [],
      detallesEstado: [],
      movimientos: null,
      pagination: {
        limit: 10,
        offset: 0,
        pages: 0,
        total: 0,
        currentPage: 1,
      },
      estadisticas: null,
      chart1: {},
    };
  },
  methods: {
    verGuia(correo) {
      this.tituloGuia = "Guía No. " + correo.numero_guia;
      this.srcGuia = null;
      if (correo.guia || correo.documento) {
        this.srcGuia = urlService.create(
          "/public/guias/" +
          correo.n_oficina_ciudad +
          "/" +
          correo.orden_numero +
          "/" +
          correo.numero_guia
        );
      }
      this.srcDocumento = null;
      if (correo.documento) {
        this.srcDocumento = urlService.create(
          "/public/documentos/" +
          correo.n_oficina_ciudad +
          "/" +
          correo.orden_numero +
          "/" +
          correo.numero_guia
        );
      }
      this.datosGuia = {
        cliente: correo.cliente_nombre + " - " + correo.proceso_referencia,
        nombre_destinatario: correo.nombre_destinatario,
        direccion_destinatario:
          (correo.barrio_destinatario
            ? correo.barrio_destinatario + ", "
            : "") + correo.direccion_destinatario,
      };
      listarMovimientos.call(this, correo);
      this.modalGuia = true;
    },
    estado(correo) {
      if (correo) {
        let estado = _.find(
          this.estados,
          (estado) => estado.id === correo.estado
        );
        switch (correo.estado) {
          case "VERIFICADO":
            let estadoVerificacion = _.find(
              this.estadosVerificacion,
              (estado) => estado.id === correo.estado_verificacion
            );
            return estado.nombre + " (" + estadoVerificacion.nombre + ")";
          case "CARGADO":
          case "REASIGNADO":
          case "DISTRIBUCION":
            return estado.nombre;
          default:
            let detalleEstado = _.find(
              this.detallesEstado[correo.estado],
              (estado) => estado.id === correo.detalle_estado
            );
            return estado.nombre + " (" + detalleEstado.nombre + ")";
        }
      }
    },
    //eliminar(id) {
    //
    //},
    filtrar() {
      this.pagination.offset = 0;
      this.pagination.currentPage = 1;
      listar.call(this);
    },
    descargarXlsx() {
      descargarXlsx.call(this);
    },
    verEstadisticas() {
      verEstadisticas.call(this);
    },
    pageChange(page) {
      this.pagination.offset = (page - 1) * this.pagination.limit;
      listar.call(this);
    },
    //Utils
    getTipo(tipoId) {
      return _.get(_.find(this.tipos, (tipo) => tipo.id === tipoId), "nombre");
    },
  },
  created() {
    listarAnios.call(this);
    listarProcesos.call(this);
    listarOficinas.call(this);
    // listarMensajeros.call(this);
    listarEstadosCorreo.call(this);
    listarEstadosVerificacionCorreo.call(this);
    listarDetallesEstadoCorreo.call(this);
  },
});

function descargarXlsx() {
  let self = this;
  correosService.obtenerEnlaceXlsx(this.filtro).then((response) => {
    window.location.href = response.data.enlace;
  });
}

function verEstadisticas() {
  let self = this;
  correosService.obtenerEstadisticas(this.filtro).then((response) => {
    self.correos = [];
    let totales = {
      inconsistentes: 0,
      por_entregar: 0,
      distribucion: 0,
      efectivo: 0,
      devuelto: 0,
      novedad: 0,
      sin_procesar: 0,
    };
    let total = 0;
    let guias = 0;
    _.each(response.data.datos, (item) => {
      guias += parseInt(item.guias);
      totales.inconsistentes += parseInt(item.incorrectas);
      totales.por_entregar += parseInt(item.por_entregar);
      totales.distribucion += parseInt(item.distribucion);
      totales.efectivo += parseInt(item.efectivo);
      totales.devuelto += parseInt(item.devuelto);
      totales.novedad += parseInt(item.novedad);
      totales.sin_procesar += parseInt(item.sin_procesar);
    });
    total +=
      totales.inconsistentes +
      totales.por_entregar +
      totales.distribucion +
      totales.efectivo +
      totales.devuelto +
      totales.novedad +
      totales.sin_procesar;
    self.estadisticas = {
      totales,
      total,
      guias,
    };
    self.chart1 = {
      datasets: [
        {
          data: [
            totales.por_entregar + totales.sin_procesar,
            totales.distribucion,
            totales.efectivo,
            totales.devuelto + totales.novedad,
            totales.inconsistentes,
          ],
          backgroundColor: [
            "#0074D9",
            "#FF4136",
            "#2ECC40",
            "#FF851B",
            "#7FDBFF",
            "#B10DC9",
            "#FFDC00",
            "#001f3f",
            "#39CCCC",
            "#01FF70",
            "#85144b",
            "#F012BE",
            "#3D9970",
            "#111111",
            "#AAAAAA",
          ],
        },
      ],
      labels: [
        "Por entregar",
        "En distribución",
        "Efectivos",
        "Devueltos",
        "Inconsistentes",
      ],
    };
    console.log(totales);
  });
}

function listar() {
  let self = this;
  let loader = this.$loading.show({
    container: this.$refs["form-container"],
  });
  correosService
    .listarFiltrado(this.pagination.limit, this.pagination.offset, this.filtro)
    .then((response) => {
      loader.hide();
      self.estadisticas = null;
      self.correos = response.data.lista;
      self.pagination.pages = Math.ceil(
        response.data.total / self.pagination.limit
      );
      self.pagination.total = response.data.total;
    });
}

function listarAnios() {
  let anio = moment().year();
  this.anios = _.range(anio, anio - 10);
  this.filtro.anio = anio;
}

function listarProcesos() {
  let self = this;
  clientesService.listarProcesos(true).then((response) => {
    self.procesos = response.data.lista;
  });
}

function listarOficinas() {
  oficinasService.listar().then((response) => {
    this.oficinas = response.data.lista;
  });
}

// function listarMensajeros() {
//     mensajerosService.listar().then(response => {
//         this.mensajeros = response.data.lista;
//     });
// }

function listarMovimientos(correo) {
  let self = this;
  correosService.listarMovimientos(correo.id).then((response) => {
    self.movimientos = response.data;
  });
}

function listarEstadosCorreo() {
  let self = this;
  enumsService.listarEstadosCorreo().then((response) => {
    self.estados = response;
  });
}

function listarEstadosVerificacionCorreo() {
  let self = this;
  enumsService.listarEstadosVerificacionCorreo().then((response) => {
    self.estadosVerificacion = response;
  });
}

function listarDetallesEstadoCorreo() {
  let self = this;
  enumsService.listarDetallesEstadoCorreo().then((response) => {
    self.detallesEstado = response;
  });
}
