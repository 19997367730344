import axios from 'axios';

import url from 'services/url-service';

const path = '/private/comun/estadisticas';

class EstadisticasService {
    obtenerEstadisticasOrdenes() {
        return axios.get(url.create(path + '/ordenes'));
    }
}

export default new EstadisticasService();