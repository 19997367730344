<div>
    <h3 class="d-print-none">{{ titulo }}</h3>
    <form @submit.prevent="generarPlanilla" class="d-print-none vld-parent" ref="form-container">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label class="form-control-label" for="correo-fecha">Fecha:</label>
                    <div class="form-inline">
                        <datetimepicker id="correo-fecha-desde" placeholder="Fecha desde" v-model="filtro.fecha_desde"></datetimepicker>
                        <datetimepicker id="correo-fecha-hasta" placeholder="Fecha hasta" v-model="filtro.fecha_hasta"></datetimepicker>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="correo-mensajero">Mensajero:</label>
                    <select class="form-control" id="correo-mensajero" v-model="filtro.mensajero_id" @change="cambiarMensajero" v-if="$security.check(['all', 'acceso_global'])" v-searchable>
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="mensajero in mensajeros" :value="mensajero.id">{{ mensajero.oficina_ciudad }}/{{ mensajero.oficina_departamento }} {{ mensajero.nombre }}</option>
                    </select>
                    <select class="form-control" id="correo-mensajero" v-model="filtro.mensajero_id" @change="cambiarMensajero" v-else>
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="mensajero in mensajeros" :value="mensajero.id">{{ mensajero.nombre }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-secondary">Generar planilla</button>
                </div>
            </div>
        </div>
    </form>
    <div class="row d-print-block" v-if="planilla">
        <div class="col-12">
            <h3>{{ titulo }}</h3>
            <div class="row">
                <div class="col-4">Contratista:</div>
                <div class="col-8">{{ mensajero.nombre }}</div>
                <div class="col-4">Identificación:</div>
                <div class="col-8">{{ mensajero.identificacion }}</div>
                <div class="col-4">Período:</div>
                <div class="col-8">{{ filtro.fecha_desde }} a {{ filtro.fecha_hasta }}</div>
            </div>
        </div>
        <div class="col-12">
            <table class="table table-bordered table-sm">
                <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>Proc.</th>
                        <th>Ent.</th>
                        <th>Dev.</th>
                        <th>Valor</th>
                        <th>Bono</th>
                        <th>Desc.</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="fila in planilla">
                        <td>{{ fila.cliente_nombre }}</td>
                        <td class="text-right">{{ fila.procesadas }}</td>
                        <td class="text-right">{{ fila.entregadas }}</td>
                        <td class="text-right">{{ fila.devueltas }}</td>
                        <td class="text-right">{{ fila.movimientos }}</td>
                        <td class="text-right">{{ fila.bonificaciones }}</td>
                        <td class="text-right">{{ fila.descuentos }}</td>
                        <td class="text-right">{{ fila.total }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>Totales</td>
                        <td class="text-right">{{ totales.procesadas }}</td>
                        <td class="text-right">{{ totales.entregadas }}</td>
                        <td class="text-right">{{ totales.devueltas }}</td>
                        <td class="text-right">{{ totales.movimientos }}</td>
                        <td class="text-right">{{ totales.bonificaciones }}</td>
                        <td class="text-right">{{ totales.descuentos }}</td>
                        <td class="text-right"><strong>{{ totales.movimientos + totales.bonificaciones - totales.descuentos }}</strong></td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="col-12" style="height:20mm;"></div>
        <div class="col-12">
            <div class="row" v-if="planilla">
                <div class="col-6">
                    Firma contratista:________________________
                </div>
                <div class="col-6">
                    Firma operador:________________________
                </div>
            </div>
        </div>
    </div>
</div>