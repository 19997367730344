<div>
    <h3>{{ titulo }}</h3>
    <form @submit.prevent="procesarReversion" class="row">
        <div class="col-6">
            <div class="form-group">
                <label class="form-control-label" for="correo-numero-guia">Número de guía:</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" id="correo-numero-guia" placeholder="Número de guía" v-model="numeroGuia" ref="guia"
                        @keypress.enter.prevent="comprobar">
                    <div class="input-group-append">

                        <button class="btn btn-secondary" type="button" @click.prevent="modalUpload = true">
                            <i class="mdi mdi-file-upload mdi-lg"></i>
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card bg-light card-body mb-3">
                <h4>Errores</h4>
                <ol>
                    <li v-for="correo in errores">{{ correo.numero_guia }}
                        <small>{{ correo.error }}</small>
                    </li>
                </ol>
                <h4>Por procesar</h4>
                <ol>
                    <li v-for="correo in procesar">{{ correo.numero_guia }}</li>
                </ol>
                <h4>Ya procesadas</h4>
                <ol>
                    <li v-for="correo in procesadas">{{ correo.numero_guia }}</li>
                </ol>
                <button class="btn btn-secondary">Procesar</button>
            </div>
        </div>
    </form>
    <form @submit.prevent="procesarArchivo">
        <modal v-model="modalUpload">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">{{ titulo }}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="subir-archivo" class="form-control-label">Archivo plano:</label>
                    <fileupload id="subir-archivo" placeholder="Archivo plano" @change="cargar" v-model="nombreArchivo"></fileupload>
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button class="btn btn-secondary">Procesar</button>
            </div>
        </modal>
    </form>
</div>