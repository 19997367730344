class NoJwtServiceException {
    constructor(message) {
        this.message = message;
    }
}
class SecurityService {
    constructor() {
        this.jwt = null;
    }
    get datos() {
        if(this.jwt === null) {
            throw new NoJwtServiceException('A Jwt service is needed');
        }
        return this.jwt.getToken().payload.udata;
    }
    check(roles = [], type = 'any') {
        if(this.jwt === null) {
            throw new NoJwtServiceException('A Jwt service is needed');
        }
        let token = this.jwt.getToken();
        if(token) {
            return token.verify(roles, type);
        }
        return false;
    }
}

export default new SecurityService();