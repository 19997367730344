import notifyService from "services/notify-service";
import urlService from "services/url-service";

import correosService from "services/data/correos-service";
import clientesService from "services/data/clientes-service";
import oficinasService from "services/data/oficinas-service";
import mensajerosService from "services/data/mensajeros-service";
import enumsService from "services/data/enums-service";
import render from "./correos.html";

import moment from "moment";

export default render({
  name: "Correos",
  data() {
    return {
      titulo: "Correos",
      modal: false,
      tituloGuia: "Guia",
      srcGuia: null,
      srcDocumento: null,
      datosGuia: null,
      modalGuia: false,
      id: null,
      filtro: {
        proceso_id: [],
      },
      correo: {},
      correos: [],
      anios: [],
      oficinas: [],
      procesos: [],
      oficinas: [],
      mensajeros: [],
      tipos: [],
      estados: [],
      estadosVerificacion: [],
      detallesEstado: [],
      movimientos: null,
      pagination: {
        limit: 10,
        offset: 0,
        pages: 0,
        total: 0,
        currentPage: 1,
      },
      estadisticas: null,
      chart1: {},
    };
  },
  methods: {
    editar(correo) {
      this.id = correo.id;
      this.correo = {
        nombre_destinatario: correo.nombre_destinatario,
        identificacion_destinatario: correo.identificacion_destinatario,
        direccion_destinatario: correo.direccion_destinatario,
        barrio_destinatario: correo.barrio_destinatario,
        ciudad_destinatario: correo.ciudad_destinatario,
        departamento_destinatario: correo.departamento_destinatario,
        email_destinatario: correo.email_destinatario,
        telefono_destinatario: correo.telefono_destinatario,
        celular_destinatario: correo.celular_destinatario,
        producto: correo.producto,
        referencia_1: correo.referencia_1,
        referencia_2: correo.referencia_2,
        referencia_3: correo.referencia_3,
        referencia_4: correo.referencia_4,
        referencia_5: correo.referencia_5,
        peso: correo.peso,
        valor_declarado: correo.valor_declarado,
        valor_recaudar: correo.valor_recaudar,
        email_remitente: correo.email_remitente,
        telefono_remitente: correo.telefono_remitente,
        observaciones: correo.observaciones,
        gestion_1: correo.gestion_1,
        gestion_2: correo.gestion_2,
        gestion_3: correo.gestion_3,
      };
      this.modal = true;
    },
    verGuia(correo) {
      this.tituloGuia = "Guía No. " + correo.numero_guia;
      this.srcGuia = null;
      if (correo.guia || correo.documento) {
        this.srcGuia = urlService.create(
          "/public/guias/" +
            correo.n_oficina_ciudad +
            "/" +
            correo.orden_numero +
            "/" +
            correo.numero_guia
        );
      }
      this.srcDocumento = null;
      if (correo.documento) {
        this.srcDocumento = urlService.create(
          "/public/documentos/" +
            correo.n_oficina_ciudad +
            "/" +
            correo.orden_numero +
            "/" +
            correo.numero_guia
        );
      }

      this.datosGuia = {
        cliente: correo.cliente_nombre + " - " + correo.proceso_referencia,
        nombre_destinatario: correo.nombre_destinatario,
        direccion_destinatario:
          (correo.barrio_destinatario
            ? correo.barrio_destinatario + ", "
            : "") + correo.direccion_destinatario,
      };
      listarMovimientos.call(this, correo);
      this.modalGuia = true;
    },
    estado(correo) {
      try {
        let estado = _.find(
          this.estados,
          (estado) => estado.id === correo.estado
        );
        switch (correo.estado) {
          case "VERIFICADO":
            let estadoVerificacion = _.find(
              this.estadosVerificacion,
              (estado) => estado.id === correo.estado_verificacion
            );
            return estado.nombre + " (" + estadoVerificacion.nombre + ")";
          case "CARGADO":
          case "EN_TRANSITO":
          case "EN_ALISTAMIENTO":
          case "CENTRO_ACOPIO":
          case "DEVOLUCION_TRANSITO":
          case "RECIBIDO_CEDIS":
          case "DEVUELTO_REMITENTE":
          case "REASIGNADO":
          case "DISTRIBUCION":
            return estado.nombre;
          default:
            let detalleEstado = _.find(
              this.detallesEstado[correo.estado],
              (estado) => estado.id === correo.detalle_estado
            );
            if (detalleEstado) {
              return estado.nombre + " (" + detalleEstado.nombre + ")";
            } else {
              return estado.nombre;
            }
        }
      } catch (error) {
        console.log(correo);
      }
    },
    //eliminar(id) {
    //
    //},
    guardar() {
      guardar.call(this);
    },
    filtrar() {
      this.pagination.offset = 0;
      this.pagination.currentPage = 1;
      listar.call(this);
    },
    descargarXlsx() {
      descargarXlsx.call(this);
    },
    descargarCsv() {
      descargarCsv.call(this);
    },
    verEstadisticas() {
      verEstadisticas.call(this);
    },
    pageChange(page) {
      this.pagination.offset = (page - 1) * this.pagination.limit;
      listar.call(this);
    },
    //Utils
    getTipo(tipoId) {
      return _.get(_.find(this.tipos, (tipo) => tipo.id === tipoId), "nombre");
    },
  },
  created() {
    listarAnios.call(this);
    listarProcesos.call(this);
    listarOficinas.call(this);
    listarMensajeros.call(this);
    listarEstadosCorreo.call(this);
    listarEstadosVerificacionCorreo.call(this);
    listarDetallesEstadoCorreo.call(this);
  },
});

function descargarXlsx() {
  let loader = this.$loading.show({
    container: this.$refs["form-container"],
  });
  correosService
    .obtenerEnlaceXlsx(this.filtro)
    .then((response) => {
      loader.hide();
      window.location.href = response.data.enlace;
    })
    .catch((error) => {
      loader.hide();
    });
}

function descargarCsv() {
  let loader = this.$loading.show({
    container: this.$refs["form-container"],
  });
  correosService
    .obtenerEnlaceCsv(this.filtro)
    .then((response) => {
      loader.hide();
      window.location.href = response.data.enlace;
    })
    .catch((error) => {
      loader.hide();
    });
}

function verEstadisticas() {
  let self = this;
  correosService.obtenerEstadisticas(this.filtro).then((response) => {
    self.correos = [];
    let totales = {
      inconsistentes: 0,
      por_entregar: 0,
      distribucion: 0,
      efectivo: 0,
      devuelto: 0,
      novedad: 0,
      sin_procesar: 0,
    };
    let total = 0;
    let guias = 0;
    _.each(response.data.datos, (item) => {
      guias += parseInt(item.guias);
      totales.inconsistentes += parseInt(item.incorrectas);
      totales.por_entregar += parseInt(item.por_entregar);
      totales.distribucion += parseInt(item.distribucion);
      totales.efectivo += parseInt(item.efectivo);
      totales.devuelto += parseInt(item.devuelto);
      totales.novedad += parseInt(item.novedad);
      totales.sin_procesar += parseInt(item.sin_procesar);
    });
    total +=
      totales.inconsistentes +
      totales.por_entregar +
      totales.distribucion +
      totales.efectivo +
      totales.devuelto +
      totales.novedad +
      totales.sin_procesar;
    self.estadisticas = {
      totales,
      total,
      guias,
    };
    self.chart1 = {
      datasets: [
        {
          data: [
            totales.por_entregar + totales.sin_procesar,
            totales.distribucion,
            totales.efectivo,
            totales.devuelto + totales.novedad,
            totales.inconsistentes,
          ],
          backgroundColor: [
            "#0074D9",
            "#FF4136",
            "#2ECC40",
            "#FF851B",
            "#7FDBFF",
            "#B10DC9",
            "#FFDC00",
            "#001f3f",
            "#39CCCC",
            "#01FF70",
            "#85144b",
            "#F012BE",
            "#3D9970",
            "#111111",
            "#AAAAAA",
          ],
        },
      ],
      labels: [
        "Por entregar",
        "En distribución",
        "Efectivos",
        "Devueltos",
        "Inconsistentes",
      ],
    };
    //console.log(totales);
  });
}

function listar() {
  let self = this;
  let loader = this.$loading.show({
    container: this.$refs["form-container"],
  });
  correosService
    .listarFiltrado(this.pagination.limit, this.pagination.offset, this.filtro)
    .then((response) => {
      loader.hide();
      self.correos = response.data.lista;
      self.pagination.pages = Math.ceil(
        response.data.total / self.pagination.limit
      );
      self.pagination.total = response.data.total;
    })
    .catch((error) => {
      loader.hide();
    });
}

function listarAnios() {
  let anio = moment().year();
  if (moment().month() === 11) {
    anio += 1;
  }
  this.anios = _.range(anio, anio - 10);
  this.filtro.anio = anio;
}

function listarProcesos() {
  let self = this;
  clientesService.listarProcesos(true).then((response) => {
    self.procesos = response.data.lista;
  });
}

function listarOficinas() {
  oficinasService.listar().then((response) => {
    this.oficinas = response.data.lista;
  });
}

function listarMensajeros() {
  mensajerosService.listar().then((response) => {
    this.mensajeros = response.data.lista;
  });
}

function listarMovimientos(correo) {
  let self = this;
  correosService.listarMovimientos(correo.id).then((response) => {
    self.movimientos = response.data;
  });
}

function listarEstadosCorreo() {
  let self = this;
  enumsService.listarEstadosCorreo().then((response) => {
    self.estados = response;
  });
}

function listarEstadosVerificacionCorreo() {
  let self = this;
  enumsService.listarEstadosVerificacionCorreo().then((response) => {
    self.estadosVerificacion = response;
  });
}

function listarDetallesEstadoCorreo() {
  let self = this;
  enumsService.listarDetallesEstadoCorreo().then((response) => {
    self.detallesEstado = response;
  });
}

function guardar() {
  let self = this;
  let promise = this.id
    ? correosService.editar(this.id, this.correo)
    : correosService.agregar(this.correo);
  promise
    .then((response) => {
      listar.call(self);
      self.modal = false;
      notifyService.success("Correo guardado correctamente");
    })
    .catch((error) => {
      if (error.response.status === 409 && error.response.data.reasons) {
        notifyService.danger(
          notifyService.buildMessage(error.response.data.reasons)
        );
      } else if (error.response.data.message) {
        notifyService.danger(error.response.data.message);
      }
    });
}
