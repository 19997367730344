<div>
    <h3>Orden No.
        <span class="text-info">{{ orden.numero }}</span> de
        <span class="text-info">{{ orden.fecha }}</span>
    </h3>
    <div class="row">
        <div class="col-12">
            <router-link :to="{ name: 'empresa.ordenes.detalle.cargar', params: { id: ordenId } }" class="btn btn-primary">
                <i class="mdi mdi-file-upload mdi-lg"></i>Cargar detalle</router-link>
            <button class="btn btn-secondary" @click.prevent="impresion">
                <i class="mdi mdi-print mdi-lg text-success"></i> Imprimir</button>
        </div>
    </div>
    <div class="row margin-top-sml" v-if="orden.estado">
        <div class="col-12">
            <div class="card bg-light card-body mb-3">
                <dl class="dl-horizontal">
                    <dt>Correo cargado:</dt>
                    <dd>{{ orden.cantidad_cargada }}</dd>
                    <dt>Correo en distribución:</dt>
                    <dd>{{ orden.estado.distribucion }}</dd>
                    <dt>Correo efectivo:</dt>
                    <dd>{{ orden.estado.efectivo }}</dd>
                    <dt>Correo devuelto:</dt>
                    <dd>{{ orden.estado.devuelto }}</dd>
                    <dt>Correo con novedad:</dt>
                    <dd>{{ orden.estado.novedad }}</dd>
                </dl>
            </div>
        </div>
    </div>
    <div class="row margin-top-sml">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Guía No.</th>
                            <th>Código</th>
                            <th>Destinatario</th>
                            <th>Identificación</th>
                            <th>Barrio</th>
                            <th>Ciudad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="correo in correos">
                            <td>{{ correo.numero_guia }}</td>
                            <td>{{ correo.codigo_destinatario }}</td>
                            <td>{{ correo.nombre_destinatario }}
                                <br>{{ correo.direccion_destinatario }}</td>
                            <td>{{ correo.identificacion_destinatario }}</td>
                            <td>{{ correo.barrio_destinatario }}</td>
                            <td>{{ correo.ciudad_destinatario }} / {{ correo.departamento_destinatario }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="pageChange"></pagination>
        </div>
    </div>
    <form @submit.prevent="imprimir">
        <modal v-model="modalGuias">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">Imprimir guías</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="params-template">Template:</label>
                    <select class="form-control" id="params-template" v-model="params.template" @change="templateChange">
                        <option :value="null">Seleccione uno</option>
                        <option v-for="template in templates" :value="template.id">{{ template.nombre }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="params-desde">Rango:</label>
                    <div class="input-group">
                        <input type="number" class="form-control" id="params-desde" placeholder="Desde" v-model="params.desde">
                        <input type="number" class="form-control" id="params-hasta" placeholder="Hasta" v-model="params.hasta">
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="params-fecha">Utilizar fecha:</label>
                    <datetimepicker id="params-fecha" placeholder="Fecha" v-model="params.fecha"></datetimepicker>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="params-desde">Corrección de margenes:</label>
                    <div class="input-group">
                        <input type="number" step="0.1" class="form-control" id="params-desde" placeholder="Margen superior" v-model="params.margen_superior">
                        <input type="number" step="0.1" class="form-control" id="params-hasta" placeholder="Margen izquierdo" v-model="params.margen_izquierdo">
                    </div>
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Imprimir</button>
                <button type="button" class="btn btn-secondary" @click="modalGuias = false">Salir</button>
            </div>
        </modal>
    </form>
</div>