import authentication from 'empresa/services/authentication-service';
import notify from 'services/notify-service';

import render from './login.html';

export default render({
    name: 'login',
    data() {
        return {
            credenciales: {}
        };
    },
    methods: {
        login() {
            var self = this;
            authentication.authenticate(this.credenciales).then(response => {
                //jwt.saveToken(response.data.token);
                //jwt.saveRefreshToken(response.data.refresh_token);
                this.$router.push({ name: 'empresa' });
            }, error => {
                if(error.response.data.message) {
                    notify.danger('No se pudo efectuar el login, status: ' + error.response.data.message);
                } else {
                    notify.danger('No se pudo efectuar el login, status: ' + error.response.status);
                }
            });
        }
    },
    created() {
        //jwt.deleteToken();
        //jwt.deleteRefreshToken();
        this.credenciales = {};
        if(authentication.valid()) {
            this.$router.push({ name: 'empresa' });
        }
    }
});