import render from './carousel.html';

export default render({
    name: 'CarouselComponent',
    props: {
        id: {
            type: String
        },
        images: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    mounted() {
        $('#carousel_' + this.id).carousel({
            interval: 5000 //changes the speed
        })
    }
});