import axios from 'axios'

import url from 'services/url-service'

const path = '/private/roles';

class RolesService {
    listar() {
        return axios.get(url.create(path));
    }
    listarFiltrado(limit, offset, criteria) {
        return axios.get(url.create(path), {
            params: {
                limit,
                offset,
                criteria
                //criteria: encodeURI(JSON.stringify(criteria))
            }
        });
    }
    obtener(id) {
        return axios.get(url.create(path + '/' + id));
    }
    agregar(rol) {
        return axios.post(url.create(path), rol);
    }
    editar(id, rol) {
        return axios.put(url.create(path + '/' + id), rol);
    }
    eliminar(id) {
        return axios.delete(url.create(path + '/' + id));
    }
    /*listarTipos() {
        return axios.get(url.create(path + '/tipos'));
    }*/
}

export default new RolesService();