<div>
    <h3 class="d-print-none">{{ titulo }}</h3>
    <form @submit.prevent="descargarReporte" class="d-print-none">
        <!--div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="form-control-label col-md-4" for="filtro-orden">Orden:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="filtro-orden" placeholder="Orden" v-model="filtro.orden_numero">
                    </div>
                </div>
            </div>
        </div-->
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="form-control-label col-md-4" for="filtro-archivo">Archivo base:</label>
                    <div class="col-md-8">
                        <fileupload id="filtro-archivo" placeholder="Archivo plano" @change="cargarArchivo" v-model="filtro.archivo"></fileupload>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col-md-8 offset-4">
                        <button type="submit" class="btn btn-secondary">Generar reporte</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>