<div>
    <h3>{{ cliente.nombre }}
        <small>{{ cliente.identificacion }}</small>
    </h3>
    <div class="row">
        <div class="col-6">
            <div class="card bg-light card-body mb-3">
                <h4>Historial</h4>
                <dl v-if="ordenes.length > 0">
                    <template v-for="orden in ordenes">
                        <dt>Proceso: {{ orden.proceso_referencia }}</dt><dd>Fecha: {{ orden.fecha }}  Numero: {{ orden.numero }}</dd>
                    </template>
                </dl>
                <template v-else>
                    No hay ordenes creadas en ningún proceso de este cliente
                </template>
            </div>
        </div>
        <div class="col-6">
            <div class="card bg-light card-body mb-3">
                <h4>Contactos
                    <small>
                        <a v-if="$security.check(['all', 'agregar_contactos'])" href="" @click.prevent="agregarContacto">Agregar</a>
                    </small>
                </h4>
                <ul>
                    <li v-for="contacto in contactos">
                        {{ contacto.nombre }} ({{ contacto.username }})
                        <a  v-if="$security.check(['all', 'editar_contactos'])" href="" @click.prevent="editarContacto(contacto)"><i class="mdi mdi-mode-edit mdi-lg"></i></a>
                        <a  v-if="$security.check(['all', 'eliminar_contactos'])" href="" @click.prevent="eliminarContacto(contacto)"><i class="mdi mdi-delete mdi-lg"></i></a><br />
                        {{ contacto.email }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h4>Procesos
                <small>
                    <a v-if="$security.check(['all', 'agregar_procesos'])" href="" @click.prevent="agregarProceso" title="Agregar">Agregar</a>
                </small>
            </h4>
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Referencia</th>
                            <th>Fecha inicio</th>
                            <th>Fecha finalización</th>
                            <th>Tarifas oficinas</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="proceso in procesos">
                            <td>{{ proceso.referencia }}</td>
                            <td>{{ proceso.fecha_inicio }}</td>
                            <td>{{ proceso.fecha_finalizacion ? proceso.fecha_finalizacion : 'ACTIVO' }}
                            </td>
                            <td>
                                <ul>
                                    <li v-for="oficina in proceso.oficinas">
                                        <a title="Tarifas" href="" @click.prevent="editarValores(proceso, oficina)">{{ oficina.ciudad }} / {{ oficina.departamento }} desde {{ oficina.fecha_inicio }}</a>
                                    </li>
                                </ul>
                            </td>
                            <!--td>{{ proceso.oficina_ciudad }} / {{ proceso.oficina_departamento }}</td-->
                            <td>
                                <a v-if="$security.check(['all', 'tarifas_procesos'])" title="Tarifas" href="" @click.prevent="ingresarValores(proceso)">
                                    <i class="mdi mdi-attach-money mdi-lg"></i>
                                </a>
                                <a v-if="$security.check(['all', 'editar_procesos'])" title="Editar" href="" @click.prevent="editarProceso(proceso)">
                                    <i class="mdi mdi-mode-edit mdi-lg"></i>
                                </a>
                                <a v-if="$security.check(['all', 'eliminar_procesos'])" title="Eliminar" href="" @click.prevent="eliminarProceso(proceso)">
                                    <i class="mdi mdi-delete mdi-lg"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <form @submit.prevent="guardarContacto">
        <modal v-model="modalContacto">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">Contactos</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="contacto-nombre">Nombre:</label>
                    <input type="text" class="form-control" id="contacto-nombre" placeholder="Nombre" v-model="contacto.nombre">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="contacto-identificacion">Identificación:</label>
                    <input type="text" class="form-control" id="contacto-identificacion" placeholder="Identificación" v-model="contacto.identificacion">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="contacto-email">Email:</label>
                    <input type="text" class="form-control" id="contacto-email" placeholder="Email" v-model="contacto.email">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="contacto-direccion">Dirección:</label>
                    <input type="text" class="form-control" id="contacto-direccion" placeholder="Dirección" v-model="contacto.direccion">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="contacto-telefono">Teléfono:</label>
                    <input type="text" class="form-control" id="contacto-telefono" placeholder="Teléfono" v-model="contacto.telefono">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="contacto-celular">Celular:</label>
                    <input type="text" class="form-control" id="contacto-celular" placeholder="Celular" v-model="contacto.celular">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="contacto-username">Nombre de usuario:</label>
                    <input type="text" class="form-control" id="contacto-username" placeholder="Nombre de usuario" v-model="contacto.username">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="contacto-password">Password:</label>
                    <input type="text" class="form-control" id="contacto-password" placeholder="Password" v-model="contacto.password">
                </div>
                <div slot="footer" class="modal-footer">
                    <button type="submit" class="btn btn-success">Guardar</button>
                    <button type="button" class="btn btn-secondary" @click="modalContacto = false">Salir</button>
                </div>
            </div>
        </modal>
    </form>
    <form @submit.prevent="guardarProceso">
        <modal v-model="modalProceso">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">Procesos</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="proceso-referencia">Referencia:</label>
                    <input type="text" class="form-control" id="proceso-referencia" placeholder="Referencia" v-model="proceso.referencia">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="proceso-fecha-inicio">Fecha de inicio:</label>
                    <datetimepicker id="proceso-fecha-inicio" placeholder="Fecha de inicio" v-model="proceso.fecha_inicio"></datetimepicker>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="proceso-fecha-finalizacion">Fecha de finalización:</label>
                    <datetimepicker id="proceso-fecha-finalizacion" placeholder="Fecha de finalización" v-model="proceso.fecha_finalizacion"></datetimepicker>
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Guardar</button>
                <button type="button" class="btn btn-secondary" @click="modalProceso = false">Salir</button>
            </div>
        </modal>
    </form>
    <form @submit.prevent="guardarValores">
        <modal v-model="modalValores">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">Valores</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="proceso-fecha-inicio">Fecha de inicio:</label>
                    <datetimepicker id="proceso-fecha-inicio" placeholder="Fecha de inicio" v-model="fecha_inicio" @change="obtenerValores"></datetimepicker>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="valores-oficina">Oficina:</label>
                    <select class="form-control" id="valores-oficina" v-model="oficinaId" @change="obtenerValores">
                        <option :value="undefined">Seleccione uno</option>
                        <option v-for="oficina in oficinas" :value="oficina.id">{{ oficina.ciudad }} / {{ oficina.departamento }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="valores-valor-devolucion">Tarifa devolución:</label>
                    <input type="text" class="form-control" id="valores-valor-devolucion" placeholder="Tarifa devolución" v-model="valores.valor_devolucion">
                    <span class="form-text text-muted">Puede poner un valor constante o un porcentaje (%).</span>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="valores-valor-descuento">Descuento x día de retraso:</label>
                    <input type="text" class="form-control" id="valores-valor-descuento" placeholder="Descuento retraso" v-model="valores.valor_descuento">
                    <span class="form-text text-muted">Puede poner un valor constante o un porcentaje (%).</span>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="valores-valor-pago-minimo">Pago mínimo:</label>
                    <input type="text" class="form-control" id="valores-valor-pago-minimo" placeholder="Pago mínimo" v-model="valores.valor_pago_minimo">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="valores-valor-bonificacion">Bonificación (24horas):</label>
                    <input type="text" class="form-control" id="valores-valor-bonificacion" placeholder="Bonificación" v-model="valores.valor_bonificacion">
                    <span class="form-text text-muted">Puede poner un valor constante o un porcentaje (%).</span>
                </div>
                <div class="row">
                    <fieldset class=" col-6">
                        <legend>Tarifas</legend>
                        <div class="form-group" v-for="tipoTarifa, index in tiposTarifa">
                            <div class="row">
                                <label class="form-control-label col-4" :for="'tarifa-' + index">{{ tipoTarifa.nombre }}:</label>
                                <div class="col-8">
                                    <input type="number" step="0.01" class="form-control" :id="'tarifa-' + index" :placeholder="tipoTarifa.nombre" v-model="tarifas[tipoTarifa.id]">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset class=" col-6">
                        <legend>Tiempos</legend>
                        <div class="form-group" v-for="tipoTarifa, index in tiposTarifa">
                            <div class="row">
                                <label class="form-control-label col-4" :for="'tiempo-' + index">{{ tipoTarifa.nombre }}:</label>
                                <div class="col-8">
                                    <input type="number" step="1" class="form-control" :id="'tiempo-' + index" :placeholder="tipoTarifa.nombre" v-model="tiempos[tipoTarifa.id]">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Guardar</button>
                <button type="button" class="btn btn-secondary" @click="modalValores = false">Salir</button>
            </div>
        </modal>
    </form>
</div>