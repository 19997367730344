import notifyService from 'services/notify-service';
import movimientosService from 'services/data/movimientos-service';


import render from './planilla-verificacion.html';

export default render({
    name: 'PlanillaEntrega',
    data() {
        return {
            titulo: 'Planilla de verificación',
            filtro: {},
            //mensajero: null,
            //mensajeros: [],
            planilla: null,
            totales: null,
            procesadas: null,
            pendientes: null,
            entregadas: null,
            devueltas: null
        }
    },
    computed: {
    },
    methods: {
        //cambiarMensajero() {
        //    obtenerMensajero.call(this);
        //},
        generarPlanilla() {
            generarPlanilla.call(this);
        },
    },
    created() {
    }
});

function generarPlanilla() {
    this.planilla = null;
    this.totales = null;
    if(this.filtro.fecha_verificacion_desde && this.filtro.fecha_verificacion_hasta) {
        let planilla = [];
        let totales = {
            procesadas: 0
        };
        let loader = this.$loading.show({
            container: this.$refs['form-container']
        });
        let self = this;
        movimientosService.listarFiltrado('verificacion', this.filtro).then(response => {
            let correos = response.data;
            let indexes = {};

            let j = 0;

            _.each(correos, (correo, i) => {
                movimiento.fecha_verificacion = moment(movimiento.fecha_verificacion).format('YYYY-MM-DD')
                let llave = correo.orden_numero + correo.estado_verificacion + correo.fecha_verificacion;
                let pos = indexes[llave];
                if(pos != null) {
                    planilla[pos].procesadas += correo.cantidad;
                } else {
                    pos = j++;
                    indexes[llave] = pos;
                    planilla[pos] = {};
                    planilla[pos].cliente_nombre = correo.cliente_nombre + ' - ' + correo.proceso_referencia;
                    planilla[pos].orden_numero = correo.orden_numero;
                    planilla[pos].tipo = correo.tipo;
                    planilla[pos].procesadas = correo.cantidad;
                    planilla[pos].estado_verificacion = correo.estado_verificacion;
                    planilla[pos].fecha_verificacion = correo.fecha_verificacion;
                }
                totales.procesadas += correo.cantidad;
            });
            self.planilla = planilla;
            self.totales = totales;
            loader.hide();
        }).catch(error => {
            loader.hide();
        });

        //console.log(this.planilla);
        //this.planilla = planilla;
        //this.totales = totales;
    } else {
        this.planilla = null;
        this.totales = null;
        notifyService.danger("Todos los campos son requeridos");
    }
}