<div>
    <h3>{{ titulo }}
        <small>
            <a v-if="$security.check(['all', 'agregar_roles'])" href="" @click.prevent="agregar" title="Agregar">[Agregar]</a>
        </small>
    </h3>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="rol in roles">
                            <td>{{ rol.nombre }}</td>
                            <td>
                                <a v-if="$security.check(['all', 'editar_roles'])" href="" @click.prevent="editar(rol)" title="Editar">
                                    <i class="mdi mdi-edit mdi-lg"></i>
                                </a>
                                <a v-if="$security.check(['all', 'eliminar_roles'])" href="" @click.prevent="eliminar(rol)" title="Eliminar">
                                    <i class="mdi mdi-delete mdi-lg"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <form @submit.prevent="guardar">
        <modal v-model="modal">
            <div slot="header" class="modal-header">
                <h4 class="modal-title">{{ titulo }}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-control-label" for="rol-nombre">Nombre:</label>
                    <input type="text" class="form-control" id="rol-nombre" placeholder="Nombre" v-model="rol.nombre">
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="rol-permisos">Permisos:</label>
                    <template v-for="(permiso, id) in permisos">
                        <div class="form-check">
                            <label>
                                <input type="checkbox" :value="id" v-model="rol.permisos">
                                <strong>{{ permiso.etiqueta }}</strong>
                            </label>
                        </div>
                        <template v-if="permiso.permisos">
                            <div class="form-check indent-20" v-for="(permiso, id) in permiso.permisos">
                                <label>
                                    <input type="checkbox" :value="id" v-model="rol.permisos">{{ permiso }}</label>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <button type="submit" class="btn btn-success">Guardar</button>
                <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
            </div>
        </modal>
    </form>
</div>