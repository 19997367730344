import authentication from 'cliente/services/authentication-service';

import Correos from './correo/correos';
import CorreosAmi from './correo/correos-ami';
import CorreosClaro from './correo/correos-claro';
import CorreosSdh from './correo/correos-sdh';
//import CorreosComfenalco from './correo/correos-comfenalco';

import render from './principal.html';

export default render({
    name: 'Principal',
    data() {
        return {
            toast: false,
            nombre: '',
            clienteId: null,
            vista: 'correos'
        }
    },
    //correos-[id]: Componente
    components: {
        'correos': Correos,
        //'correos-2': CorreosComfenalco
        'correos-54': CorreosAmi,
        'correos-192': CorreosClaro,
        'correos-27': CorreosSdh,
        'correos-219': CorreosSdh,
    },
    methods: {
        imprimir() {
            window.print();
        },
        logout() {
            authentication.unAuthenticate();
            this.$router.push({ name: 'index' });
        }
    },
    created() {
        //let datos = security.datos();
        //console.log(token);
        //this.nombre = datos.nombre;
        
        let clienteId = this.$security.datos.cliente_id;
        //console.log(this.$options.components);
        if(this.$options.components['correos-' + clienteId]) {
            this.vista = 'correos-' + clienteId;
        } else {
            this.vista = 'correos';
        }
        //console.log(this.vista);
    }
});