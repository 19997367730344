import moment from 'moment';

import render from './landing.html';

export default render({
    name: 'Landing',
    data() {
        return {
            anio: moment().year(),
            images: [
                {src: 'assets/images/paquetes.jpg', caption: 'Alta calidad'},
            ]
        }
    }
});