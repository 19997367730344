import usuariosService from 'services/data/usuarios-service';
import mensajerosService from 'services/data/mensajeros-service';
import estadisticasService from 'services/data/estadisticas-service';


import render from './dashboard.html';

var graphTemplate = {
    labels: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    datasets: [
        {
            label: 'Ordenes creadas',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            borderColor: '#2E7D32',
            backgroundColor: '#66BB6A'
        }
    ]
}

export default render({
    name: 'Dashboard',
    data() {
        return {
            usuariosInactivos: [],
            mensajerosAtrasados: [],
            graph: graphTemplate,
        }
    },
    created() {
        listarUsuariosInactivos.call(this);
        listarMensajerosAtrasados.call(this);
        obtenerEstadisticasOrdenes.call(this);
        //listarOrdenesAnio.call(this);
    }
});

//function listarOrdenesAnio() {
//    let self = this;
//    let anio = (new Date()).getFullYear();
//    ordenesService.listarFiltrado(undefined, undefined, {
//        anio
//    }).then(response => {
//        self.ordenes = response.data.lista;
//        self.pagination.pages = Math.ceil(response.data.total / self.pagination.limit);
//    });
//}

function listarUsuariosInactivos() {
    let self = this;
    usuariosService.listarBloqueados().then(response => {
        self.usuariosInactivos = response.data.lista;
    });
}

function listarMensajerosAtrasados() {
    let self = this;
    mensajerosService.listarAtrasados().then(response => {
        self.mensajerosAtrasados = response.data.lista;
    });
}

function obtenerEstadisticasOrdenes() {
    let self = this;
    estadisticasService.obtenerEstadisticasOrdenes().then(response => {
        let graph = _.clone(graphTemplate);
        graph.datasets[0].data = _.map(_.range(1, 13), (mes) => {
            let cantidad = _.find(response.data, cantidad => cantidad.mes === mes);
            return _.get(cantidad, 'cantidad') || 0;
        });
        self.graph = graph;
    });
}