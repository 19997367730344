import dialogService from 'services/dialog-service';
import notifyService from 'services/notify-service';
import rolesService from 'services/data/roles-service';
import enumsService from 'services/data/enums-service';
import render from './roles.html'


export default render({
    name: 'Roles',
    data() {
        return {
            titulo: 'Roles',
            modal: false,
            id: null,
            rol: {},
            roles: [],
            permisos: []
        }
    },
    methods: {
        agregar() {
            this.id = null;
            this.rol = {
                permisos: []
            };
            this.modal = true;
        },
        editar(rol) {
            this.id = rol.id;
            this.rol = {
                nombre: rol.nombre,
                permisos: rol.permisos,
            };
            this.modal = true;
        },
        eliminar(rol) {
            eliminar.call(this, rol);
        },
        guardar() {
            guardar.call(this);
        }
    },
    created() {
        listar.call(this);
        listarPermisos.call(this);
    }
});

function listar() {
    let self = this;
    rolesService.listarFiltrado().then(response => {
        self.roles = response.data.lista;
    });
}

function listarPermisos() {
    let self = this;
    enumsService.listarPermisos().then(response => {
        self.permisos = response;
    });
}

function guardar() {
    let self = this;
    let promise = this.id ? rolesService.editar(this.id, this.rol) : rolesService.agregar(this.rol);
    promise.then(response => {
        listar.call(self);
        self.modal = false;
        notifyService.success('Rol guardado correctamente');
    }).catch(error => {
        if(error.response.status === 409 && error.response.data.reasons) {
            notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
        } else if(error.response.data.message) {
            notifyService.danger(error.response.data.message);
        }
    });
}

function eliminar(rol) {
    let self = this;
    dialogService.confirm(`Esta seguro de que desea eliminar este rol ${rol.nombre}?`, result => {
        if(result) {
            rolesService.eliminar(rol.id).then(response => {
                listar.call(self);
                notifyService.success('Orden eliminada correctamente');
            }).catch(error => {
                if(error.response.status === 409 && error.response.data.reasons) {
                    notifyService.danger(notifyService.buildMessage(error.response.data.reasons));
                } else if(error.response.data.message) {
                    notifyService.danger(error.response.data.message);
                }
            });
        }
    });
}