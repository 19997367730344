import notifyService from 'services/notify-service';
import movimientosService from 'services/data/movimientos-service';
import oficinasService from 'services/data/oficinas-service';


import render from './planilla-consolidada-clientes.html';
import { isNullOrUndefined } from 'util';

export default render({
    name: 'PlanillaConsolidadaClientes',
    data() {
        return {
            titulo: 'Planilla consolidada por clientes',
            filtro: {},
            oficinas: [],
            mensajero: null,
            planilla: null,
            clientes: [],
            totales: null,
            procesadas: null,
            pendientes: null,
            entregadas: null,
            devueltas: null
        }
    },
    computed: {
    },
    methods: {
        generarPlanilla() {
            generarPlanilla.call(this);
        },
    },
    created() {
        listarOficinas.call(this);
    }
});

function listarOficinas() {
    let self = this;
    oficinasService.listar().then(response => {
        self.oficinas = response.data.lista;
    });
}

function generarPlanilla() {
    this.planilla = null;
    this.totales = null;

    if(this.filtro.fecha_desde && this.filtro.fecha_hasta) {
        let planilla = [];
        let totales = {
            clientes: [],
            procesadas: 0,
            movimientos: 0.0,
            bonificaciones: 0.0,
            descuentos: 0.0
        };
        let loader = this.$loading.show({
            container: this.$refs['form-container']
        });

        let self = this;
        movimientosService.listarFiltrado('recepcion', this.filtro).then(response => {
            let movimientos = response.data;
            let indexes = {};
            let clientesIndexes = {};

            let j = 0;
            let k = 0;

            _.each(movimientos, (movimiento, i) => {
                if(["EFECTIVO", "DEVUELTO"].indexOf(movimiento.estado) < 0) {
                    return true;
                }
                let llave = movimiento.mensajero_id;
                let pos = indexes[llave];
                let clienteLlave = movimiento.proceso_id;
                let clientePos = clientesIndexes[clienteLlave];

                if(clientePos === null || clientePos === undefined) {
                    clientePos = k++;
                    clientesIndexes[clienteLlave] = clientePos;
                    this.clientes[clientePos] = movimiento.cliente_nombre + ' - ' + movimiento.proceso_referencia;
                }

                if(pos !== null && pos !== undefined) {
                    //console.log("!=");
                    planilla[pos].procesadas += movimiento.cantidad;
                    //planilla[pos].movimientos += movimiento.valor_movimiento;
                    //planilla[pos].bonificaciones += movimiento.valor_bonificacion;
                    //planilla[pos].descuentos += movimiento.valor_descuento;
                    if(planilla[pos].clientes[clientePos]) {
                        planilla[pos].clientes[clientePos] += movimiento.valor_movimiento + movimiento.valor_bonificacion - movimiento.valor_descuento;
                    } else {
                        planilla[pos].clientes[clientePos] = movimiento.valor_movimiento + movimiento.valor_bonificacion - movimiento.valor_descuento;
                    }

                    planilla[pos].total += (movimiento.valor_movimiento + movimiento.valor_bonificacion - movimiento.valor_descuento);
                    switch(movimiento.estado){
                        case "EFECTIVO":
                            planilla[pos].entregadas += movimiento.cantidad;
                            break;
                        case "DEVUELTO":
                            planilla[pos].devueltas += movimiento.cantidad;
                            break;
                    }
                } else {
                    //console.log("==");
                    pos = j++;
                    indexes[llave] = pos;
                    planilla[pos] = {};
                    planilla[pos].mensajero_nombre = movimiento.mensajero_nombre;
                    planilla[pos].procesadas = movimiento.cantidad;
                    //planilla[pos].movimientos = movimiento.valor_movimiento;
                    //planilla[pos].bonificaciones = movimiento.valor_bonificacion;
                    //planilla[pos].descuentos = movimiento.valor_descuento;

                    planilla[pos].clientes = [];
                    planilla[pos].clientes[clientePos] = movimiento.valor_movimiento + movimiento.valor_bonificacion - movimiento.valor_descuento;

                    planilla[pos].total = movimiento.valor_movimiento + movimiento.valor_bonificacion - movimiento.valor_descuento;
                    planilla[pos].entregadas = 0;
                    planilla[pos].devueltas = 0;
                    switch(movimiento.estado){
                        case "EFECTIVO":
                            planilla[pos].entregadas += movimiento.cantidad;
                            break;
                        case "DEVUELTO":
                            planilla[pos].devueltas += movimiento.cantidad;
                            break;
                    }
                }

                totales.procesadas += movimiento.cantidad;
                if(totales.clientes[clientePos]) {
                    totales.clientes[clientePos] += movimiento.valor_movimiento + movimiento.valor_bonificacion - movimiento.valor_descuento;
                } else {
                    totales.clientes[clientePos] = movimiento.valor_movimiento + movimiento.valor_bonificacion - movimiento.valor_descuento;
                }
                totales.movimientos += movimiento.valor_movimiento;
                totales.bonificaciones += movimiento.valor_bonificacion;
                totales.descuentos += movimiento.valor_descuento;
                // switch(movimiento.estado){
                //     case "EFECTIVO":
                //         totales.entregadas += movimiento.cantidad;
                //         break;
                //     case "DEVUELTO":
                //         totales.devueltas += movimiento.cantidad;
                //         break;
                // }
            });
            self.planilla = planilla;
            self.totales = totales;
            loader.hide();
        }).catch(error => {
            loader.hide();
        });

        //console.log(this.planilla);
        //this.planilla = planilla;
        //this.totales = totales;
    } else {
        this.planilla = null;
        this.totales = null;
        notifyService.danger("Todos los campos son requeridos");
    }
}